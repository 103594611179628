import React, { useEffect, useState } from 'react';
import './readingscreen.scss';
import FilterTool from '../../components/filtertool/filtertool';
import Profilecard from '../../components/profilecard/profilecard';
import { VscSettings } from 'react-icons/vsc';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useTranslation from '../../localization/translations';
import { collection, getDocs, query, where } from 'firebase/firestore';
import firebase from '../../config/firebase';
import ProfileCardSkeletion from '../profilecard/profilecardskeletion';
import { CgClose } from 'react-icons/cg';

const db = firebase.db;

const Readings = (props) => {

    const translation = useTranslation();

    const initialIsShowingOfflineFilter = true
    const initialMeetingTypeFilter = { "index": 0, "value": "any" }
    const initialNumberOfReviewsFilter = { "index": 0, "value": 0 }
    const initialPricePerMinuteFilter = { "min": 0, "max": 500}

    const [isShowingOfflineFilter, setIsShowingOfflineFilter] = useState(initialIsShowingOfflineFilter);
    const [meetingTypeFilter, setMeetingTypeFilter] = useState(initialMeetingTypeFilter);
    const [numberOfReviewsFilter, setNumberOfReviewsFilter] = useState(initialNumberOfReviewsFilter);
    const [pricePerMinuteFilter, setPricePerMinuteFilter] = useState(initialPricePerMinuteFilter);
    const [filteredAdvisorsState, setFilteredAdvisorsState] = useState({
        fetched: false,
        fetching: false,
        advisors: []
    });

    const width = window.screen.width;
    const { img, skill, header, text, skillFilter } = props;

    // const filter = {
    //     "skillFilter": skillFilter,
    //     "isShowingOfflineFilter": isShowingOfflineFilter,
    //     "meetingTypeFilter": meetingTypeFilter,
    //     "numberOfReviewsFilter": numberOfReviewsFilter,
    //     "pricePerMinuteFilter": pricePerMinuteFilter,
    // }

    function resetFilters() {
        setIsShowingOfflineFilter(initialIsShowingOfflineFilter);
        setMeetingTypeFilter(initialMeetingTypeFilter);
        setNumberOfReviewsFilter(initialNumberOfReviewsFilter);
        setPricePerMinuteFilter(initialPricePerMinuteFilter);
    }

    const Filter = (width) => {
        const [show, setShow] = useState(false);
        const offset = useWindowDimensions();
        width = offset.width;
        const showFilter = width >= 1000;

        return (
            width && (
                <div className='filter' style={{ width: "calc(100% - 20px)", display: "flex", alignItems: "center", justifyContent: "center", zIndex: "20" }}>
                    <div onClick={() => setShow(prev => !prev)} className="filterPlaceHolder" style={{ width: "100%", maxWidth: "960px", height: "50px", borderRadius: "7px", border: "1px solid var(--border-color)", background: "#0001", display: width < 1000 ? "block" : "none", cursor: "pointer", alignSelf: "center" }}>
                        <div style={{ width: "100%", height: "45px", background: "white", borderRadius: "7px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <VscSettings style={{ marginRight: "10px", width: "22px", height: "22px" }} />
                            <span style={{ fontSize: "17px", fontWeight: "bold" }}>{translation.filters}</span>
                        </div>
                    </div>

                    <FilterTool
                        initialIsShowingOfflineFilter={isShowingOfflineFilter}
                        updateIsShowingOfflineFilter={setIsShowingOfflineFilter}
                        initialMeetingTypeFilter={meetingTypeFilter}
                        updateMeetingTypeFilter={setMeetingTypeFilter}
                        initialNumberOfReviewsFilter={numberOfReviewsFilter}
                        updateNumberOfReviewsFilter={setNumberOfReviewsFilter}
                        initialPricePerMinuteFilter={pricePerMinuteFilter}
                        updatePricePerMinuteFilter={setPricePerMinuteFilter}
                        showFilter={showFilter || show} />
                </div>
            )
        )
    }

    useEffect(() => {
        function filterAdvisors(advisors) {
            if (skillFilter) {
                advisors = advisors.filter(advisor => advisor.get('profile.skills').includes(skillFilter));
            }
            if (!isShowingOfflineFilter) {
                advisors = advisors.filter(advisor => advisor.get('status.is_online') === true);
            }
            if (meetingTypeFilter) {
                advisors = advisors.filter(advisor => advisor.get(`live_modes.chat.active`) === true);
            }
            if (numberOfReviewsFilter) {
                advisors = advisors.filter(advisor => advisor.get('reviews.count.total') >= numberOfReviewsFilter.value);
            }
            if (pricePerMinuteFilter) {
                advisors = advisors.filter(advisor => (advisor.get('live_modes.chat.price.discount_price') >= pricePerMinuteFilter.min && advisor.get('live_modes.chat.price.discount_price') <= pricePerMinuteFilter.max));
            }
            return advisors;
        }

        async function fetchFilteredAdvisors() {
            setFilteredAdvisorsState({
                fetched: false,
                fetching: true,
                advisors: [],
            });
            const query_ = query(collection(db, 'users'), where('user_type', '==', 'advisor'));
            const advisorsSnapshot = await getDocs(query_);
            const advisors = advisorsSnapshot.docs;
            const filteredAdvisors = filterAdvisors(advisors);
            filteredAdvisors.forEach(advisor => {
                setFilteredAdvisorsState(prev => ({
                    ...prev,
                    advisors: [...prev.advisors, {
                        id: advisor.id,
                        ...advisor.data()
                    }]
                }))
            });
            setFilteredAdvisorsState(prev => ({ ...prev, fetched: true, fetching: false }))
            console.warn("ADVISORS FETCHED");
        }

        fetchFilteredAdvisors();
    }, [skillFilter, isShowingOfflineFilter, meetingTypeFilter, numberOfReviewsFilter, pricePerMinuteFilter]);

    return (
        <div className="readings">
            <div style={{ width: "100%", height: "500px", backgroundImage: `linear-gradient(to bottom, rgba(var(--${skill}-card-color-rgb), .5) 70%, rgba(255, 255, 255, 0))`, position: "absolute", top: "0", left: "0", zIndex: "0" }}></div>
            <div className="top-section" style={{ zIndex: "1" }}>
                <div className="top-section-container">
                    <div className="header">
                        <img src={img} alt="" />
                        <h1 style={{ color: `var(--${skill}-text-color)` }}>{header}</h1>
                    </div>
                    <span style={{ color: `var(--${skill}-text-color)` }}>{text}</span>
                </div>
            </div>
            <Filter width={width} />
            {
                filteredAdvisorsState.fetched ? (
                    filteredAdvisorsState.advisors.length > 0 ? (
                        <div className="profile-card-container">
                            {
                                filteredAdvisorsState.advisors.sort((a, b) => b.status.is_online - a.status.is_online).map(advisor => {
                                    return (
                                        <Profilecard advisor={advisor} />
                                    )
                                })
                            }
                        </div>
                    ) : (
                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center", marginTop: "60px", zIndex: "10" }}>
                            <span>{translation.didnt_find}</span>
                            <span style={{ marginTop: "2px" }}>{translation.clear_search}</span>
                            <div onClick={resetFilters} className='noselect' style={{ width: "220px", height: "55px", borderRadius: "8px", background: "#0001", border: "1px solid var(--border-color)", marginTop: "15px", cursor: "pointer" }}>
                                <div style={{ width: "100%", height: "50px", background: "white", borderRadius: "7px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    <CgClose style={{ marginRight: "10px", width: "25px", height: "25px" }} />
                                    <span style={{ fontSize: "18px" }}>{translation.clear_filters}</span>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="profile-card-container">
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                        <ProfileCardSkeletion />
                    </div>
                )
            }
        </div>
    )
}

export default Readings