import React, { useState } from 'react';
import './advisorProfile.css';
import { GiRoundStar } from 'react-icons/gi';
import { HiOutlineHeart, HiHeart, HiEmojiSad, HiEmojiHappy, HiOutlineBell, HiBell } from 'react-icons/hi';
import { BsPlayFill } from 'react-icons/bs';
import coinImg from '../../assets/ic_coin.png';
import chatImgActive from '../../assets/ic_chat-active.png';
import chatImgBusy from '../../assets/ic_chat-busy.png';
import videoImgActive from '../../assets/ic_video-active.png';
import videoImgBusy from '../../assets/ic_video-busy.png';
import voiceImgActive from '../../assets/ic_voice-active.png';
import voiceImgBusy from '../../assets/ic_voice-busy.png';
import voiceImg from '../../assets/ic_voice-disable.png';
import videoImg from '../../assets/ic_video-disable.png';
import chatImg from '../../assets/ic_chat-disable.png';
import Card from '../../components/skillscard/skillscard';
import useScrollPosition from '../../hooks/scrollHook';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoUrl } from '../../provider/site/site';
import firebase from '../../config/firebase';
import { useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { PulseLoader } from 'react-spinners';
import FavouriteCRUD from '../../functions/favourite';
import useTranslation from '../../localization/translations';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useSendMeetingRequest from '../../hooks/useSendMeetingRequest';
import NotificationControl from '../../functions/notification';
import Firestore from '../../functions/firestore';
import ReviewCard from '../../components/reviewcard/reviewcard';
import ReviewSkeletion from '../../components/reviewcard/reviewskeletion';

const AdvisorProfile = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const translation = useTranslation();
  const [reviewsState, setReviewsState] = useState({
    all: {
        reviews: [],
        startAfter: null,
        fetched: false,
        fetchFunction: Firestore.fetchAllReviews,
    },
    positive: {
        reviews: [],
        startAfter: null,
        fetched: false,
        fetchFunction: Firestore.fetchPositiveReviews,
    },
    negative: {
        reviews: [],
        startAfter: null,
        fetched: false,
        fetchFunction: Firestore.fetchNegativeReviews,
    },
    limit: 5,
    fetching: false,
    selectedType: "all",
  });
  const { user, language } = useSelector(state => state.site);
  var positionY = useScrollPosition(document.getElementById('root'));
  const { width } = useWindowDimensions();
  const sendMeetingRequest = useSendMeetingRequest();
  const [advisor, setAdvisor] = useState();
  const advisorId = location.state.id && location.state.id;
  const isAccessible = advisor ? advisor.status.busy || !advisor.status.is_online : null;

  useEffect(() => {
    const app = document.getElementById('app');
    if(app && width < 750) {
      app.style.marginBottom = "60px";
    }
    

    return () => {
      if(app) {
        app.style.marginBottom = "0";
      }
    }
  }, [width]);

  useEffect(() => {
    let advisorOnSnapshot = null;

    if(advisorId) {
      advisorOnSnapshot = onSnapshot(doc(firebase.db, `users/${advisorId}`), function (snapshot) {
        setAdvisor(snapshot.data());
      })
    } else {
      navigate('/');
    }

    return () => {
      if(advisorOnSnapshot) {
        advisorOnSnapshot();
      }
    }

  }, [advisorId]);

  useEffect(() => {
    if(reviewsState.fetching && !reviewsState[reviewsState.selectedType].fetched) {
      const reviewType = reviewsState.selectedType;
      reviewsState[reviewType].fetchFunction(advisorId, reviewsState[reviewType].startAfter, reviewsState.limit).then(({reviews, startAfter, fetched}) => {
        setReviewsState(prev => ({
          ...prev,  
          [reviewType]: ({
            ...prev[reviewType],
            reviews: [...prev[reviewType].reviews, ...reviews],
            startAfter: startAfter,
            fetched: fetched,
          }),
          fetching: false,
        }));
      });
    }
  }, [reviewsState.selectedType, reviewsState.fetching]);

  useEffect(() => {
    if(advisorId) {
      let all = null;
      let positive = null;
      let negative = null;
      async function fetchReviews() {
        setReviewsState(prev => ({...prev, fetching: true}));
        await reviewsState.all.fetchFunction(advisorId, null, 10).then((allState) => all = allState);
        await reviewsState.positive.fetchFunction(advisorId, null, 10).then((positiveState) => positive = positiveState);
        await reviewsState.negative.fetchFunction(advisorId, null, 10).then((negativeState) => negative = negativeState);
        setReviewsState(prev => ({
          ...prev,
          all: {
            ...prev.all,
            ...all
          },
          positive: {
            ...prev.positive,
            ...positive
          },
          negative: {
            ...prev.negative,
            ...negative
          },
          fetching: false,
        }))
      }

      fetchReviews();
    }
  }, [advisorId]);

  async function deleteFavourites() {
    try {
      await FavouriteCRUD.delete(advisorId);
    } catch (e) {
      alert(e)
    }
  }

  async function addFavourites() {
    try {
      await FavouriteCRUD.add(advisorId);
    } catch (e) {
      alert(e)
    }
  }

  const Fortune = (props) => {
    const { id } = props;

    return (
      <div className="ad-p-info-bottom-sec" id={id}>

        <div className="reading">
          <span>
            {advisor.reviews.count.total + ' ' +translation.reading}
          </span>
        </div>

        <div className="reviews">
          <div>
            <HiEmojiHappy className="happy-icon" />
            <span>{advisor.reviews.count.positive}</span>
          </div>

          <div>
            <HiEmojiSad className="sad-icon" />
            <span>{advisor.reviews.count.negative}</span>
          </div>
        </div>

      </div>
    )
  }

  const HorizontalCard = (activeimg, disableImg, busyImg, text, status) => {
    const img = isAccessible ? busyImg : status ? activeimg : disableImg;
    const color = isAccessible ? "var(--disabled-color)" : status ? `var(--${text.toLowerCase()}-active-color)` : "var(--disabled-color)";

    return (
      <div className="horizontal-card" style={{ width: "140px", height: "45px", borderRadius: "5px", backgroundColor: color, display: "flex", alignItems: "center", paddingLeft: "15px", cursor: "pointer", opacity: isAccessible ? ".5" : status ? "1" : ".5", pointerEvents: isAccessible ? "none" : status ? "all" : "none" }} onClick={() => sendMeetingRequest(advisorId, text.toLowerCase())}>
        <img src={img} alt="" style={{ width: "35px", height: "35px", marginTop: "6px", marginRight: "5px" }} />
        <span style={{ color: isAccessible ? "black" : status ? "white" : "black", fontWeight: "bold", fontSize: "18px" }}>{translation[text.toLowerCase()]}</span>
      </div>
    )
  }

  const BottomHorizontalCard = (activeimg, disableImg, busyImg, text, status) => {
    const img = isAccessible ? busyImg : status ? activeimg : disableImg;
    const color = isAccessible ? "var(--disabled-color)" : status ? `var(--${text.toLowerCase()}-active-color)` : "var(--disabled-color)";

    return (
      <div className="bottom-horizontal-card" style={{ width: "100px", height: "35px", borderRadius: "5px", backgroundColor: color, display: "flex", alignItems: "center", paddingLeft: "5px", cursor: "pointer", opacity: isAccessible ? ".5" : status ? "1" : ".5", pointerEvents: isAccessible ? "none" : status ? "all" : "none" }} onClick={() => sendMeetingRequest(advisorId, text.toLowerCase())}>
        <img src={img} alt="" style={{ width: "25px", height: "25px", marginTop: "6px", marginRight: "5px" }} />
        <span style={{ color: isAccessible ? "black" : status ? "white" : "black", fontWeight: "bold", fontSize: "16px" }}>{translation[text.toLowerCase()]}</span>
      </div>
    )
  }

  return (

    advisor ?
      (
        <div className="advisor">
          <div className="top-banner"></div>
          {
            positionY > 400 && (
              <>
                <div className="top-fixed">
                  <div className="top-fixed-container">
                    <div className="top-fixed-info-container">
                      <div className="top-fixed-image">
                        <img src={advisor.profile.pic_url} alt="" />
                      </div>
                      <div className="top-fixed-name-skill">
                        <span className="top-fixed-name">{advisor.profile.name}</span>
                        <div className="row">
                          {
                            advisor.profile.skills.sort().map(function (skill, index) {
                              const skill_ = translation[skill.toLowerCase()];
                              const isLast = index === (advisor.profile.skills.length - 1);
                              return (
                                <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>

                    <div className="top-fixed-stream-container noselect">
                      {HorizontalCard(chatImgActive, chatImg, chatImgBusy, "Chat", advisor.live_modes.chat.active)}
                      {HorizontalCard(voiceImgActive, voiceImg, voiceImgBusy, "Voice", advisor.live_modes.voice.active)}
                      {HorizontalCard(videoImgActive, videoImg, videoImgBusy, "Video", advisor.live_modes.video.active)}
                    </div>

                    <div className="top-fixed-icon-container">
                      {
                        user && (
                          <>
                            {user.notify && user.notify.includes(advisorId) ? <HiBell className='icon' style={{color: "var(--green-color)", cursor: "pointer"}} onClick={() => NotificationControl.removeNotify(advisorId)}/> : <HiOutlineBell className='icon' style={{"cursor": "pointer"}} onClick={() => NotificationControl.addNotify(advisorId)}/>}
                            {user.favourites && user.favourites.includes(advisorId) ? <HiHeart className="icon" style={{ color: "var(--red-color)", cursor: "pointer" }} onClick={deleteFavourites} /> : <HiOutlineHeart className="icon" style={{ color: "#000", cursor: "pointer" }} onClick={addFavourites} />}
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>

                {
                  width < 750 && (
                    <div className="bottom-fixed noselect" id="bottom-fixed">
                    {BottomHorizontalCard(chatImgActive, chatImg, chatImgBusy, "Chat", advisor.live_modes.chat.active)}
                    {BottomHorizontalCard(voiceImgActive, voiceImg, voiceImgBusy, "Voice", advisor.live_modes.voice.active)}
                    {BottomHorizontalCard(videoImgActive, videoImg, videoImgBusy, "Video", advisor.live_modes.video.active)}
                  </div>
                  )
                }
              </>

            )
          }


          <div className="advisor-profile">
            <div className="advisor-profile-first-sec">
              <div className="advisor-profile-first-sec-container">
                <div className="advisor-profile-pic">
                  <img src={advisor.profile.pic_url} alt="" style={{ width: "100%", height: "100%", borderRadius: "20px", filter: "brightness(90%)" }} />
                  <div className="filter"></div>
                  <div className="advisor-profile-pic-status-flag">
                    <div style={{ background: advisor.status.busy ? "var(--red-color)" : advisor.status.is_online ? "var(--green-color)" : "var(--red-color)" }}>
                      <span>{advisor.status.busy ? translation.busy : advisor.status.is_online ? translation.online : translation.offline}</span>
                    </div>
                  </div>
                  <div style={{ width: "60px", height: "60px", borderRadius: "50%", border: "2px solid rgba(255,255,255,.8)", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", cursor: "pointer", zIndex: "999" }} onClick={() => dispatch(setVideoUrl(advisor.profile.video_url))}>
                    <BsPlayFill style={{ width: "45px", height: "45px", color: "#fff", marginLeft: "6px" }} />
                  </div>
                  <div className="profile-info" style={{ width: "100%", height: "100%", position: "absolute", top: "0" }}>
                    <div className="advisor-profile-pic-point-favorite">
                      <div>
                        <GiRoundStar className='point-icon' style={{ color: "orange", width: "18px", height: "18px" }} />
                        <span className='point'>{advisor.reviews.point_avg}</span>
                      </div>
                      <div>
                        {
                          user && (
                            <>
                              {user.notify && user.notify.includes(advisorId) ? <HiBell className='advisor-profile-pic-icon' style={{color: "var(--green-color)", cursor: "pointer"}} onClick={() => NotificationControl.removeNotify(advisorId)}/> : <HiOutlineBell className='advisor-profile-pic-icon' onClick={() => NotificationControl.addNotify(advisorId)} style={{color: "white", cursor: "pointer"}}/>}
                              {user.favourites && user.favourites.includes(advisorId) ? <HiHeart className="advisor-proile-pic-icon" style={{ width: "30px", height: "30px", color: "var(--red-color)", cursor: "pointer" }} onClick={deleteFavourites} /> : <HiOutlineHeart className="advisor-profile-pic-icon" style={{ color: "#fff", cursor: "pointer" }} onClick={addFavourites} />}
                            </>
                          )
                        }
                      </div>
                    </div>

                    <div className="advisor-profile-pic-info">
                      <span>
                        {advisor.profile.name}
                      </span>
                      <div className="row">
                        {
                          advisor.profile.skills.map(function (skill, index) {
                            const skill_ = translation[skill.toLowerCase()];
                            const isLast = index === (advisor.profile.skills.length - 1);
                            return (
                              <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className="advisor-profile-info">
                  <div className="ad-p-info-top-sec">
                    <div className="ad-p-info-name-skills-point">
                      <div className="ad-p-info-name-skills">
                        <span>
                          {advisor.profile.name}
                        </span>
                        <div className="row">
                          {
                            advisor.profile.skills.map(function (skill, index) {
                              const skill_ = translation[skill.toLowerCase()];
                              const isLast = index === (advisor.profile.skills.length - 1);
                              return (
                                <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                              )
                            })
                          }
                        </div>
                      </div>
                      <div className="ad-p-info-point">
                        <GiRoundStar className='point-icon' style={{ width: "18px", height: "18px", color: "orange" }} />
                        <span className='point'>{advisor.reviews.point_avg}</span>
                      </div>
                    </div>
                    <div className="ad-p-info-bell-heart">
                      {
                        user && (
                          <>
                            {user.notify && user.notify.includes(advisorId) ? <HiBell className='bell-icon' style={{ width: "30px", height: "30px", color: "var(--default-text-color)", cursor: "pointer", color: "var(--green-color)"}} onClick={() => NotificationControl.removeNotify(advisorId)}/> : <HiOutlineBell className='bell-icon' onClick={() => NotificationControl.addNotify(advisorId)} style={{ width: "30px", height: "30px", color: "var(--default-text-color)", cursor: "pointer" }}/>}
                            {user.favourites && user.favourites.includes(advisorId) ? <HiHeart className='heart-icon' style={{ width: "30px", height: "30px", color: "var(--red-color)", cursor: "pointer" }} onClick={deleteFavourites} /> : <HiOutlineHeart className='heart-icon' style={{ width: "30px", height: "30px", color: "var(--default-text-color)", cursor: "pointer" }} onClick={addFavourites} />}
                          </>
                        )
                      }
                    </div>
                  </div>

                  <div className="ad-p-info-mid-sec noselect">
                    <div className="chat-call call" style={{ background: isAccessible ? "var(--disabled-color)" : advisor.live_modes.chat.active ? "var(--chat-active-color)" : "var(--disabled-color)", opacity: isAccessible ? ".5" : advisor.live_modes.chat.active ? "1" : ".5", pointerEvents: isAccessible ? "none" : advisor.live_modes.chat.active ? "all" : "none" }} onClick={() => sendMeetingRequest(advisorId, "chat")}>
                      <div style={{ backgroundImage: `url(${isAccessible ? chatImgBusy : advisor.live_modes.chat.active ? chatImgActive : chatImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ color: isAccessible ? "black" : advisor.live_modes.chat.active ? "white" : "black" }}>{translation.chat}</span>
                        <div className="row" style={{ display: "flex", flexDirection: "row", marginTop: "2px" }}>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.chat.active ? "rgb(245,245,245)" : "grey", marginRight: "2px", fontWeight: "normal" }}>{advisor.live_modes.chat.price.discount_price + ' '}</span>
                          <img src={coinImg} style={{ width: "20px", height: "20px", marginRight: "1px" }}></img>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.chat.active ? "rgb(245,245,245)" : "grey", fontWeight: "normal", marginRight: "1px" }}>/</span>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.chat.active ? "rgb(245,245,245)" : "grey", fontWeight: "normal" }}>min</span>
                        </div>
                      </div>
                      <div>
                        <span style={{ color: isAccessible ? "var(--disabled-color)" : advisor.live_modes.chat.active ? "var(--chat-active-color)" : "var(--disabled-color)" }}>{translation.live}</span>
                      </div>
                    </div>

                    <div className="voice-call call" style={{ background: isAccessible ? "var(--disabled-color)" : advisor.live_modes.voice.active ? "var(--voice-active-color)" : "var(--disabled-color)", opacity: isAccessible ? ".5" : advisor.live_modes.voice.active ? "1" : ".5", pointerEvents: isAccessible ? "none" : advisor.live_modes.voice.active ? "all" : "none" }} onClick={() => sendMeetingRequest(advisorId, "voice")}>
                      <div style={{ backgroundImage: `url(${isAccessible ? voiceImgBusy : advisor.live_modes.voice.active ? voiceImgActive : voiceImg})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ color: isAccessible ? "black" : advisor.live_modes.voice.active ? "white" : "black", whiteSpace: "nowrap" }}>{translation.voice_call}</span>
                        <div className="row" style={{ display: "flex", flexDirection: "row", marginTop: "2px" }}>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.voice.active ? "rgb(245,245,245)" : "grey", marginRight: "2px", fontWeight: "normal" }}>{advisor.live_modes.voice.price.discount_price + ' '}</span>
                          <img src={coinImg} style={{ width: "20px", height: "20px" }}></img>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.voice.active ? "rgb(245,245,245)" : "grey", fontWeight: "normal" }}>/min</span>
                        </div>
                      </div>
                      <div>
                        <span style={{ color: isAccessible ? "var(--disabled-color)" : advisor.live_modes.voice.active ? "var(--voice-active-color)" : "var(--disabled-color)" }}>{translation.live}</span>
                      </div>
                    </div>

                    <div className="video-call call" style={{ background: isAccessible ? "var(--disabled-color)" : advisor.live_modes.video.active ? "var(--video-active-color)" : "var(--disabled-color)", opacity: isAccessible ? ".5" : advisor.live_modes.video.active ? "1" : ".5", pointerEvents: isAccessible ? "none" : advisor.live_modes.video.active ? "all" : "none" }} onClick={() => sendMeetingRequest(advisorId, "video")}>
                      <div style={{ backgroundImage: `url(${isAccessible ? videoImgBusy : advisor.live_modes.video.active ? videoImgActive : videoImg})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }}></div>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ color: isAccessible ? "black" : advisor.live_modes.video.active ? "white" : "black" }}>{translation.video_call}</span>
                        <div className="row" style={{ display: "flex", flexDirection: "row", marginTop: "2px" }}>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.video.active ? "rgb(245,245,245)" : "grey", marginRight: "2px", fontWeight: "normal" }}>{advisor.live_modes.video.price.discount_price + ' '}</span>
                          <img src={coinImg} style={{ width: "20px", height: "20px" }}></img>
                          <span style={{ color: isAccessible ? "grey" : advisor.live_modes.video.active ? "rgb(245,245,245)" : "grey", fontWeight: "normal" }}>/min</span>
                        </div>
                      </div>
                      <div>
                        <span style={{ color: isAccessible ? "var(--disabled-color)" : advisor.live_modes.video.active ? "var(--video-active-color)" : "var(--disabled-color)" }}>{translation.live}</span>
                      </div>
                    </div>
                  </div>
                  <Fortune key="fortune-pc" id="fortune-pc" />
                </div>
              </div>
              <Fortune key="fortune-pc" id="fortune" />
            </div>

            <div className="advisor-profile-second-sec">
              <div className="advisor-description">
                <h3>{translation.about_my_services}</h3>
                <span className="description-text">{advisor.profile.translated_description ? advisor.profile.translated_description[language ? language.toLowerCase() : "en"] : advisor.profile.description}</span>
                <h3>{translation.about_me}</h3>
                <span  className="description-text">{advisor.profile.translated_about_me ? advisor.profile.translated_about_me[language ? language.toLowerCase() : "en"] : advisor.profile.about_me}</span>
                <h3>{translation.specialities}</h3>
                <div className="skills noscrollbar noselect">
                  {
                    advisor.profile.skills.map((skill, index) => {
                      return (
                        <Card key={skill + index} skill={skill} />
                      )
                    })
                  }
                </div>
              </div>
              <div className="advisor-reviews">
                <div className="advisor-reviews-bar noselect">
                  <div className="advisor-reviews-bar-container">
                    <div style={{display: "flex", borderBottom: reviewsState.selectedType === "all" ? "4px solid var(--default-text-color)" : null}} onClick={() => setReviewsState(prev => ({...prev, selectedType: "all"}))}>
                        <span>Reviews</span>
                    </div>

                    <div style={{cursor: "pointer", borderBottom: reviewsState.selectedType === "positive" ? "4px solid var(--default-text-color)" : null}} onClick={() => setReviewsState(prev => ({...prev, selectedType: "positive"}))}>
                        <HiEmojiHappy style= {{color: "var(--green-color)"}} className= "advisor-reviews-bar-container-icon"/>
                        <span>{advisor.reviews.count.positive}</span>
                    </div>

                    <div style={{cursor: "pointer", borderBottom: reviewsState.selectedType === "negative" ? "4px solid var(--default-text-color)" : null}} onClick={() => setReviewsState(prev => ({...prev, selectedType: "negative"}))}>
                        <HiEmojiSad style={{color: "var(--red-color)"}} className= "advisor-reviews-bar-container-icon"/>   
                        <span>{advisor.reviews.count.negative}</span> 
                    </div>
                  </div>
                </div>
                <div className="advisor-reviews-comment">
                  {
                    reviewsState[reviewsState.selectedType].reviews.length > 0 && reviewsState[reviewsState.selectedType].reviews.map((review) => {
                      return (
                        <ReviewCard review={review}/>
                      )
                    })
                  }
                  {reviewsState.fetching ? (
                    <div className="review-skeletions">
                      <ReviewSkeletion/>
                      <ReviewSkeletion/>
                      <ReviewSkeletion/>
                      <ReviewSkeletion/>
                      <ReviewSkeletion/>
                      <ReviewSkeletion/>
                      <ReviewSkeletion/>
                    </div>
                    ) : !reviewsState[reviewsState.selectedType].fetched && 
                      <span className='comment-seemore noselect' id="comment-seemore" onClick= {() => setReviewsState(prev => ({...prev, fetching: true}))}>+ {translation.see_more}</span> 
                   }
                </div>
              </div>
            </div>
          </div>
        </div>
      ) :
     (
      <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <PulseLoader color="var(--theme-color)" />
      </div>
     )

  )
}

export default AdvisorProfile