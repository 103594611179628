import Readings from '../../components/readingscreen/readingscreen';
import careerImg from '../../assets/ic_career.png';
import useTranslation from '../../localization/translations';

const Career = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"career"} img={careerImg} header={"Career readings"} text={translation.career_description} skillFilter={"Career"}/>
  )
}

export default Career