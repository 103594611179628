import React, { useEffect } from 'react';
import './meeting.scss';
import firebase from '../../../config/firebase';
import { arrayUnion, collection, doc, getDoc, getDocs, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
import { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { setRejectMessage } from '../../../provider/site/site';
import useTranslation from '../../../localization/translations';
import { ref as dbRef, update } from 'firebase/database';

const MeetingSection = () => {

    const { user, meeting, rejectMessage } = useSelector(state => state.site);

    const dispatch = useDispatch(false);
    const translation = useTranslation();
    const [loading, setLoading] = useState(false);

    const [showRejectModal, setShowRejectModal] = useState(false);

    const closeRejectModal = () => setShowRejectModal(false);
    const openRejectModal = () => setShowRejectModal(true);

    const cancelForFirestore = async () => {
        try {
            // UPDATE MEETING REQUEST STATUS
            await updateDoc(doc(firebase.db, `meetings/${meeting.id}`), {
                'status': 'cancelled',
                'cancelled_by': meeting.data.customer_id
            });

            // UPDATE USER BUSY STATUS
            await updateDoc(doc(firebase.db, `users/${meeting.data.customer_id}`), {
                'status.meeting': null,
            });

            // UPDATE USER BUSY STATUS
            await updateDoc(doc(firebase.db, `users/${meeting.data.advisor_id}`), {
                'status.meeting': null,
            });

            await update(dbRef(firebase.rDb, `busy_status`), {
                [meeting.data.advisor_id]: false
            });
        } catch (e) {
            throw e.message;
        }
    }

    const rejectForFirestore = async (message) => {
        try {
            // UPDATE MEETING REQUEST STATUS
            await updateDoc(doc(firebase.db, `meetings/${meeting.id}`), {
                'status': 'rejected',
                'reject_message': message,
            });

            // UPDATE USER BUSY STATUS
            await updateDoc(doc(firebase.db, `users/${meeting.data.customer_id}`), {
                'status.meeting': null,
            });

            // UPDATE USER BUSY STATUS
            await updateDoc(doc(firebase.db, `users/${meeting.data.advisor_id}`), {
                'status.meeting': null,
            });

            await update(dbRef(firebase.rDb, `busy_status`), {
                [meeting.data.advisor_id]: false
            })
        } catch (e) {
            throw e.message;
        }
    }

    const acceptForFirestore = async () => {
        const customerData = (await getDoc(doc(firebase.db, `users/${meeting.data.customer_id}`))).data();
        const balance = customerData.balance;
        const price = meeting.data.price;
        const will_terminate = new Date(new Date().getTime() + 60000 * ((balance / price).toFixed(0)));
        const customerId = meeting.data.customer_id;
        const advisorId = meeting.data.advisor_id;
        const meetingId = meeting.id;

        try {
            const customerContact = (await getDoc(doc(firebase.db, `users/${customerId}/chat/${advisorId}`)))
            const advisorContact = (await getDoc(doc(firebase.db, `users/${advisorId}/chat/${customerId}`)))
            if (customerContact.exists()) {
                await updateDoc(doc(firebase.db, `users/${customerId}/chat/${advisorId}`), {
                    'meeting': meetingId,
                })
            } else {
                setDoc(doc(firebase.db, `users/${customerId}/chat/${advisorId}`), {
                    'user_id': user.uid,
                    'user_name': user.profile.name,
                    'user_image': user.profile.pic_url ? user.profile.pic_url : null,
                    'last_message': null,
                    'last_message_date': null,
                    'meeting': meetingId,
                });
            }
            if (advisorContact.exists()) {
                await updateDoc(doc(firebase.db, `users/${advisorId}/chat/${customerId}`), {
                    'meeting': meetingId,
                })
            } else {
                setDoc(doc(firebase.db, `users/${advisorId}/chat/${customerId}`), {
                    'user_id': customerId,
                    'user_name': customerData.profile.name,
                    'user_image': customerData.profile.pic_url ? customerData.profile.pic_url : null,
                    'last_message': null,
                    'last_message_date': null,
                    'meeting': meetingId,
                });
            }

            await fetchOffers(() => {

            }).then(async (offers) => {
                await updateDoc(doc(firebase.db, `meetings/${meetingId}`), {
                    'status': 'accepted',
                    'started_at': serverTimestamp(),
                    'offers': offers,
                    'will_terminate': will_terminate,
                });
            });

            await updateDoc(firebase.db, `users/${customerId}`, {
                'notify': arrayUnion(advisorId)
            });
            
        } catch (e) {
            throw e.message;
        }
    }

    async function fetchOffers() {
        let offers = {};
        try {
            const offersSnapshot = (await getDocs(collection(firebase.db, `offers`))).docs;
            offersSnapshot.forEach(offerSnapshot => {
                const id = offerSnapshot.id;
                offers[id] = {
                    is_used: false,
                    purchased: false,
                    remaining_time: null,
                    data: offerSnapshot.data()
                }
            });
        } catch (error) {
            console.log(error);
            throw error;
        }
        return offers;
    }

    const startCall = async () => {
        setLoading(true);
        acceptForFirestore().then(function () {
            setLoading(false);
        }).catch(e => {
            alert(e);
            setLoading(false);
        });
    }

    const rejectCall = async (message) => {
        setLoading(true);
        rejectForFirestore(message).then(function () {
            setLoading(false);
            setShowRejectModal(false);
        }).catch(e => {
            alert(e);
            setLoading(false);
        });
    }

    const cancelCall = async () => {
        setLoading(true);
        cancelForFirestore().then(function () {
            setLoading(false);
        }).catch(e => {
            alert(e);
        });
    }

    function RejectModalAdvisor() {
        const [message, setMessage] = useState('');
        const handleInput = e => setMessage(e.target.value);

        return (
            <div className="reject-modal-advisor modal modal-anim" style={{ display: showRejectModal ? "flex" : "none" }}>
                <div className='loading' style={{ display: loading ? "flex" : "none" }}>
                    <PulseLoader color="var(--theme-color)" size={20} margin={5} />
                </div>

                <h2>{translation.reject_meeting}</h2>
                <textarea name="reject-message" id="reject-message" cols="30" rows="10" onChange={handleInput} placeholder="Your excuse message must be more then 20 characters"></textarea>
                <div className="bottom">
                    <div className="apply" onClick={() => rejectCall(message)} style={{ pointerEvents: message.length > 20 ? "all" : "none", opacity: message.length > 20 ? "1" : ".5" }}>{translation.reject}</div>
                    <div className="close" onClick={closeRejectModal}>Close</div>
                </div>
            </div>
        )
    }


    return (
        <>
            {
                user && user.user_type === "customer" && rejectMessage && (
                    <div className="modal-container" onClick={() => dispatch(setRejectMessage(''))}>
                        <div className="modal-anim modal reject-modal-customer" onClick={(e) => e.stopPropagation()}>
                            <h2>{translation.advisor_busy}</h2>
                            <h4>{translation.message_from_advisor}</h4>
                            <textarea cols="30" rows="10" readOnly="true" spellCheck="false" value={rejectMessage} />
                            <div className="close" onClick={() => dispatch(setRejectMessage(''))}>{translation.close}</div>
                        </div>
                    </div>
                )
            }
            {
                (user && meeting && meeting.data.status === "pending") && (
                    <div className="modal-container noselect">
                        {
                            user.user_type === "advisor" ? (
                                showRejectModal ? (
                                    <RejectModalAdvisor />
                                ) : (
                                    <div className="advisor-section row">
                                        <div className="start-button" onClick={startCall}>{translation.join_meeting}</div>
                                        <div className="call" style={{ background: `var(--${meeting.data.meeting_type}-active-color)` }}>
                                            <div className="circle-anim1" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .5)` }}></div>
                                            <div className="circle-anim2" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .3)` }}></div>
                                            {
                                                meeting.data.meeting_type === "chat" ?
                                                    (
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.21875 8.9553C1.21875 4.68064 4.68407 1.21533 8.95875 1.21533C13.2334 1.21533 16.6987 4.68064 16.6987 8.9553C16.6987 13.23 13.2334 16.6953 8.95875 16.6953H1.21875V8.9553Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                        </svg>
                                                    ) :
                                                    meeting.data.meeting_type === "voice" ?
                                                        (
                                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3984 16.318C13.3175 16.318 13.2367 16.3146 13.1522 16.3112C12.1569 16.2465 11.1615 15.9691 9.91999 15.4108C7.72623 14.3947 5.81455 12.9768 4.23167 11.193C2.9668 9.75985 2.0415 8.29605 1.48273 6.84075C1.20425 6.11396 0.931151 5.24419 1.0156 4.2825C1.04434 3.67656 1.31744 3.11827 1.80434 2.68764L3.03867 1.51659C3.76273 0.830648 4.75451 0.827244 5.50553 1.50979C5.7373 1.71233 5.96368 1.93361 6.18287 2.14637C6.29247 2.2519 6.40207 2.35913 6.51526 2.46636L7.49266 3.3923C8.24188 4.13272 8.24188 5.04334 7.49985 5.77694C7.28424 5.98119 7.07404 6.17353 6.86562 6.36587C6.68775 6.52927 6.50987 6.69267 6.3338 6.85948C6.54581 7.31223 6.85664 7.76499 7.32917 8.31137C8.31734 9.45007 9.27318 10.2722 10.3368 10.8969L10.3602 10.9105C10.4931 10.999 10.6566 11.0756 10.8291 11.1573C10.8758 11.1794 10.9243 11.2015 10.9711 11.2254L12.1281 10.1292C12.4857 9.79049 12.9133 9.61177 13.366 9.61177C13.8188 9.61177 14.2464 9.79049 14.604 10.1292L16.6073 12.027C17.3547 12.7351 17.3547 13.6866 16.6073 14.3947C16.4761 14.5189 16.3432 14.6415 16.2138 14.7623C15.9533 15.004 15.7071 15.2321 15.47 15.4721C14.9543 16.0304 14.2554 16.318 13.3984 16.318Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                            </svg>

                                                        ) :
                                                        meeting.data.meeting_type === "video" &&
                                                        (
                                                            <svg className="call-img" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9 1H2.7C1.76112 1 1 1.72754 1 2.625V12.375C1 13.2725 1.76112 14 2.7 14H12.9C13.8389 14 14.6 13.2725 14.6 12.375V2.625C14.6 1.72754 13.8389 1 12.9 1Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                                <path d="M14.6 5.0625L17.5055 2.97955C17.7092 2.83346 18 2.97244 18 3.21591V11.7841C18 12.0276 17.7092 12.1665 17.5055 12.0205L14.6 9.9375V5.0625Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                            </svg>
                                                        )

                                            }
                                        </div>
                                        <div className="reject-button" onClick={openRejectModal}>{translation.not_available}</div>
                                    </div>
                                )
                            ) : (
                                <div className="customer-section row">
                                    <div className="start-button">{translation.waiting}</div>
                                    <div className="call" style={{ background: `var(--${meeting.data.meeting_type}-active-color)` }}>
                                        <div className="circle-anim1" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .5)` }}></div>
                                        <div className="circle-anim2" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .3)` }}></div>
                                        {
                                            meeting.data.meeting_type === "chat" ?
                                                (
                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M1.21875 8.9553C1.21875 4.68064 4.68407 1.21533 8.95875 1.21533C13.2334 1.21533 16.6987 4.68064 16.6987 8.9553C16.6987 13.23 13.2334 16.6953 8.95875 16.6953H1.21875V8.9553Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                    </svg>
                                                ) :
                                                meeting.data.meeting_type === "voice" ?
                                                    (
                                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3984 16.318C13.3175 16.318 13.2367 16.3146 13.1522 16.3112C12.1569 16.2465 11.1615 15.9691 9.91999 15.4108C7.72623 14.3947 5.81455 12.9768 4.23167 11.193C2.9668 9.75985 2.0415 8.29605 1.48273 6.84075C1.20425 6.11396 0.931151 5.24419 1.0156 4.2825C1.04434 3.67656 1.31744 3.11827 1.80434 2.68764L3.03867 1.51659C3.76273 0.830648 4.75451 0.827244 5.50553 1.50979C5.7373 1.71233 5.96368 1.93361 6.18287 2.14637C6.29247 2.2519 6.40207 2.35913 6.51526 2.46636L7.49266 3.3923C8.24188 4.13272 8.24188 5.04334 7.49985 5.77694C7.28424 5.98119 7.07404 6.17353 6.86562 6.36587C6.68775 6.52927 6.50987 6.69267 6.3338 6.85948C6.54581 7.31223 6.85664 7.76499 7.32917 8.31137C8.31734 9.45007 9.27318 10.2722 10.3368 10.8969L10.3602 10.9105C10.4931 10.999 10.6566 11.0756 10.8291 11.1573C10.8758 11.1794 10.9243 11.2015 10.9711 11.2254L12.1281 10.1292C12.4857 9.79049 12.9133 9.61177 13.366 9.61177C13.8188 9.61177 14.2464 9.79049 14.604 10.1292L16.6073 12.027C17.3547 12.7351 17.3547 13.6866 16.6073 14.3947C16.4761 14.5189 16.3432 14.6415 16.2138 14.7623C15.9533 15.004 15.7071 15.2321 15.47 15.4721C14.9543 16.0304 14.2554 16.318 13.3984 16.318Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                        </svg>

                                                    ) :
                                                    meeting.data.meeting_type === "video" &&
                                                    (
                                                        <svg className="call-img" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.9 1H2.7C1.76112 1 1 1.72754 1 2.625V12.375C1 13.2725 1.76112 14 2.7 14H12.9C13.8389 14 14.6 13.2725 14.6 12.375V2.625C14.6 1.72754 13.8389 1 12.9 1Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                            <path d="M14.6 5.0625L17.5055 2.97955C17.7092 2.83346 18 2.97244 18 3.21591V11.7841C18 12.0276 17.7092 12.1665 17.5055 12.0205L14.6 9.9375V5.0625Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
                                                        </svg>
                                                    )

                                        }
                                    </div>
                                    <div className="reject-button" onClick={cancelCall}>{translation.cancel_call}</div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default MeetingSection

// import React, { useEffect } from 'react';
// import './meeting.scss';
// import { FaPhone } from 'react-icons/fa';
// import firebase from '../../../config/firebase';
// import { arrayUnion, collection, doc, getDoc, getDocs, serverTimestamp, setDoc, updateDoc } from 'firebase/firestore';
// import { useState } from 'react';
// import { PulseLoader } from 'react-spinners';
// import { useDispatch, useSelector } from 'react-redux';
// import { setRejectMessage } from '../../../provider/site/site';
// import useTranslation from '../../../localization/translations';

// const MeetingSection = () => {

//     const { user, meeting, rejectMessage } = useSelector(state => state.site);

//     const dispatch = useDispatch(false);
//     const translation = useTranslation();
//     const [loading, setLoading] = useState(false);

//     const [showRejectModal, setShowRejectModal] = useState(false);

//     const closeRejectModal = () => setShowRejectModal(false);
//     const openRejectModal = () => setShowRejectModal(true);

//     const cancelForFirestore = async () => {
//         try {
//             // UPDATE MEETING REQUEST STATUS
//             await updateDoc(doc(firebase.db, `meetings/${meeting.id}`), {
//                 'status': 'cancelled',
//                 'cancelled_by': meeting.data.customer_id
//             });

//             // UPDATE USER BUSY STATUS
//             await updateDoc(doc(firebase.db, `users/${meeting.data.customer_id}`), {
//                 'status.busy': false,
//                 'status.meeting': null,
//             });

//             // UPDATE USER BUSY STATUS
//             await updateDoc(doc(firebase.db, `users/${meeting.data.advisor_id}`), {
//                 'status.busy': false,
//                 'status.meeting': null,
//             });
//         } catch (e) {
//             throw e.message;
//         }
//     }

//     const rejectForFirestore = async (message) => {
//         try {
//             // UPDATE MEETING REQUEST STATUS
//             await updateDoc(doc(firebase.db, `meetings/${meeting.id}`), {
//                 'status': 'rejected',
//                 'reject_message': message,
//             });

//             // UPDATE USER BUSY STATUS
//             await updateDoc(doc(firebase.db, `users/${meeting.data.customer_id}`), {
//                 'status.busy': false,
//                 'status.meeting': null,
//             });

//             // UPDATE USER BUSY STATUS
//             await updateDoc(doc(firebase.db, `users/${meeting.data.advisor_id}`), {
//                 'status.busy': false,
//                 'status.meeting': null,
//             });
//         } catch (e) {
//             throw e.message;
//         }
//     }

//     const acceptForFirestore = async () => {
//         var customerContacts = [];
//         var advisorContacts = [];

//         const customerData = (await getDoc(doc(firebase.db, `users/${meeting.data.customer_id}`))).data();
//         const balance = customerData.balance;
//         const price = meeting.data.price;
//         const will_terminate = new Date(new Date().getTime() + 60000 * ((balance / price).toFixed(0)));
//         const customerId = meeting.data.customer_id;
//         const advisorId = meeting.data.advisor_id;
//         const meetingId = meeting.id;
//         try {
//             (await getDocs(collection(firebase.db, `users/${customerId}/chat`))).docs.forEach(doc => customerContacts = [...customerContacts, doc.id]);
//             (await getDocs(collection(firebase.db, `users/${advisorId}/chat`))).docs.forEach(doc => advisorContacts = [...advisorContacts, doc.id]);
//             if (customerContacts.includes(advisorId)) {
//                 await updateDoc(doc(firebase.db, `users/${customerId}/chat/${advisorId}`), {
//                     'meetings': arrayUnion(meetingId),
//                 })
//             } else {
//                 setDoc(doc(firebase.db, `users/${customerId}/chat/${advisorId}`), {
//                     'user_id': user.uid,
//                     'user_name': user.profile.name,
//                     'user_image': user.profile.pic_url ? user.profile.pic_url : null,
//                     'last_message': null,
//                     'last_message_date': null,
//                     'meetings': [meetingId],
//                 });
//             }
//             if (advisorContacts.includes(customerId)) {
//                 await updateDoc(doc(firebase.db, `users/${advisorId}/chat/${customerId}`), {
//                     'meetings': arrayUnion(meetingId),
//                 })
//             } else {
//                 setDoc(doc(firebase.db, `users/${advisorId}/chat/${customerId}`), {
//                     'user_id': customerId,
//                     'user_name': customerData.profile.name,
//                     'user_image': customerData.profile.pic_url ? customerData.profile.pic_url : null,
//                     'last_message': null,
//                     'last_message_date': null,
//                     'meetings': [meetingId],
//                 });
//             }

//             await fetchOffers(() => {

//             }).then(async (offers) => {
//                 await updateDoc(doc(firebase.db, `meetings/${meetingId}`), {
//                     'status': 'accepted',
//                     'started_at': serverTimestamp(),
//                     'offers': offers,
//                     'will_terminate': will_terminate,
//                 });
//             });

//             await updateDoc(firebase.db, `users/${customerId}`, {
//                 'notify': arrayUnion(advisorId)
//             });
            
//         } catch (e) {
//             throw e.message;
//         }
//     }

//     async function fetchOffers() {
//         let offers = {};
//         try {
//             const offersSnapshot = (await getDocs(collection(firebase.db, `offers`))).docs;
//             offersSnapshot.forEach(offerSnapshot => {
//                 const id = offerSnapshot.id;
//                 offers[id] = {
//                     is_used: false,
//                     purchased: false,
//                     remaining_time: null,
//                     data: offerSnapshot.data()
//                 }
//             });
//         } catch (error) {
//             console.log(error);
//             throw error;
//         }
//         return offers;
//     }

//     const startCall = async () => {
//         setLoading(true);
//         acceptForFirestore().then(function () {
//             setLoading(false);
//         }).catch(e => {
//             alert(e);
//             setLoading(false);
//         });
//     }

//     const rejectCall = async (message) => {
//         setLoading(true);
//         rejectForFirestore(message).then(function () {
//             setLoading(false);
//             setShowRejectModal(false);
//         }).catch(e => {
//             alert(e);
//             setLoading(false);
//         });
//     }

//     const cancelCall = async () => {
//         setLoading(true);
//         cancelForFirestore().then(function () {
//             setLoading(false);
//         }).catch(e => {
//             alert(e);
//         });
//     }

//     function RejectModalAdvisor() {
//         const [message, setMessage] = useState('');
//         const handleInput = e => setMessage(e.target.value);

//         return (
//             <div className="reject-modal-advisor modal modal-anim" style={{ display: showRejectModal ? "flex" : "none" }}>
//                 <div className='loading' style={{ display: loading ? "flex" : "none" }}>
//                     <PulseLoader color="var(--theme-color)" size={20} margin={5} />
//                 </div>

//                 <h2>{translation.reject_meeting}</h2>
//                 <textarea name="reject-message" id="reject-message" cols="30" rows="10" onChange={handleInput} placeholder="Your excuse message must be more then 20 characters"></textarea>
//                 <div className="bottom">
//                     <div className="apply" onClick={() => rejectCall(message)} style={{ pointerEvents: message.length > 20 ? "all" : "none", opacity: message.length > 20 ? "1" : ".5" }}>{translation.reject}</div>
//                     <div className="close" onClick={closeRejectModal}>Close</div>
//                 </div>
//             </div>
//         )
//     }


//     return (
//         <>
//             {
//                 user && user.user_type === "customer" && rejectMessage && (
//                     <div className="modal-container" onClick={() => dispatch(setRejectMessage(''))}>
//                         <div className="modal-anim modal reject-modal-customer" onClick={(e) => e.stopPropagation()}>
//                             <h2>{translation.advisor_busy}</h2>
//                             <h4>{translation.message_from_advisor}</h4>
//                             <textarea cols="30" rows="10" readOnly="true" spellCheck="false" value={rejectMessage} />
//                             <div className="close" onClick={() => dispatch(setRejectMessage(''))}>{translation.close}</div>
//                         </div>
//                     </div>
//                 )
//             }
//             {
//                 (user && meeting && meeting.data.status === "pending") && (
//                     <div className="modal-container noselect">
//                         {
//                             user.user_type === "advisor" ? (
//                                 showRejectModal ? (
//                                     <RejectModalAdvisor />
//                                 ) : (
//                                     <div className="advisor-section row">
//                                         <div className="start-button" onClick={startCall}>{translation.join_meeting}</div>
//                                         <div className="call" style={{ background: `var(--${meeting.data.meeting_type}-active-color)` }}>
//                                             <div className="circle-anim1" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .5)` }}></div>
//                                             <div className="circle-anim2" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .3)` }}></div>
//                                             {
//                                                 meeting.data.meeting_type === "chat" ?
//                                                     (
//                                                         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <path d="M1.21875 8.9553C1.21875 4.68064 4.68407 1.21533 8.95875 1.21533C13.2334 1.21533 16.6987 4.68064 16.6987 8.9553C16.6987 13.23 13.2334 16.6953 8.95875 16.6953H1.21875V8.9553Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                         </svg>
//                                                     ) :
//                                                     meeting.data.meeting_type === "voice" ?
//                                                         (
//                                                             <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                 <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3984 16.318C13.3175 16.318 13.2367 16.3146 13.1522 16.3112C12.1569 16.2465 11.1615 15.9691 9.91999 15.4108C7.72623 14.3947 5.81455 12.9768 4.23167 11.193C2.9668 9.75985 2.0415 8.29605 1.48273 6.84075C1.20425 6.11396 0.931151 5.24419 1.0156 4.2825C1.04434 3.67656 1.31744 3.11827 1.80434 2.68764L3.03867 1.51659C3.76273 0.830648 4.75451 0.827244 5.50553 1.50979C5.7373 1.71233 5.96368 1.93361 6.18287 2.14637C6.29247 2.2519 6.40207 2.35913 6.51526 2.46636L7.49266 3.3923C8.24188 4.13272 8.24188 5.04334 7.49985 5.77694C7.28424 5.98119 7.07404 6.17353 6.86562 6.36587C6.68775 6.52927 6.50987 6.69267 6.3338 6.85948C6.54581 7.31223 6.85664 7.76499 7.32917 8.31137C8.31734 9.45007 9.27318 10.2722 10.3368 10.8969L10.3602 10.9105C10.4931 10.999 10.6566 11.0756 10.8291 11.1573C10.8758 11.1794 10.9243 11.2015 10.9711 11.2254L12.1281 10.1292C12.4857 9.79049 12.9133 9.61177 13.366 9.61177C13.8188 9.61177 14.2464 9.79049 14.604 10.1292L16.6073 12.027C17.3547 12.7351 17.3547 13.6866 16.6073 14.3947C16.4761 14.5189 16.3432 14.6415 16.2138 14.7623C15.9533 15.004 15.7071 15.2321 15.47 15.4721C14.9543 16.0304 14.2554 16.318 13.3984 16.318Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                             </svg>

//                                                         ) :
//                                                         meeting.data.meeting_type === "video" &&
//                                                         (
//                                                             <svg className="call-img" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                                 <path d="M12.9 1H2.7C1.76112 1 1 1.72754 1 2.625V12.375C1 13.2725 1.76112 14 2.7 14H12.9C13.8389 14 14.6 13.2725 14.6 12.375V2.625C14.6 1.72754 13.8389 1 12.9 1Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                                 <path d="M14.6 5.0625L17.5055 2.97955C17.7092 2.83346 18 2.97244 18 3.21591V11.7841C18 12.0276 17.7092 12.1665 17.5055 12.0205L14.6 9.9375V5.0625Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                             </svg>
//                                                         )

//                                             }
//                                         </div>
//                                         <div className="reject-button" onClick={openRejectModal}>{translation.not_available}</div>
//                                     </div>
//                                 )
//                             ) : (
//                                 <div className="customer-section row">
//                                     <div className="start-button">{translation.waiting}</div>
//                                     <div className="call" style={{ background: `var(--${meeting.data.meeting_type}-active-color)` }}>
//                                         <div className="circle-anim1" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .5)` }}></div>
//                                         <div className="circle-anim2" style={{ background: `rgba(var(--${meeting.data.meeting_type}-active-color-rgb), .3)` }}></div>
//                                         {
//                                             meeting.data.meeting_type === "chat" ?
//                                                 (
//                                                     <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                         <path d="M1.21875 8.9553C1.21875 4.68064 4.68407 1.21533 8.95875 1.21533C13.2334 1.21533 16.6987 4.68064 16.6987 8.9553C16.6987 13.23 13.2334 16.6953 8.95875 16.6953H1.21875V8.9553Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                     </svg>
//                                                 ) :
//                                                 meeting.data.meeting_type === "voice" ?
//                                                     (
//                                                         <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3984 16.318C13.3175 16.318 13.2367 16.3146 13.1522 16.3112C12.1569 16.2465 11.1615 15.9691 9.91999 15.4108C7.72623 14.3947 5.81455 12.9768 4.23167 11.193C2.9668 9.75985 2.0415 8.29605 1.48273 6.84075C1.20425 6.11396 0.931151 5.24419 1.0156 4.2825C1.04434 3.67656 1.31744 3.11827 1.80434 2.68764L3.03867 1.51659C3.76273 0.830648 4.75451 0.827244 5.50553 1.50979C5.7373 1.71233 5.96368 1.93361 6.18287 2.14637C6.29247 2.2519 6.40207 2.35913 6.51526 2.46636L7.49266 3.3923C8.24188 4.13272 8.24188 5.04334 7.49985 5.77694C7.28424 5.98119 7.07404 6.17353 6.86562 6.36587C6.68775 6.52927 6.50987 6.69267 6.3338 6.85948C6.54581 7.31223 6.85664 7.76499 7.32917 8.31137C8.31734 9.45007 9.27318 10.2722 10.3368 10.8969L10.3602 10.9105C10.4931 10.999 10.6566 11.0756 10.8291 11.1573C10.8758 11.1794 10.9243 11.2015 10.9711 11.2254L12.1281 10.1292C12.4857 9.79049 12.9133 9.61177 13.366 9.61177C13.8188 9.61177 14.2464 9.79049 14.604 10.1292L16.6073 12.027C17.3547 12.7351 17.3547 13.6866 16.6073 14.3947C16.4761 14.5189 16.3432 14.6415 16.2138 14.7623C15.9533 15.004 15.7071 15.2321 15.47 15.4721C14.9543 16.0304 14.2554 16.318 13.3984 16.318Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                         </svg>

//                                                     ) :
//                                                     meeting.data.meeting_type === "video" &&
//                                                     (
//                                                         <svg className="call-img" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                                             <path d="M12.9 1H2.7C1.76112 1 1 1.72754 1 2.625V12.375C1 13.2725 1.76112 14 2.7 14H12.9C13.8389 14 14.6 13.2725 14.6 12.375V2.625C14.6 1.72754 13.8389 1 12.9 1Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                             <path d="M14.6 5.0625L17.5055 2.97955C17.7092 2.83346 18 2.97244 18 3.21591V11.7841C18 12.0276 17.7092 12.1665 17.5055 12.0205L14.6 9.9375V5.0625Z" stroke="rgb(240,240,240)" stroke-width="1.5" />
//                                                         </svg>
//                                                     )

//                                         }
//                                     </div>
//                                     <div className="reject-button" onClick={cancelCall}>{translation.cancel_call}</div>
//                                 </div>
//                             )
//                         }
//                     </div>
//                 )
//             }
//         </>
//     )
// }

// export default MeetingSection