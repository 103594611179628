import { getDownloadURL, ref as storageRef, uploadBytesResumable} from 'firebase/storage';
import firebase from '../config/firebase';
const storage = firebase.storage;

export default class Storage {
    static uploadImage = async (path, file) => {
      if(!file) return null;
      let downloadUrl = '';
      const imageRef = storageRef(storage, `${path}/${file.name}`);
      await uploadBytesResumable(imageRef, file).then(async task => {
        await getDownloadURL(task.ref).then(url => {
          downloadUrl = url;
        })
      });
      // alert(downloadUrl)
      return downloadUrl;
    }
  }