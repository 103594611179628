import Readings from '../../components/readingscreen/readingscreen';
import palmImg from '../../assets/ic_palm-readings.webp'
import useTranslation from '../../localization/translations';

const Palm = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"palm"} img={palmImg} header={"Palm readings"} text={translation.palm_description} skillFilter={"Palm"}/>
  )
}

export default Palm