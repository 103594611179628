import React, { useEffect } from 'react';
import ElementControl from '../../functions/elementcontrol';
import './index.scss';

const PrivacyPolicy = () => {

  useEffect(() => {

    ElementControl.removeFixedElements();

    return () => ElementControl.addFixedElements();

  }, []);


  return (
    <div className="static-page">
      <h1>Privacy Policy</h1>
      <h3>Last updated: January 3, 2023</h3>
      <span>Zodiac Advisor website and mobile application (the “Service”). We respect our users’ privacy. Please take a moment to familiarize yourself with our privacy practices and let us know if you have any questions.</span>
      <span>This Privacy Policy (“Policy”) explains our policies regarding the collection, storage, use, transfer and disclosure of Personal Information when you use our Service. The Policy does not address our collection of Personal Information from other sources. The Policy applies to you if you are a user, Advisor or other visitor to our website and/or Service. We will not use or share your information with anyone except as described in this Privacy Policy.</span>
      <span>The Personal Information you provide us will be used to provide and improve the Service. By using the Service, you agree to our collection and use of information in accordance with this Privacy Policy. Unless otherwise defined in this Policy, the terms used here have the same meanings as in our Term of Use.</span>
      <span>This privacy policy has been updated to comply with the EU’s General Data Protection Regulation (GDPR).</span>
      <span>It is your responsibility to read this Policy. If you do not accept this Policy, you must not use the Service.</span>
      <h2>Information Collection And Use</h2>
      <span>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to purchase the Service and to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, or credit card or other financial information (“Personal Information”). If you are using the Service as an Advisor, you may be required to provide additional information, including, but not limited to, photo identification or other identity-verifying information and tax related documents, such as a Form W-9 or Form W-8BEN, that will be maintained in our databases. Even though you may provide us with credit card or other financial information, we do not handle, process, or store that information. Personal Information shared with third party payment services will be used only in accordance with the terms of the third party services. We retain sole discretion to choose another third party payment service.</span>
      <span>You understand that you are providing us with this information voluntarily and it will be shared with third parties in connection with processing payment and the performance of the Service. Your Personal Information may also be used to send you updates regarding our company, to respond to your requests for information or customer service, or to inform you of other services or benefits that you may be interested in.</span>
      <span>The following is a list of personal information collected by Zodiac Advisor:</span>
      <ul>
        <li>Email address</li>
        <li>Login name</li>
        <li>Full name</li>
        <li>IP address</li>
        <li>Geographic location</li>
        <li>Profile photo</li>
        <li>Live chat transcripts</li>
        <li>Video recordings</li>
        <li>Voice recordings</li>
        <li>In-app messages</li>
        <li>Favorite advisors list</li>
        <li>Reviews</li>
        <li>Information in your customer service inquiries</li>
      </ul>
      <span>This information will be stored on third party partner servers (e.g. Google Firebase) for as long as your account is active. User data is hosted on servers belonging to third parties with whom we have signed Data Processing Agreements. All third party partners are GDPR-compliant. All communications on Zodiac Advisor are stored, and are used for analytics purposes, for quality assurance, and to maintain long- term relationships between clients and advisors.</span>
      <h2>Rights Under GDPR</h2>
      <span>Under certain circumstances, users based in the EU have rights under data protection laws in relation to your personal data, namely:</span>
      <ul>
        <li>Access, correction, update, or erasure of your data: You may have the right to request access, correction, update, or erasure in regards to any personal data we hold about you. We will consider your request in accordance with applicable laws.</li>
        <li>Restriction of processing your personal data. You may have the right to prevent or restrict processing of your personal data.</li>
        <li>Portability of your personal data. You may have the right to request transfer of your personal data to a third party, when technically feasible.</li>
      </ul>
      <span>You can exercise any of these rights by contacting us.</span>
      <h2>Additional Information for California Residents</h2>
      <span>This section contains information about policies that apply solely to California residents who use the Service. If you are a California resident, as of January 2020 you have certain rights regarding your personal information under the California Consumer Privacy Act of 2018 (“CCPA”):</span>
      <h4>(1) Right to Know About Personal Information Collected, Disclosed, or Sold</h4>
      <span>You may request that we provide you with the following information:</span>
      <ul>
        <li>The categories or specific pieces of personal information that we have collected about you.</li>
        <li>The categories of sources from which the personal information was collected.</li>
        <li>The purposes for collecting, using, or selling that personal information. Please note that we do not sell personal information (as we understand the term “sell” to be defined in the CCPA).</li>
        <li>The categories of third parties with whom we shared that personal information.</li>
        <li>The categories of personal information we have disclosed or sold for a business purpose.</li>
        <li>Details about the personal information we collect can be found in the “Information Collection and Use” section of this Privacy Policy. The supplemental policy for the CCPA regarding the personal information we have collected over the last 12 months, including the categories of personal information, business purposes, and whether we collect or sell your personal information are provided below:</li>
      </ul>
      <table>
        <tr>
          <th>Categories of personal information</th>
          <th>Business purpose</th>
          <th>Collected?</th>
          <th>Sold?</th>
        </tr>
        <tr>
          <td>
            <li><b>Identifiers</b>, such as full name, login name/nickname, street address, unique personal identifier, online identifier, date of birth, device type, unique device ID, browser type, Internet Protocol (IP) address, email address, account ID number, social security number, driver's license number, passport number, signature, or other similar identifiers.</li>
          </td>
          <td>
            <ul>
              <li>To provide our products and services to you.</li>
              <li>To personalize, develop, and improve our products and services.</li>
              <li>To create, customize, maintain, and secure your account with us.</li>
              <li>To process your purchases, transactions, and payments.</li>
              <li>To prevent fraudulent transactions and fraudulent usage of our products and services.</li>
              <li>To provide you with customer support.</li>
            </ul>
          </td>
          <td>Yes</td>
          <td>No</td>
        </tr>
      </table>
    </div>
  )
}

export default PrivacyPolicy