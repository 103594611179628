import firebase from "../config/firebase"
import { httpsCallable } from "firebase/functions"

const functions = firebase.fbFunctions

export default class Agora {

    static generateAgoraRtcToken = httpsCallable(functions, 'generateAgoraRtcToken')
    static generateAgoraRtmToken = httpsCallable(functions, 'generateAgoraRtmToken')

    static generateRtcToken = async (channel, uid) => {
        return this.generateAgoraRtcToken({
            channel: channel,
            uid: uid
        })
    }

    static generateRtmToken = async (account) => {
        return this.generateAgoraRtmToken({
            account: account
        })
    }
}