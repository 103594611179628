import Cookie from 'js-cookie';

class CookieCRUD {

    static setCookie (cookieName, usrin) {
        Cookie.set(cookieName, usrin, {
            expires: 1,
            secure: true,
            sameSite: 'strict',
            path: '/'
        }) 
    }

    static removeCookie (cookieName) {
        Cookie.remove(cookieName)
    }

    static getCookie (cookieName) {
        const value = Cookie.get(cookieName);
        return value ?? null;
    }
}

export default CookieCRUD;