export default class ElementControl {
    static removeFixedElements() {
        const bar = document.getElementById('navbar');
        const endSection = document.getElementById('end-section');

        if(bar && endSection) {
            bar.style.display = "none";
            endSection.style.display = "none";
        }
      }
    
    static addFixedElements() {
        const bar = document.getElementById('navbar');
        const endSection = document.getElementById('end-section');

        if(bar && endSection) {
            bar.style.display = "flex";
            endSection.style.display = "flex";
        }
    }
}