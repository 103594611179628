import React from 'react';
import { useSelector } from 'react-redux';
import callMP3 from '../assets/audio/au_call.mp3';

const CallAudio = () => {

    const {meeting} = useSelector(state => state.site);

    return (
        meeting && meeting.data.status === "pending" &&
        (
            <audio autoPlay={true} src={callMP3} loop={true}></audio>
        )
    )
}

export default CallAudio