import { getString } from 'firebase/remote-config';
import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import ElementControl from '../../functions/elementcontrol';
import './index.scss';

const TermOfUse = () => {

  useEffect(() => {

    ElementControl.removeFixedElements();

    return () => ElementControl.addFixedElements();

  }, []);

  return (
    <div className="static-page">
      <h1>TERMS OF USE FOR THE ZODIAC ADVISOR SERVICE</h1>
      <h3>Last updated January 3, 2023</h3>
      <span>These Terms create a binding agreement (“<b>Agreement</b>”) between you (“<b>End User</b>” or “<b>You</b>”) and Zodiac Advisor (“<b>Zodiac Advisor</b>,” “<b>Us</b>,” “<b>We</b>,” or “<b>Our</b>”). This Agreement governs your use of the Zodiac Advisor Mobile Application, (including all related documentation, the “<b>Application</b>”) and your use of the Zodiac Advisor web site (“<b>Website</b>”) (collectively, the Application and the Website are referred to as the “<b>Service</b>”). The Application is licensed, not sold, to You, on the terms below.</span>
      <span><b>THIS SERVICE IS FOR ENTERTAINMENT PURPOSES ONLY BY ACCESSING, DOWNLOADING OR USING THE SERVICE, YOU ACKNOWLEDGE AND REPRESENT THAT:</b></span>
      <ul>
        <li><b>YOU ARE EIGHTEEN (18) YEARS OF AGE OR OLDER AND ARE OTHERWISE CAPABLE OF FORMING LEGALLY BINDING CONTRACTS UNDER APPLICABLE LAW, AND</b></li>
        <li><b>THAT YOU HAVE READ, UNDERSTAND AND ACCEPT THESE TERMS AND CONDITIONS AND AGREE TO BE LEGALLY BOUND BY THEM.</b></li>
        <span><b>IF YOU DO NOT AGREE TO THESE TERMS, DO NOT DOWNLOAD, INSTALL OR USE THE APPLICATION AND DELETE IT FROM YOUR MOBILE DEVICE.</b></span>
        <span><b>The terms and conditions below limit Our liability and obligations to You and allow Us to change, suspend or terminate your access to and use of the Service at any time in Our sole discretion. It is your responsibility to read the following terms and conditions, along with any other policies on Our website including, but not limited to Our Privacy Policy and Our Advisor Term and Conditions BY ACCESSING OR USING THE SERVICE YOU AGREE TO BE BOUND BY THESE TERMS AS WELL AS ANY ADDITIONAL TERMS AND CONDITIONS CONTAINED IN OUR OTHER POLICIES AND AGREEMENTS THAT MAY APPLY TO YOU. If any of the terms of Our other policies conflict with the Terms here, the Terms provided here will control.</b></span>
        <span><b>THESE TERMS APPLY TO ALL VISITORS, CLIENTS, ADVISORS AND OTHERS WHO ACCESS OR USE THE SERVICE. You understand and agree that your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. Additional terms and conditions apply to Advisors and their use of the Service to which they must read and agree to prior to using the Service.</b></span> 
      </ul>
      <h2>THE ZODIAC ADVISOR MOBILE APPLICATION</h2>
      <span>The Service is a marketplace that brings advice-seekers and independent, professional psychic advisors (“<b>Advisor(s)</b>”) together through text or video. It allows You to search for, engage and submit questions to Advisors who provide personalized psychic advice.</span>
      <span><b>ZODIAC ADVISOR IS A SOFTWARE COMPANY THAT BUILT THE ZODIAC ADVISOR PLATFORM TO CONNECT ADVISORS AND ADVICE-SEEKERS - IT DOES NOT DIRECTLY OFFER OR SELL ANY PSYCHIC ADVICE OR SERVICE.</b></span>
      <span>You understand that any information provided to You by any Advisor is strictly for general entertainment purposes and that any consultation with any Advisor or any information provided by any Advisor is not a substitute for the advice and counsel of a qualified professional. We do not provide the Advisors with your personal information, such as your real name or email address, but may forward your nickname, date of birth and/or gender in connection with facilitating the Service. We do not guarantee the accuracy of a reading or any other advice exchanged.</span>
      <span><b>WE PROHIBIT DISCLOSURE OF PERSONAL CONTACT INFORMATION TO OTHER USERS, INCLUDING CLIENTS AND ADVISORS. THIS INCLUDES, BUT IS NOT LIMITED TO, PHONE NUMBERS, ADDRESSES, AND E-MAIL ADDRESSES. IF YOU PROVIDE CONTACT INFORMATION TO ANOTHER USER, CLIENT OR ADVISOR, YOU DO SO AT YOUR OWN RISK AND YOUR ACCOUNT MAY BE SUBJECT TO TERMINATION.</b></span>
      <h2>LICENSE</h2>
      <span>Our Service allows You to post, link, store, share and otherwise make available certain information, messages, communications, text, graphics, images, audio, video, or other material (“Content”). You are responsible for the Content that You post to the Service, including its legality, reliability, and appropriateness.</span>
      <span>With this Agreement, we grant You a limited, revocable, non-exclusive, non-transferable, non-sublicensable right to use the Service only for its intended use – to submit questions to and receive psychic advice and feedback from Advisors for your personal entertainment only. We may terminate this license at any time for any reason whatsoever. You understand and agree that You are not permitted to use the Zodiac Advisor logo or any of Zodiac Advisor’ patents, copyrights, trade secrets, trade names, trademarks (whether registered or unregistered), domain names or any other rights, functions or licenses as it is with respect to the Service for any purpose whatsoever, including, but not limited to, the advertising and marketing of your services.</span>
      <h2>COLLECTION AND USE OF YOUR INFORMATION</h2>
      <span>You acknowledge that when You download, install or use the Application, Zodiac Advisor may use automatic means (including, for example, cookies, Apple’s Advertising Identifier (“IDFA”), and Google’s Advertising ID) to collect information about your mobile device and about your use of the Application. You also may be required to provide certain information about yourself as a condition to downloading, installing or using the Application or certain of its features or functionality, and the Application may provide You with opportunities to share information about yourself with others. All information We collect through or in connection with this Application is subject to Our Privacy Policy.</span>
      <span><b>BY DOWNLOADING, INSTALLING, USING AND PROVIDING INFORMATION TO OR THROUGH THIS APPLICATION, YOU CONSENT TO ALL ACTIONS TAKEN BY US WITH RESPECT TO YOUR INFORMATION IN COMPLIANCE WITH THE PRIVACY POLICY.</b></span>
      <h2>CONTENT AND SERVICES</h2>
      <span>The Application may provide You with access to Company’s website located at www.zodiacadvisor.com (the “<b>Website</b>”) and products and services accessible thereon, and certain features, functionality and content accessible on or through the Application may be hosted on the Website (collectively, “<b>Content and Services</b>”). Your access to and use of such Content and Services are governed by Website’s Terms of Use and Privacy Policy located at<a>http://www.zodiacadvisor.com/terms-of-use</a> and <a>http://www.zodiacadvisor.com/privacy</a>, which are incorporated herein by this reference. your access to and use of such Content and Services may require You to acknowledge your acceptance of such Terms of Use and Privacy Policy and/or to register with the Website and your failure to do so may restrict You from accessing or using certain of the Application's features and functionality. Any violation of such Terms of Use will also be deemed a violation of this Agreement.</span>
      <h2>ADVISORS</h2>
      <span>Advisors are not Our employees, consultants, agents or representatives. Advisors use the Service to provide clients with personalized readings and psychic advice for entertainment purposes. We do not guarantee the verification of qualifications, certifications, credentials, competence or background of any Advisor. Your relationship relating to the Service is strictly and solely with the Advisor. We do not endorse, recommend, evaluate, warrant or guarantee any opinion, response, advice, prediction, recommendation, information or other service provided by any Advisor. Advisors are not permitted to request your personal contact information, address, credit card, payment information, or other sensitive information of a similar nature. You must not provide any such information <b>even if an Advisor violates these Terms and asks You for </b>it. Advisors must also read and agree to the Advisor Term and Conditions.</span>
      <h2>ORDERS, PAYMENT AND FEES</h2>
      <span>If You wish to place an order for a reading through the Service (“Order”), You may be asked to supply certain information relevant to your Order including, without limitation, your credit card number, the expiration date of your credit card, and your billing address. By submitting such information, You grant Us the right to provide the information to third parties for purposes of facilitating the completion of your Orders.</span>
      <span>You understand that Zodiac Advisor does not store your payment and/or credit card information and that all payments are processed separately either through in-app purchases, or through PayPal, Stripe, or such other third party payment or service facility We choose. In addition to these Terms, You will be subject to PayPal’s, Stripe’s, or such other third party’s terms and conditions, privacy policies and other applicable agreements.</span>
      <span>At all times, You agree that You will provide true, complete and correct credit card and payment information and that You are authorized to do so. Once a payment is made for the Service, You agree that You will not seek to cancel, charge back, or otherwise seek a refund on any basis that is fraudulent, misleading, or untrue. You agree to pay all fees and charges incurred in connection with your Orders at the rates in effect when the charges were incurred. In the event that your order is cancelled or unfulfilled for any reason, your account will be credited accordingly.</span>
      <span>It is possible to purchase credits or tokens (collectively, “<b>Credit</b>”), both via the Application and the Website, which may be used to pay for future readings or Service. <b>IF YOU DO NOT USE YOUR CREDIT FOR A PERIOD OF FIVE (5) YEARS AFTER IT IS PURCHASED, THE CREDIT MAY BECOME INACTIVE OR DORMANT.</b></span>
      <span>You are solely responsible for any fees or other charges from your internet service provider and wireless communications provider for any data, video, audio or message services.</span>
      <h2>REFUND POLICY</h2>
      <span>Refund requests must be made by email transmission to info@zodiacadvisor.com, within 3 days of the completion of the Order. Refund requests must state the Order date & time and the Advisor name. Refunds cannot exceed the equivalent value of the Order for which the refund was requested. Refunds may be issued in the form of Credit or real world money, in Our sole discretion. We reserve the right to refund You partially or in full and also to refuse a refund request.</span>
      <h2>ACCOUNTS</h2>
      <span>When You create an account with Us, You must provide us information that is accurate, complete, and current at all times. Your failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account.</span>
      <span>You understand that You are entirely responsible for safeguarding the password that You use to access the Service and for any activities or actions under your password, whether your password is with Our Service or a third-party service. You agree not to disclose your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of your account. Zodiac Advisor will not be liable for any loss that You incur as a result of someone else using your account or your password.</span>
      <span>You may not use as a username or nickname the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, a name that is otherwise offensive, vulgar or obscene, or a name that deceives or otherwise misleads others to believe You are someone that You are not.</span>
      <span>You may not create multiple accounts, and shall at all times have at most one active account. You may not allow anyone else to use your account, username or password.</span>
      <h2>PROHIBITED CONTENT</h2>
      <span>We reserve the right to monitor any and all communication through the Service. By using the Service, You understand and agree that the following behavior is strictly prohibited and/or illegal and is not permitted at any time while using the Service:</span>
      <ul>
        <li>Using vulgar, offensive or obscene language.</li>
        <li>Sending or requesting sexually explicit images or other offensive content.</li>
        <li>Discussing or organizing illegal activity, such as how to obtain drugs or construct bombs.</li>
        <li>Harassing, threatening, or embarrassing another user.</li>
        <li>Infringing on another’s intellectual property, including, but not limited to, any copyright, trademark, rights of publicity, or other proprietary rights.</li>
        <li>Impersonating or attempting to represent any employee of Zodiac Advisor or Zodiac Advisor itself.</li>
        <li>Gambling activities, or the payment or acceptance of payments relating to gambling activities.</li>
        <li>Solicitation or provision of advice as to taxes, the value of securities, or the advisability of investing in, purchasing, or selling securities or any other negotiable instruments.</li>
        <li>Exploitation of, or the posting or requesting of any information from, a minor (anyone under the age of 18).</li>
        <li>Using racially, religious or sexually offensive language.</li>
        <li>Diagnosis of illnesses, provision of treatments, or prescription of medications.</li>
        <li>Posting, storing, or transmitting advertising, promotional material, or solicitations of goods or services, except where expressly permitted by Zodiac Advisor.</li>
        <li>Transmitting any virus, worm, Trojan Horse, or other harmful or disruptive component.</li>
        <li>Engaging in any other conduct that would be considered a criminal offense, give rise to civil liability, or violate any law or regulation.</li>
        <li>Invading the privacy of any other person.</li>
      </ul>
      <span>You understand that if You cause or participate in any of the above-mentioned activities while using the Service, your account will be terminated and your actions may be reported to the proper authorities.</span>
      <h2>AVAILABILITY, ERRORS AND INACCURACIES</h2>
      <span>We are constantly updating Our offerings of products and services on the Service. The products or services available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information on the Service and in Our advertising on other web sites.</span>
      <span>We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</span>
      <span>You understand that it is your responsibility to update the Application as new versions are released and that your failure to download a new version of the Application may prevent You from enjoying the most recent content and features. Zodiac Advisor shall not bear any responsibility or liability for your failure to update the Application.</span>
      <span>Our Service depends on various factors such as software, hardware and communications networks, and other factors outside of its control. You understand that We do not guarantee that the Service will be uninterrupted or that it will be timely, secure and error-free.</span>
      <h2>INTELLECTUAL PROPERTY</h2>
      <span>You acknowledge that the Service and its original content (excluding Content provided by users), graphics, scripts, service names, features and functionality are and will remain the exclusive property of Zodiac Advisor and its licensors. The Service and its components are protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without Zodiac Advisor’ prior express written consent.</span>
      <span>You agree that You will not change, sell, translate, reverse engineer, repurpose, or distribute the Service except as expressly authorized by Zodiac Advisor. </span>
      <span>You also agree that You will not use the Service in any way to infringe on Zodiac Advisor’ or any third party’s intellectual property or other rights.</span>
      <h2>LINKS TO OTHER WEB SITES</h2>
      <span>Some of the Advisors who are accessible through our Service may also be accessible through other websites and services, including, without limitation, and the Zodiac Advisor mobile application.</span>
      <span><b>Any interactions, content or services accessed through zodiacadvisor.com are subject to Zodiac Advisor's Terms of Service and Privacy Policy both of which are incorporated here by reference. BY ACCESSING THE www.zodiacadvisor.com WEBSITE, YOU AGREE TO THE Terms of Service and the Privacy Policy, AS MAY BE UPDATED FROM TIME TO TIME.</b></span>
      <span><b>Any interactions, content or services accessed through the Zodiac Advisor mobile application are subject to Zodiac Advisor's Terms of Use and Privacy Policy, both of which are incorporated here by reference. BY ACCESSING THE ZODIAC ADVISOR MOBILE APPLICATION, YOU AGREE TO ZODIAC ADVISOR'S Terms of Use and Privacy Policy, AS MAY BE UPDATED FROM TIME TO TIME.</b></span>
      <span>Our Service may contain links to or other content related to third-party web sites or services that are not owned or controlled by Zodiac Advisor.</span>
      <span>Zodiac Advisor has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. We are not responsible for the availability or content of such third party links, web sites, products or services. You further acknowledge and agree that Zodiac Advisor shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services that You visit.</span>
      <h2>COPYRIGHT COMPLAINTS</h2>
      <span>Zodiac Advisor respects the intellectual property of others. If You are a trademark or copyright owner and You believe that your rights have been violated in any way, please notify Us of your claim of infringement by sending the following written information to our designated copyright agent:</span>
      <ul>
        <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
        <li>Identification of the copyrighted work claimed to have been infringed or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
        <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</li>
        <li>Information reasonably sufficient to permit Us to contact You such as an address, telephone number, and, if available, an electronic mail address at which You may be contacted;</li>
        <li>A statement that You have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
        <li>A statement that the information in the notification is accurate and, under penalty of perjury, that You are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
      </ul>
      <span>Our copyright agent for notice of claims of copyright infringement can be reached at:</span>
      <span>info@zodiacadvisor.com</span>
      <h2>TERMINATION</h2>
      <span>We may terminate or suspend your access to Our Service immediately, without prior notice or liability, for any reason whatsoever or for no reason at all, including without limitation if You breach the Terms.</span>
      <span>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</span>
      <span>Upon termination, your right to use the Service will immediately cease. If You wish to terminate your account, You may simply discontinue using the Service.</span>
      <h2>COMPLIANCE WITH LAWS</h2>
      <span>We make no claim or representation that the Service is permitted, or lawful for use outside the United States. If You access or use the Service from a location outside the United States, You do so at your own risk and are responsible for compliance with the laws of the jurisdiction where You reside. You represent and warrant that (i) You are not located in a country that is subject to a United States Government embargo, or that has been designated by the United States Government as a “terrorist supporting” country; and (ii) You are not listed on any United States Government list of prohibited or restricted parties.</span>
      <span>You also represent and warrant that You are eighteen (18) years of age or older and that You agree to abide by all of these terms. You agree that You will not use the Service for any prohibited purpose or if use of the Service is prohibited in your area. It is your sole responsibility to ensure that your use of the Service complies with all laws, rules and regulations applicable to You. In addition, You acknowledge that to use the Service, (i) the Content must be yours (You own it) or You have the right to use it and grant Us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity.</span>
      <h2>INDEMNIFICATION</h2>
      <span>You agree to defend, indemnify and hold harmless Zodiac Advisor and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of or in connection with: (i) your use and access of the Service, by You or any person using your account and password; (ii) a violation or breach of these Terms, the Advisor Terms and Conditions, or any other agreement that governs your use of the Service; (iv) a violation of any of your representations or warranties made to Us, (v) a violation of any law or the rights of any third party; or (vi) any user Content, third party content, third party sites and any other content which You posted or uploaded on the Service.</span>
      <h2>LIMITATION OF LIABILITY</h2>
      <span>To the maximum extent permitted by applicable law, in no event shall Zodiac Advisor, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any Content obtained from or provided to the Service; (iv) following or relying upon on an Advisor’s recommendations or advice or the actions You take based on the same; and (v) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not We have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</span>
      <span>To the maximum extent permitted by applicable law, Zodiac Advisor is not responsible for (i) any Content or conduct, whether online or offline, made in connection with the Service, whether caused by visitors, users, Advisors, third parties and others; (ii) any error, omission, interruption, deletion, defect, delay in operation or transmission, communication lines failure, theft or destruction or unauthorized access to, or alteration of Content, user communications or Advisor communications; (iii) any problems or technical malfunction of any telephone network or lines, cellular data provider or network, computer online systems, servers or providers, computer equipment, software, failure of email, traffic congestion or downtime on the Service, including injury or damage to users or to any other person’s computer and/or mobile device; or (iv) any loss or damage, including personal injury or death, resulting from anyone’s use of the Service, any content posted or transmitted to users, or any interactions between users of the Service, whether online or offline.</span>
      <h2>DISCLAIMER – FOR ENTERTAINMENT PURPOSES ONLY; NOT A SUBSTITUTE FOR QUALIFIED PROFESSIONAL ADVICE</h2>
      <span><b>THE INFORMATION PROVIDED ON OUR WEBSITES AND USE OF THE SERVICE IS PROVIDED FOR ENTERTAINMENT PURPOSES ONLY, AND IS IN NO WAY INTENDED TO DIAGNOSE, CURE, OR TREAT ANY MEDICAL CONDITION, DISEASE OR AILMENT, AND IS IN NO WAY INTENDED TO BE A SUBSTITUTE NOR REPLACEMENT FOR PROFESSIONAL MEDICAL ADVICE, PROFESSIONAL FINANCIAL OR INVESTMENT ADVICE OR GUIDANCE, OR PROFESSIONAL LEGAL ADVICE. ZODIAC ADVISOR IS NOT A MEDICAL PRACTICE, IS NOT A FINANCIAL SERVICES FIRM, IS NOR REGISTERED AS A FINANCIAL ADVISOR (NOR ARE ANY OF ITS OFFICERS, PRINCIPALS, OR EMPLOYEES), AND IS NOT ENGAGED IN THE BUSINESS OR PRACTICE OF PROVIDING OR RENDERING ANY OF THE PRECEDING.</b></span>
      <span><b>IF YOU ARE HAVING SUICIDAL THOUGHTS, FEEL YOU MAY BE A DANGER TO YOURSELF OR OTHERS, OR IF YOU HAVE A MEDICAL EMERGENCY, IMMEDIATELY CALL 911 (OR THE RELEVANT EMERGENCY NUMBER FOR YOUR AREA) AND NOTIFY THE POLICE OR EMERGENCY MEDICAL SERVICES FOR HELP.</b></span>
      <span><b>YOU ACKNOWLEDGE, CONFIRM AND AGREE THAT THE SERVICE IS NOT DESIGNED FOR USE IN EMERGENCIES, FOR MEDICAL OR PSYCHOLOGICAL PURPOSES, FOR THERAPY OR FOR ANY PURPOSE OTHER THAN ENTERTAINMENT.</b></span>
      <span><b>WE STRONGLY RECOMMEND THAT YOU ALWAYS SEEK THE ADVICE OF A QUALIFIED AND LICENSED PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER IN CONNECTION WITH ANY AND ALL QUESTIONS YOU MAY HAVE REGARDING A MEDICAL CONDITION. IN ADDITION TO ALL OTHER LIMITATIONS AND DISCLAIMERS HEREIN, ZODIAC ADVISOR AND ITS THIRD PARTY PROVIDERS EXPRESSLY DISCLAIM ANY LIABILITY OR LOSS IN CONNECTION WITH THE CONTENT PROVIDED VIA OUR SERVICE.</b></span>
      <span><b>YOU RELY ON OR ACT UPON THE ADVICE OF ANY ADVISOR OR OTHER USER OF THE SERVICE SOLELY AT YOUR OWN RISK AND BY YOUR OWN FREE AND VOLUNTARY ELECTION AND VOLITION. YOU BEAR SOLE RESPONSIBILITY AND LIABILITY FOR THE CONTENT THAT YOU SUBMIT AND ANY ACTS OR OMISSIONS UNDERTAKEN BY YOU IN CONNECTION WITH ANY OF THE INFORMATION OR ADVICE PROVIDED BY ANY ADVISOR, CLIENT OR USER.</b></span>
      <h2>WARRANTY DISCLAIMER</h2>
      <span><b>ZODIAC ADVISOR DOES NOT EVALUATE, PROVIDE, PRODUCE OR CONTROL THE USERS, CLIENTS, ADVISORS OR ANY INFORMATION EXCHANGED VIA THE SERVICE. WE ONLY CONNECT CLIENTS WITH ADVISORS THROUGH THE SERVICE. WE DO NOT REPRESENT OR WARRANT THAT ANY ADVISOR YOU SELECT IS QUALIFIED OR LICENSED TO PROVIDE THE INFORMATION OR ADVICE THAT YOU SEEK. AS A RESULT, ZODIAC ADVISOR IS NOT RESPONSIBLE FOR THE TRUTH, ACCURACY, COMPLETENESS, SAFETY, TIMELINESS, QUALITY, APPROPRIATENESS, LEGALITY OR APPLICABILITY OF ANYTHING SAID, DEPICTED OR WRITTEN BY ITS USERS, CLIENTS AND/OR ADVISORS.</b></span>
      <span><b>YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE AND THE INFORMATION OBTAINED FROM THE SERVICE IS PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS. THE SERVICE AND THE INFORMATION OBTAINED FROM THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON INFRINGEMENT OR COURSE OF PERFORMANCE. OUR WEBSITES AND THE SERVICE MAY CONTAIN VIRUSES, BUGS, ERRORS, PROBLEMS OR OTHER LIMITATIONS. IN NO EVENT WILL ZODIAC ADVISOR OR ITS DIRECTORS, EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, SUBSIDIARIES OR AFFILIATES HAVE ANY LIABILITY WHATSOEVER FOR YOUR DISPUTES WITH OTHER USERS, CLIENTS OR ADVISORS, FOR YOUR USE OF THE SERVICE OR ANY INFORMATION OBTAINED FROM THE SERVICE. WE ARE NOT LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NO ADVICE OR INFORMATION OBTAINED BY YOU FROM US THROUGH OUR WEBSITES OR SERVICE SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE NOT EXPRESSLY STATED IN THIS AGREEMENT. THESE EXCLUDED DAMAGES INCLUDE, BUT ARE NOT LIMITED TO, MEDICAL, LEGAL AND ANY OTHER MALPRACTICE DAMAGE CLAIMS OR AWARDS, PAIN AND SUFFERING, THEFT OF PROPERTY, PERSONAL INJURY/WRONGFUL DEATH, LOSS OF INCOME, LOSS OF CONSORTIUM, BUSINESS INTERRUPTION OR MEDICAL BILLS.</b></span>
      <span><b>ZODIAC ADVISOR’ LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US FOR THE SERVICE AND USE OF OUR WEBSITES AND SERVICE, BUT IN NO EVENT WILL OUR LIABILITY TO YOU EXCEED FIFTY DOLLARS ($50.00). YOU ACKNOWLEDGE AND AGREE THAT IF NO FEES ARE PAID TO US, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND YOU SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM US, REGARDLESS OF THE CAUSE OF ACTION. ANY CAUSE OF ACTION ARISING OUT OF OR RELATING TO THESE TERMS OR THE SERVICE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION AROSE; OTHERWISE, IT IS PERMANENTLY BARRED.</b></span>
      <span><b>IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA CIVIL CODE SECTION 1542, WHICH STATES, IN PART: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”</b></span>
      <h2>EXCLUSIONS</h2>
      <span>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to You.</span>
      <h2>GOVERNING LAW</h2>
      <span>You expressly agree that these Terms and any dispute arising out of these Terms or use of the Service shall be governed, construed, and enforced in accordance with the laws of New York, United States, without regard to its conflict of law provisions and excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG). You further agree and consent to the exclusive personal and subject matter jurisdiction and venue of the state and federal courts located in the Bergen County, New Jersey, for the resolution of any such dispute and You agree and submit to personal jurisdiction in such courts. In addition, You forever waive any argument or defense based on personal jurisdiction, venue, or forum non conveniens.</span>
      <h2>CLASS ACTION WAIVER</h2>
      <span><b>IN ANY DISPUTE, NEITHER YOU NOR ANY OTHER PERSON SHALL BE ENTITLED TO JOIN OR CONSOLIDATE CLAIMS AS A REPRESENTATIVE OF A CLASS OR IN A PRIVATE ATTORNEY GENERAL CAPACITY. YOU ACKNOWLEDGE THAT YOU ARE GIVING UP YOUR RIGHTS TO PARTICIPATE IN A CLASS ACTION OR REPRESENTATIVE ACTION WITH RESPECT TO ANY SUCH CLAIM.</b></span>
      <h2>APPLICABLE APPLE INC. TERMS</h2>
      <span>The parties acknowledge that this Agreement is entered into by the End User and Zodiac Advisor only, and not with Apple. Zodiac Advisor, not Apple, is solely responsible for the Service, subject to the terms and conditions of this Agreement. The parties acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to the Service.</span>
      <span>Apple and Apple’s subsidiaries shall be third party beneficiaries of these Terms. Upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against You as a third party beneficiary thereof.</span>
      <span>To the extent that any product warranty is being granted to You by this Agreement and is not disclaimed, if the Service fails to conform to any applicable warranty, You may notify Apple and Apple will refund the purchase price for the Service to You. To the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the Service, and any other claims, losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty is solely Zodiac Advisor’ responsibility.</span>
      <span>The parties acknowledge that Zodiac Advisor, not Apple, is responsible for addressing any of your claims or any third party claims relating to the Service or your possession and/or use of the Service, including, but not limited to: (i) product liability claims; (ii) any claim that the Service fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation.</span>
      <span>In the event of any third party claim that the Service or your possession and use of the Service infringes that third party’s intellectual property rights, Zodiac Advisor, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim.</span>
      <h2>MISCELLANEOUS TERMS</h2>
      <span>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. You may not assign your rights or obligations under this Agreement without Our prior written consent and any attempt to do so without such consent will be null and void and given no force or effect. Zodiac Advisor may assign its rights under this Agreement without condition. These Terms constitute the entire agreement between us regarding Our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</span>
      <h2>CHANGES</h2>
      <span>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time, without notification. The latest Terms will be posted on Our website. It is your responsibility to review the latest Terms before You use the Service. By continuing to access or use Our Service after new Terms become effective, You agree to be bound by the new Terms. If You do not agree to the new Terms, please stop using the Service.</span>
      <h2>CONTACT US</h2>
      <span>If you have any questions about these Terms prior to accepting, please contact Us at <a>info@zodiacadvisor.com</a></span>

    </div>
  )
}

export default TermOfUse