import React, { useEffect, useState } from 'react';
import './addfunds.scss';
import { CgClose } from 'react-icons/cg';
//import { BiSupport } from 'react-icons/bi';
import coinImg from '../../../assets/ic_coin.png';
import singleCoin from '../../../assets/ic_single-coin.png';
import twoCoin from '../../../assets/ic_two-coin.png';
import someCoin from '../../../assets/ic_some-coin.png';
import coupleCoin from '../../../assets/ic_couple-coin.png';
import aChestOfCoin from '../../../assets/ic_a-chest-of-coin.png';
import aBagOfCoin from '../../../assets/ic_a-bag-of-coin.png';
import boxCoin from '../../../assets/ic_box-coin.png';
import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import firebase from '../../../config/firebase';
import { PulseLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { setFunds, setProduct } from '../../../provider/site/site';
import useTranslation from '../../../localization/translations';


const AddFundsModal = () => {

    const dispatch = useDispatch();
    const translation = useTranslation();
    const { funds, user } = useSelector(state => state.site);
    const [balance, setBalance] = useState(0);
    const [productList, setProductList] = useState([])

    const images = {
        0: singleCoin,
        1: boxCoin,
        2: twoCoin,
        3: someCoin,
        4: coupleCoin,
        5: aChestOfCoin,
        6: aBagOfCoin
    }

    useEffect(() => {
        let userDisposable = null;
        if (funds && user && user.uid) {
            userDisposable = onSnapshot(doc(firebase.db, `users/${user.uid}`), (snapshot) => {
                setBalance(snapshot.data().balance);
            });
        }
        return () => {
            if (userDisposable) {
                userDisposable();
            }
        }
    }, [funds, user && user.uid]);

    useEffect(() => {
        let productListDisposable = null;

        if (funds) {
            productListDisposable = onSnapshot(
                query(collection(firebase.db, "products"), orderBy("rank", "asc"))
                , (snapshot) => {
                    setProductList(
                        snapshot.docs.map((productSnapshot) => {
                            const product = productSnapshot.data();
                            return Object.assign({}, product,
                                { id: productSnapshot.id, img: images[product.rank ?? 1] }
                            )
                        })
                    );
                })
        }

        return () => {
            if (productListDisposable) {
                productListDisposable();
            }
        }
    }, [funds])

    function close() {
        dispatch(setFunds(false));
    }

    function selectProduct(product) {
        dispatch(setFunds(false));
        dispatch(setProduct(product));
    }

    return (
        funds && (
            <div className="modal-container">
                <div className="add-funds-modal modal modal-anim">
                    {
                        user ? (
                            <>
                                <div className="top">
                                    <div className="row">
                                        <img src={coinImg} className="coin-icon" alt="" />
                                        <span className="balance">{balance.toFixed()}</span>
                                    </div>
                                    <span className="header">{translation.get_coins}</span>
                                    <CgClose className="close-icon" onClick={close} />
                                </div>
                                <div className="funds-container">
                                    <div className="funds">
                                        {
                                            productList.map(function (product) {

                                                const coin = product.coin;
                                                const price = product.price;
                                                const img = product.img ?? singleCoin;
                                                const badge = product.badge ?? '';
                                                const save = product.save ?? '';
                                                const type = product.type;

                                                return (
                                                    <div className={`fund ${type}`} onClick={() => selectProduct(product)}>
                                                        <div className={`badge ${badge.replace(' ', '_').toLowerCase()} noselect`} style={{ display: badge ? "flex" : "none" }}>{badge}</div>
                                                        <div className="coin">{coin}</div>
                                                        <div className="coin-img">
                                                            <img src={img} alt="" />
                                                            <div className="save noselect" style={{ display: save ? "flex" : "none" }}>{`Save ${save}`}</div>
                                                        </div>
                                                        <div className="price noselect" onClick={() => selectProduct(product)}>{`$${price}`}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                {/* <div className="bottom">
                                <BiSupport className="support-icon"/>
                                <span className="support-text">Contact us if you have any questions</span>
                            </div> */}
                            </>
                        ) : <div style={{ width: "100%", height: "100%", background: "white", display: "flex", alignItems: "center", justifyContent: "center" }}><PulseLoader color="var(--theme-color)" /></div>
                    }
                </div>
            </div>
        )
    )
}

export default AddFundsModal