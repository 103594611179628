import { arrayRemove, arrayUnion, deleteField, doc, updateDoc } from 'firebase/firestore';
import firebase from '../config/firebase';


export default class FavouriteCRUD {

    static async delete(uid) {
        try {
            updateDoc(doc(firebase.db, `users/${firebase.auth.currentUser.uid}`), {
                'favourites': arrayRemove(uid),
            })
        } catch (e) {
            throw e.message;
        } 
    }

    static async add(uid) {
        try {
            updateDoc(doc(firebase.db, `users/${firebase.auth.currentUser.uid}`), {
                'favourites': arrayUnion(uid)
            })
        } catch (e) {
            throw e.message;
        } 
    }
}