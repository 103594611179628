import { createSlice } from "@reduxjs/toolkit";

export const site = createSlice({
    name: 'site',
    initialState: {
        language: "en",
        signInModal: false,
        signUpModal: false,
        menu: false,
        user: null,
        related_users: [],
        videoUrl: null,
        meeting: "",
        meetingId: "",
        languageModal: false,
        funds: false,
        product: "",
        rejectMessage: "",
        customerSupportModal: false,
        notEnoughCoinModal: false,
    },
    reducers: {
        setLanguage: (state, action) => {
            state.language = action.payload;
        },

        showSignInModal: (state, action) => {
            state.signInModal = action.payload;
        },

        showSignUpModal: (state, action) => {
            state.signUpModal = action.payload;
        },

        setMenu: (state, action) => {
            state.menu = action.payload;
        },

        toggleMenu: state => {
            state.menu = !state.menu;
        },

        setUser: (state, action) => {
            state.user = action.payload;
        },

        setVideoUrl: (state, action) => {
            state.videoUrl = action.payload;
        },

        setMeeting: (state, action) => {
            state.meeting = action.payload;
        },

        setMeetingId: (state, action) => {
            state.meetingId = action.payload;
        },

        setLanguageModal: (state, action) => {
            state.languageModal = action.payload;
        },

        toggleLanguageModal: state => {
            state.languageModal = !state.languageModal
        },

        setFunds: (state, action) => {
            state.funds = action.payload;
        },

        toggleFunds: state => {
            state.funds = !state.funds;
        },

        setProduct: (state, action) => {
            state.product = action.payload;
        },

        setCustomerSupportModal: state => {
            state.customerSupportModal = !state.customerSupportModal;
        },

        toggleCustomerSupportModal: (state, action) => {
            state.customerSupportModal = action.payload;
        },

        setRejectMessage: (state, action) => {
            state.rejectMessage = action.payload;
        },

        setNotEnoughCoinModal: (state, action) => {
            state.notEnoughCoinModal = action.payload;
        },


        setRelatedUserMap: (state, action) => {
            const user = action.payload;
            const index = state.related_users.findIndex((relatedUser) => relatedUser.uid === user.uid)
            console.warn(index);
            if(index >= 0) {
                state.related_users[index] = user;
            } else {
                state.related_users = [...state.related_users, user]
            }
        }
    }
})

export const { showSignInModal, showSignUpModal, setMenu, toggleMenu, setUser, setVideoUrl, setMeeting, setLanguage, setLanguageModal, toggleLanguageModal, setFunds, toggleFunds, setProduct, setCustomerSupportModal, toggleCustomerSupportModal, setRejectMessage, setNotEnoughCoinModal, setRelatedUserMap, setMeetingId} = site.actions;
export default site.reducer;