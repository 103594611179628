import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFunds, setNotEnoughCoinModal } from '../../../provider/site/site';
import CrossMark from '../../../assets/ic_crossmark.png';
import {CgClose} from 'react-icons/cg';
import CoinImg from '../../../assets/ic_coin.png';
import './notenoughcoinmodal.scss';
import useTranslation from '../../../localization/translations';

const NotEnoughCoinModal = () => {

    const {notEnoughCoinModal} = useSelector(state => state.site);
    const translation = useTranslation();
    const dispatch = useDispatch();

    return (
        notEnoughCoinModal && (
            <div className="modal-container" onClick={() => dispatch(setNotEnoughCoinModal(false))}>
                <div className="modal modal-anim not-enough-coin-modal" onClick={e => e.stopPropagation()}>
                    <CgClose className="close-button" onClick={() => dispatch(setNotEnoughCoinModal(false))}/>
                    <img src={CrossMark} alt="" className="cross-img"/>
                    <h2>{"Sorry ;("}</h2>
                    <div className="row">
                        <span>{translation.dont_have_coin}</span>
                        <img src={CoinImg} alt="" className="coin-img"/>
                    </div>
                    <div className="get-coin-button noselect" onClick={() => {
                        dispatch(setNotEnoughCoinModal(false));
                        dispatch(setFunds(true));
                    }}>{translation.get_coins}</div>
                </div>
            </div>
        )
    )
}

export default NotEnoughCoinModal