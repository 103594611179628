import React from 'react';
import './reviewskeletion.scss';

const ReviewSkeletion = () => {
  return (
    <div className="review-skeletion">
        <div className="review-info-skeletion">
            <div className="icon-skeletion"></div>
            <div className="name-skeletion"></div>
            <div className="date-skeletion"></div>
        </div>
        <div className="comment-skeletion"></div>
    </div>
  )
}

export default ReviewSkeletion