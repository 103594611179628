import React from 'react'
import './shaderEffect.scss'

const ShaderEffect = (props) => {
    const width = props.width;
    const effectWidth = props.effectWidth;
    const color = props.color;

  return (
    <div className="shader" id="shader" style={{width: width, backgroundImage: `linear-gradient(to bottom right, rgba(255, 255, 255, 0) ${50-(effectWidth/2)}%, ${color} 50%, rgba(255, 255, 255, 0) ${50+(effectWidth/2)}%)`}}></div>
  )
}
export default ShaderEffect