import {
    doc,
    getDoc
} from "firebase/firestore";
import {
    useSelector
} from "react-redux";
import firebase from "../config/firebase";
import Firestore from "../functions/firestore";
import useMeetingRequestController from "./useMeetingRequestController";

const db = firebase.db;

const useSendMeetingRequest = () => {

    const {user} = useSelector(state => state.site);
    const meetingRequestController = useMeetingRequestController();

    async function sendMeetingRequest(advisorId, meetingType) {
        try {
            const advisorSnapshot = await getDoc(doc(db, `users/${advisorId}`));
            const advisor = ({...advisorSnapshot.data(), id: advisorSnapshot.id});
   
            const price = advisor.live_modes[meetingType].price.discount_price;
            const isValidRequest = meetingRequestController(user, advisor, meetingType, price);

            if (isValidRequest) {
                await Firestore.createMeetingRequest({
                    "customerId": user.uid,
                    "advisorId": advisor.id,
                    "meetingType": meetingType,
                    "price": price,
                });
            }
        } catch (error) {
            throw error;
        }
    }

    return sendMeetingRequest;
}

export default useSendMeetingRequest