import React from 'react';
import './endsection.scss';
import { RiInstagramFill } from 'react-icons/ri';
import { BsFacebook } from 'react-icons/bs';
import { FaTwitter } from 'react-icons/fa';
import useTranslation from '../../localization/translations';
import { useNavigate } from 'react-router-dom';
import { setCustomerSupportModal } from '../../provider/site/site';
import { useDispatch } from 'react-redux';


const EndSection = () => {

    const translation = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <div className="end-section" id="end-section">
            <div className="end-section-container">
                <div className="topsec">
                    <div className="topsec1">
                        <div className="links">
                            <div className="about-true-advisor">
                                <h3>{translation.about_zodiac_advisor}</h3>
                                {/* <li>{translation.about_us}</li> */}
                                <li onClick={() => navigate('/advisor_terms')}>{translation.advisor_terms_and_conditions}</li>
                                <li onClick={() => navigate('/privacy_policy')}>{translation.privacy_policy}</li>
                                <li onClick={() => navigate('/terms_of_use')}>{translation.terms_of_use}</li>
                                <li onClick={() => dispatch(setCustomerSupportModal(true))}>{translation.contact_us}</li>
                            </div>
                            <div className="become-an-advisor">
                                <h3>{translation.become_an_advisor}</h3>
                                <li onClick={() => navigate('/for_advisors')}>{translation.for_advisors}</li>
                            </div>
                            {/* <div className="how-it-works">
                                <h3>{translation.how_it_works}</h3>
                                <li>{translation.getting_started}</li>
                                <li>{translation.how_to_find_advisor}</li>
                                <li>{translation.terms_of_use}</li>
                            </div> */}
                        </div>
                        <div className="get-on-mobile">
                            <div className="android"></div>
                            <div className="ios"></div>
                        </div>
                    </div>

                    <div className="cards">
                        <span>{translation.we_accept}</span>
                        <div className="cards-container">
                            <div style={{ "--i": 0 }}></div>
                            <div style={{ "--i": 1 }}></div>
                            <div style={{ "--i": 2 }}></div>
                            <div style={{ "--i": 3 }}></div>
                        </div>
                        <div className="social">
                            <a href='https://www.instagram.com/zodiacadvisor/' target="_blank"><RiInstagramFill className='social-icon' /></a>
                            <a href='https://www.facebook.com/profile.php?id=100088474826377' target="_blank"><BsFacebook className='social-icon' /></a>
                        </div>
                    </div>
                </div>

            </div>
            <div className="bottomsec">
                <span>2022 Zodiac Advisor © {translation.all_rights_reserved}</span>
            </div>
        </div>
    )
}

export default EndSection