import React, { useEffect, useRef } from 'react';
import './offermodal.scss';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import useTranslation from '../../../localization/translations';

const OfferModal = (props) => {

    const {state, setState} = props;

    const translation = useTranslation();
    const offerModalRef = useRef('');
    

    useEffect(() => {
        let mouseDownSubscribe = null;
        let mouseUpSubscribe = null;

        
        setTimeout(() => {
            const offerModal = document.getElementById('offer-modal');

            function handleMouseDown() {
                console.log("--Mouse down--");
                window.addEventListener('mousemove', handleMouseMove, true);
                
            }
    
            function handleMouseUp() {
                console.log("--Mouse up--");
                offerModal.style.bottom = "35px";
                window.removeEventListener('mousemove',  handleMouseMove, true);
            }

            function handleMouseMove(event) {
                console.log("--Mouse move--");
                const height = window.innerHeight;
                var x = event.clientX;
                var y = event.clientY;


                if((height - y) > 450) {

                } else {
                    if((height - y) < 50) {
                        window.removeEventListener('mousemove', handleMouseMove, true);
                        offerModal.style.bottom = "-500px";
                        setTimeout(() => setState(prev => ({...prev, open: false})), 200);
                    } else {
                        offerModal.style.bottom = `${(height - y - 380)}px`;
                    }
                }
            }


    
            if(offerModal) {
                mouseDownSubscribe = offerModal.addEventListener('mousedown', handleMouseDown);
                mouseUpSubscribe = offerModal.addEventListener('mouseup', handleMouseUp);
            }
        }, 2000);
        

        return () => {
            if(mouseDownSubscribe) {
                mouseDownSubscribe();
            }
            if(mouseUpSubscribe) {
                mouseUpSubscribe();
            }
            window.removeEventListener('mousemove', () => null, true);
        }
    }, [offerModalRef.current]);

    return (
        state.open && (
            <div className="modal-container noselect" style={{display: "flex", marginTop: 0}}>
                <div className="offer-modal modal-anim" ref={offerModalRef} id="offer-modal">
                    <CountdownCircleTimer
                        isPlaying={true}
                        duration={state.duration}
                        colors={['#F7B801']}
                        initialRemainingTime={state.remainingTime}
                        colorsTime={[0]}
                        size="80"
                        strokeWidth={4}
                        trailColor="rgba(255, 255, 255, 0)"
                        strokeLineCap="square"
                        rotation="counterclockwise"
                        strokeLinecap="square"
                    >
                        {({ remainingTime }) => (
                            <div className="time-container">
                                <span className="time">{`${Math.floor((remainingTime / 60)).toString().padStart(2, '0')}:${(remainingTime % 60).toString().padStart(2, '0')}`}</span>
                            </div>
                        )}
                    </CountdownCircleTimer>
                    <span className="get-more">{translation.get_more_coins_to_continue}</span>
                    <div className="offer">
                        <div className="row">
                            <img className="coin-img" src={require('../../../assets/ic_coin.png')} alt="" />
                            <span className="coin">{state.coin}</span>
                        </div>
                        <div className="price">{`SAR ${state.price}`}</div>
                    </div>
                    <img className="coin-effect" src={require('../../../assets/ic_coin-effect.png')} alt="" />
                    <div className="space"></div>
                    <div className="get-coin" onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}>{translation.get_coins}</div>
                </div>
            </div>
        )
    )
}

export default OfferModal