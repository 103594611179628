import Readings from '../../components/readingscreen/readingscreen';
import clairvoyanceImg from '../../assets/ic_clairvoyance.png';
import useTranslation from '../../localization/translations';

const Clairvoyance = () => {
  
  const translation = useTranslation();

  return (
    <Readings skill={"clairvoyance"} img={clairvoyanceImg} header={"Clairvoyance readings"} text={translation.clairvoyance_description} skillFilter={"Clairvoyance"}/>
  )
}

export default Clairvoyance