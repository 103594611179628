import { doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import firebase from "../config/firebase";
import NotificationControl from "../functions/notification";
import { setMeeting, setRejectMessage } from "../provider/site/site";
import logo from '../assets/ic_fallogo.png';

// const delay = (delayInms) => {
//     return new Promise(resolve => setTimeout(resolve, delayInms));
// }

export default function useMeetingListener () {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {user, meetingId} = useSelector(state => state.site);

    useEffect(() => {
        let meetingSubscribe = null;
        if(meetingId) {
            const meetingRef = doc(firebase.db, `meetings/${meetingId}`);

            meetingSubscribe = onSnapshot(meetingRef, async function(meeting) {
                const meetingData = meeting.data();
                if (!meetingData) {
                    return
                }
                const meetingId = meeting.id;
                const status = meetingData.status;
                dispatch(
                    setMeeting({
                        id: meetingId,
                        data: meetingData,
                    })
                );
                switch(status) {
                    case "accepted":
                        if(meetingData.meeting_type === "chat") {
                            navigate('/chat');
                        }
                        if(meetingData.meeting_type === "video") {
                            navigate('/video')
                        }
                        if(meetingData.meeting_type === "voice") {
                            navigate('/voice')
                        }
                        break;
                    case "pending":
                        if(user.user_type === "advisor") {
                            NotificationControl.createScheduledNotification('call', `Calling 🔔`, `You have a ${meeting.get('meeting_type')} call!`, logo);
                        }
                        console.log('MEETING SETTED: ', meetingData);
                        break;
                    case "terminated":
                        navigate('/');
                        dispatch(setMeeting(''));
                        // Do nothing, since when a meeting terminated, user.status.meeting had been set to null
                        // so that useEffect will unsubscribe from the meeting and set related states.
                        break;
                    case "stopped": 
                        navigate('/');
                        dispatch(setMeeting(''));
                        break;
                    case "rejected":
                        console.log("Rejected meeting: ", meetingData);
                        dispatch(setRejectMessage(meetingData.reject_message));
                        dispatch(setMeeting(''));
                        break;
                    default:
                        dispatch(setMeeting(''));
                        break;
                }
            }, (error) => console.log('ERROR', error.message));
        } else {
            console.log("User not found so that meetings could not be fetched: ", user)
        }

        return async () => {
            if(meetingSubscribe) {
                meetingSubscribe();
            }
        }
    }, [meetingId]);
}


