import { useEffect, useState } from "react";

export default function useWindowDimensions () {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        setOffset({"width": width, "height": height});
        const onResize = () => setOffset({"width": window.innerWidth, "height": window.innerHeight});
        // clean up code
        window.removeEventListener('resize', onResize);
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);

    return offset
}