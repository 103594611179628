import React from 'react';
import './averagecallmodal.scss';
//import { CgClose } from 'react-icons/cg';
import ClockImg from '../../../assets/ic_clock.png';
import { useDispatch, useSelector } from 'react-redux';
import { setAverageCallModal } from '../../../provider/modal/modal';
import { setFunds } from '../../../provider/site/site';
import useSendMeetingRequest from '../../../hooks/useSendMeetingRequest';
import useTranslation from '../../../localization/translations';


const AverageCallModal = () => {

    const dispatch = useDispatch();
    const translation = useTranslation();
    const { averageCallModal } = useSelector(state => state.modal);
    const sendMeetingRequest = useSendMeetingRequest();

    const modalPayload = {
        open: false,
        isShowed: false,
        advisorId: "",
        meetingType: "",
    }

    return (
        averageCallModal.open && (
            <div className="modal-container" onClick={() => dispatch(setAverageCallModal(modalPayload))}>
                <div className="modal modal-anim avarage-call-modal" onClick={e => e.stopPropagation()}>
                    {/* <CgClose className="close-icon" onClick={() => dispatch(setAverageCallModal(modalPayload))} /> */}
                    <img src={ClockImg} alt="" className="clock-img" />
                    <h2>{translation.do_you_know}</h2>
                    <span>{translation.average_meeting}</span>
                    <div className="row">
                        <div className="get-coin-button noselect" onClick={() => {
                            dispatch(setFunds(true));
                            dispatch(setAverageCallModal(modalPayload));
                        }}>{translation.get_coins}</div>
                        <div className="continue-button noselect" onClick={() => {
                            sendMeetingRequest(averageCallModal.advisorId, averageCallModal.meetingType);
                            dispatch(setAverageCallModal(modalPayload));
                        }}>{translation.continue}</div>
                    </div>
                </div>
            </div>
        )
    )
}

export default AverageCallModal