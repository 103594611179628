import Readings from '../../components/readingscreen/readingscreen';
import loveImg from '../../assets/ic_love-readings.webp'
import useTranslation from '../../localization/translations';

const Love = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"love"} img={loveImg} header={"Love readings"} text={translation.love_description} skillFilter={'Love'}/>
  )
}

export default Love