import React from 'react';
import {HiEmojiHappy, HiEmojiSad} from 'react-icons/hi';
import DateHooks from '../../hooks/dateHook';
import './reviewcard.scss';

const ReviewCard = ({review}) => {

    //const customerId = review.owner;
    const customerName = review.name;
    const point = review.point;
    const comment = review.comment;
    const createdAt = DateHooks.getDateByStringFromSec(review.created_at.seconds);

    return (
        <div className="review-card">
            <div className="review-info">
                {point > 2 ? <HiEmojiHappy className="happy-icon" /> : <HiEmojiSad className="sad-icon" />}
                <span className="review-info-name">{customerName}</span>
                <span className="review-info-createdat">{createdAt}</span>
            </div>
            <span className="review-comment">{comment}</span>
        </div>
    )
}

export default ReviewCard