import Readings from '../../components/readingscreen/readingscreen';
import dreamImg from '../../assets/ic_dream-analysis.webp'
import useTranslation from '../../localization/translations';

const Dream = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"dream"} img={dreamImg} header={"Dream analysis"} text={translation.dream_description} skillFilter={"Dream"}/>
  )
}

export default Dream