
import React, { useEffect, useState } from 'react'
import Bar from './components/navbar/navbar';
import EndSection from './components/endsection/endsection';
import VideoPlayer from './components/videoplayer/videoplayer';
import MeetingSection from './components/modal/meeting/meeting';
import SignInModal from './components/modal/loginmodal/login';
import SignUpModal from './components/modal/signinmodal/signup';
import PaymentModal from './components/modal/paymentmodal/paymentModal';
import CustomerSupportModal from './components/modal/customersupportmodal/customersupportmodal';
import { useDispatch, useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom';
import { setUser, showSignUpModal, showSignInModal, setLanguage, setRelatedUserMap, setMeetingId } from './provider/site/site';
import { goOffline, goOnline } from 'firebase/database';
import useMeetingListener from './hooks/useMeetingListener';
import LanguageModal from './components/modal/languagemodal/languagemodal';
import UserService from "./functions/UserService";
import { PulseLoader } from 'react-spinners';
import { fetchAndActivate } from 'firebase/remote-config';
import ReviewModal from './components/modal/reviewmodal/reviewmodal';
import AddFundsModal from './components/modal/addfunds/addfunds';
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import CookieCRUD from './storage/cookie';
import Router from './router';

import firebase from './config/firebase';
import ReactGA from 'react-ga4';
import CallAudio from './components/callaudio';
import NotEnoughCoinModal from './components/modal/notenoughcoinmodal/notenoughcoinmodal';
import AverageCallModal from './components/modal/averagecallmodal/averagecallmodal';
import NotificationControl from './functions/notification';
import { onMessage } from 'firebase/messaging';


function App() {

  ReactGA.initialize('G-ED3H7VLTHP');
  let location = useLocation();

  const uid = CookieCRUD.getCookie('uid');
  const cookieLanguage = CookieCRUD.getCookie('language');

  const dispatch = useDispatch();
  const [fetch, setFetch] = useState(false);
  const { user, related_users } = useSelector(state => state.site);
  const [authorizedUserId, setAuthorizedUserId] = useState(null);
  const userService = new UserService();

  useMeetingListener();
  //useLinkAdvisors();

  useEffect(() => {
    if(user && user.preffered_language) {
      dispatch(setLanguage(user.preffered_language));
    } else {
      if(cookieLanguage) {
        dispatch(setLanguage(cookieLanguage && cookieLanguage.toLowerCase()));
      } else {
        const lang = ["en", "es"].includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : "en";
        dispatch(setLanguage(lang));
      }
    }
  }, [user && user.preffered_language, cookieLanguage]);

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send('pageview');
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location])

  useEffect(() => {
    async function fetchRemoteConfig() {
      try {
        await fetchAndActivate(firebase.remoteConfig)
        setFetch(true);
      } catch (e) {
        console.log(e);
      }
    }

    fetchRemoteConfig();

    const unsubscribe = firebase.auth.onAuthStateChanged(async function (authorizedUser) {
      setAuthorizedUserId(authorizedUser && authorizedUser.uid)
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    let fetchUserDisposable = null;
    let disposables = []
    if (authorizedUserId) {
      dispatch(showSignInModal(false));
      dispatch(showSignUpModal(false));

      async function getChildren() {
        const children = (await getDoc(doc(firebase.db, `users/${authorizedUserId}`))).get("children");
        return children
      }

      if(related_users.length === 0) {
        getChildren().then(children => {
          disposables = children.map((childId) => {
            return onSnapshot(doc(firebase.db, `users/${childId}`), (snapshot) => {
              const data = snapshot.data();
              dispatch(
                setRelatedUserMap(
                  {uid: childId, ...data}
                )
              )
              if(data.status.meeting) {
                dispatch(
                  setMeetingId(data.status.meeting)
                )
              }
            })
          })
        })
      }

      // Fetches user from database
      // then sets the user globally.
      goOnline(firebase.rDb);
      fetchUserDisposable = onSnapshot(doc(firebase.db, `users/${authorizedUserId}`), (snapshot) => {
        const data = snapshot.data();
        console.log("User updated: ", data);

        if(data.profile) {
          dispatch(
            setUser({
              ...data,
              'uid': authorizedUserId,
            })
          )
          if(data.status.meeting) {
            dispatch(
              setMeetingId(data.status.meeting)
            )
          }
        }
      })
    } else {
      goOffline(firebase.rDb);
      dispatch(setUser(null))
    }

    return () => {
      if (fetchUserDisposable) {
        console.log("Dispose user.")
        fetchUserDisposable();
      }
      disposables.forEach(disposable => {
        disposable()
      })
    }
  }, [authorizedUserId])

  useEffect(() => {
    if (user && user.user_type === "advisor") {
      userService.setStatus(firebase, user.uid);
    }
  }, [user && user.uid]);

  useEffect(() => {
    if(user && user.status && user.status.is_online) {
      if(!user.status.is_online) {
        async function updateUser() {
          await updateDoc(doc(firebase.db, `users/${user.uid}`), {
            'status.is_online': true,
          });
        }

        setTimeout(updateUser, 1000);
      }
    }
  }, [user && user.status && user.status.is_online]);

  useEffect(() => {
    if(user) {
      async function updateNotificationToken() {
        console.warn("UPDATE NOTIFICATION FUNCTION WORK!");
        try {
          await NotificationControl.updateNotificationToken();
        } catch (error) {
          console.warn(error);
        } 
      }

      updateNotificationToken();
    }
  }, ['Notification' in window && Notification.permission, user]);

  useEffect(() => {
    NotificationControl.requestNotificationPermission();
  }, []);

  useEffect(() => {
    onMessage(firebase.messaging, async (payload) => {
        console.log("Recevied notification message: ", payload);
        return await navigator.serviceWorker.ready.then(registration => {
          registration.showNotification(payload.notification.title, {
            body: payload.notification.body,
            icon: payload.notification.icon,
            sound: payload.notification.sound,
            data: payload.data
        });
      });
    });
  }, []);


  return (
      fetch && (!uid || (uid && user)) ? (
        <div className="app noscrollbar" id="app">
          {/* <VerifyFlag user={user}/> */}
          <Bar /> 
          <MeetingSection />
          <CallAudio/>
          <NotEnoughCoinModal/>
          <AverageCallModal/>
          <Router/>
          <SignInModal />
          <SignUpModal />
          <VideoPlayer />
          <AddFundsModal />
          <PaymentModal />
          <ReviewModal />
          <LanguageModal />
          <CustomerSupportModal />
          <EndSection />
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "fixed", top: 0, left: 0 }}><PulseLoader color="var(--theme-color)" /></div>
      )
  )
}

export default App;
