import Resizer from "react-image-file-resizer";

const useImageResizer = () => {
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "WEBP",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    return resizeFile;
}

export default useImageResizer