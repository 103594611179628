import React from 'react'
import ShaderEffect from '../shaderEffect/shaderEffect'
import './profilecardcircleskeletion.scss'

const ProfileCardCircleSkeletion = () => {
  return (
    <div className="profile-card-circle-skeletion">
        <ShaderEffect width="80px" effectWidth="40" color="#fff2"/>
        <div className="profile-image">
            <div className="skeletion-img"></div>
        </div>

        <div className="name-skill">
          <div className="skeletion-name"></div>
          <div className="skeletion-skill"></div>
        </div>
      </div>
  )
}

export default ProfileCardCircleSkeletion