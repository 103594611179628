import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegThumbsUp } from 'react-icons/fa';
import { CgClose } from 'react-icons/cg';
import { AiFillStar } from 'react-icons/ai';
import './reviewmodal.scss';
import Firestore from '../../../functions/firestore';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import firebase from '../../../config/firebase';
import useTranslation from '../../../localization/translations';
import { PulseLoader } from 'react-spinners';

const ReviewModal = () => {

  const [point, setPoint] = useState(5);
  const [reviewState, setReviewState] = useState({
    sending: false,
    is_sent: false,
    has_error: false
  });
  const [comment, setComment] = useState('');
  const { user } = useSelector(state => state.site);
  const translation = useTranslation();

  async function close() {
    try {
      await updateDoc(doc(firebase.db, `users/${user.uid}`), {
        review_needed: deleteField()
      })
    } catch (e) {
      console.log(e);
    }
  }

  const handleComment = e => setComment(e.target.value);

  function Star({ point_, onClick }) {
    const a = point_ <= point;
    return (
      <AiFillStar className="star-icon" style={{ color: a ? "orange" : "white" }} onClick={onClick} />
    )
  }

  async function sendReview() {
    setReviewState(prev => ({
      ...prev,
      sending: true
    }));
    try {
      const customerId = user.uid;
      const customerName = user.profile.name;
      const meetingId = user.review_needed.meeting_id;
      const advisorId = user.review_needed.advisor_id;
      await Firestore.createReview({ comment, point, advisorId, customerId, customerName, meetingId });
    } catch (e) {
      return setReviewState({
        sending: false,
        is_sent: false,
        has_error: true
      })
    }
    setReviewState({
      sending: false,
      is_sent: true,
      has_error: false
    })

    return await close()
  }

  return (
    user && user.review_needed && (
      <div className="modal-container" onClick={close}>

        {
          user.user_type === "customer" ? (
            <div className="review modal-anim" onClick={(e) => e.stopPropagation()}>
              <div className="close-button" onClick={close}>
                <CgClose className="close-icon" />
              </div>

              <div className="thumbs-up">
                <div className="thumbs-up-container">
                  <FaRegThumbsUp className="thumbs-icon" />
                </div>
              </div>

              <h3 className="header">{translation.meeting_ended}</h3>
              <span className="text">{translation.could_you_share_experience}</span>
              <div className="star-container">
                <Star point_={1} onClick={() => setPoint(1)} />
                <Star point_={2} onClick={() => setPoint(2)} />
                <Star point_={3} onClick={() => setPoint(3)} />
                <Star point_={4} onClick={() => setPoint(4)} />
                <Star point_={5} onClick={() => setPoint(5)} />
              </div>

              <textarea name="review" id="review" cols="30" rows="5" value={comment} onChange={handleComment} placeholder="Enter your review here.." style={{ textAlign: comment ? "start" : "center" }}></textarea>
              <div className="send-button" onClick={sendReview} style={{ pointerEvents: reviewState.sending ? "none" : "all", opacity: reviewState.sending && comment ? ".5" : "1" }}>
                {reviewState.sending ? <PulseLoader color="white" size={10}/> : translation.submit}
              </div>
              <div className="reject-button" onClick={close}>{translation.maybe_later}</div>

            </div>
          ) : (
            <div className="review modal-anim advisor_">
              <div className="close-button" onClick={close}>
                <CgClose className="close-icon" />
              </div>
              <h3 className="header">{translation.meeting_ended}</h3>
              <span className="text"><b>{translation.total_earned}</b>x{` ${translation.coin}`}</span>
            </div>
          )
        }
      </div>
    )
  )
}

export default ReviewModal