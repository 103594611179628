import React from 'react';
import './profilecard.scss';
import { FaStar, FaPlay } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import DateHooks from '../../hooks/dateHook';
import { useDispatch } from 'react-redux';
import { setVideoUrl } from '../../provider/site/site';
import coinImg from '../../assets/ic_coin.png';
import useTranslation from '../../localization/translations';

const Profilecard = ({advisor}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translation = useTranslation();

    const ID = advisor.id;
    const PROFILE = advisor.profile;
    const STATUS = advisor.status;
    const REVIEWS = advisor.reviews;
    const LIVEMODES = advisor.live_modes;

    const NAME = PROFILE.name;
    const AGE = PROFILE.age;
    const POINT = REVIEWS.point_avg;
    const DESCRIPTION = PROFILE.description;
    const PIC_URL = PROFILE.pic_url;
    const DISCOUNT_TITLE = LIVEMODES.chat.price.discount_title;
    const DISCOUNT_PERCENT = LIVEMODES.chat.price.discount_percent;
    const DISCOUNT_PRICE = LIVEMODES.chat.price.discount_price;
    const NORMAL_PRICE = LIVEMODES.chat.price.normal_price;
    const READING_SINCE = DateHooks.getYearByStringFromSec(advisor.created_at.seconds);
    const READING_AMOUNT = REVIEWS.count.total;
    const SKILLS = PROFILE.skills;
    const IS_ONLINE = STATUS.is_online;
    const BUSY = STATUS.busy;
    const VIDEO_URL = PROFILE.video_url;

    const Reading = (props) => {
        const id = props.id;

        return (
            <span className="readingSince" id={id}><b>{READING_AMOUNT}</b> {`${translation.reading}`}</span>
        )
    }

    const NameSkill = (props) => {
        const id = props.id;

        return (
            <div className="name-skill" id={id}>
                <span className="name noscrollbar">{NAME}</span>
                <div className="row noscrollbar">
                    {
                        SKILLS.map(function (skill, index) {
                            const skill_ = translation[skill.toLowerCase()];
                            const isLast = index === (SKILLS.length - 1);
                            return (
                                <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    return (
        <div className="advisor-profile-card" onClick={() => navigate(`/advisor/${NAME}`, { state: { id: ID } })}>
            <div className="profileImg">
                <img src={PIC_URL} alt="" />
                <div className="point-play">
                    <div className="point">
                        <FaStar className="starIcon" />
                        <span>{POINT}</span>
                    </div>
                    <FaPlay className="playIcon" onClick={(event) => {
                        event.stopPropagation();
                        dispatch(setVideoUrl(VIDEO_URL));
                    }} />
                </div>
                <NameSkill key="name-skill-position1" id="name-skill-position1" />
                <Reading key="reading-position1" id="reading-position1" />
                <div className="status" style={{ background: BUSY ? "var(--red-color)" : IS_ONLINE ? "var(--green-color)" : "var(--red-color)" }}>{BUSY ? translation.busy : IS_ONLINE ? translation.online : translation.offline}</div>
            </div>

            <div className="info-call">
                <div className="infoSec">
                    <NameSkill key="name-skill-position2" id="name-skill-position2" />
                    <Reading key="reading-position2" id="reading-position2" />
                    <span className="description">{DESCRIPTION}</span>
                </div>

                <div className="callSec">
                    <div className="stream">
                        <div className="chat-call"></div>
                        <div className="voice-call"></div>
                        <div className="video-call"></div>
                    </div>
                    <div className="row" style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "5px" }}>{translation.from}</span>
                        {
                            DISCOUNT_PERCENT > 0 ? (
                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                    <span style={{ marginRight: "2px", fontSize: "14px", textDecoration: "line-through", textDecorationColor: "red" }}><b style={{ color: "red" }}>{NORMAL_PRICE}</b></span>
                                    <span style={{ marginRight: "2px", fontSize: "16px" }}><b>{DISCOUNT_PRICE}</b></span>
                                </div>
                            ) : (
                                <span style={{ marginRight: "2px" }}><b>{NORMAL_PRICE}</b></span>
                            )
                        }
                        <img src={coinImg} alt="" style={{ width: "20px", height: "20px", marginRight: "1px" }} />
                        <span style={{ marginRight: "1px" }}><b>/</b></span>
                        <span><b>min</b></span>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Profilecard