import React from 'react';
import ShaderEffect from '../shaderEffect/shaderEffect';
import './profilecardskeletion.scss';

const ProfileCardSkeletion = () => {
    return (
        <div className="skeletion">
            <ShaderEffect width="200px" effectWidth="40" color="#fff2" />
            <div className="skeletionImg"></div>
            <div className="skeletionBottom">
                <div className="skeletion1"></div>
                <div className="skeletion2"></div>
                <div className="skeletion3">
                    <div className="left">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="right"></div>
                </div>
            </div>
        </div>
    )
}

export default ProfileCardSkeletion