class DateHooks {
    static getDateFromSec = (sec) => {
        const date = new Date(sec * 1000);
        return date;
    }

    static getDiffBySec = (date1, date2) => {
        return Math.abs((date1.getTime()/1000) - (date2.getTime()/1000)).toFixed(0);
    }

    static getDateByStringFromDate = (date) => {
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        const dateByString = `${padL(date.getMonth()+1)}/${padL(date.getDate())}/${date.getFullYear()} ${padL(date.getHours())}:${padL(date.getMinutes())}:${padL(date.getSeconds())}`;
        return dateByString;
    }

    static getDateByStringFromSec = (sec) => {
        const date = new Date(sec * 1000);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        const dateByString = `${padL(date.getMonth()+1)} ${this.monthToString(date.getDate())} ${date.getFullYear()}`;
        return dateByString;
    }

    static getYearByStringFromSec = (sec) => {
        const date = new Date(sec * 1000);
        const yearByString = date.getFullYear();
        return yearByString;
    }

    static getHMByStringFromSec = (sec) => {
        const date = new Date(sec * 1000);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        const hmByString = `${padL(date.getHours())}:${padL(date.getMinutes())}`;
        return hmByString;
    }

    static getDayNumAndMonthLong = () => {
        const today = new Date();
        return today.toLocaleDateString("en-US", {month: "long", day: "numeric"})
    }

    static getDateDiffByDay = (t2) => {
        const t1 = new Date().getTime()/1000;
        return Math.abs(Math.floor((t1-t2)/(60*60*24)));
    }

    static monthToString = (month) => {
        switch(month){
            case 1:
                return 'Jan';
            case 2:
                return 'Feb';
            case 3:
                return 'Mar';
            case 4:
                return 'Apr';
            case 5:
                return 'May';
            case 6:
                return 'Jun';
            case 7:
                return 'Jul';
            case 8:
                return 'Aug';
            case 9:
                return 'Sep';
            case 10:
                return 'Oct';
            case 11:
                return 'Nov';
            case 12:
                return 'Dec';
            default:
                return 'Jan';
        }
    }
}

export default DateHooks