import Readings from '../../components/readingscreen/readingscreen';
import astrologyImg from '../../assets/ic_astrology-horoscopes.webp'
import useTranslation from '../../localization/translations';

const Astrology = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"astrology"} img={astrologyImg} header={"Astrology & horoscopes"} text={translation.astrology_description} skillFilter={"Astrology"}/>
  )
}

export default Astrology