import { useSelector } from 'react-redux';
import {en} from './languages/en';
import {es} from './languages/es';

const translations = {
    en,
    es
}

export default function useTranslation() {

    // Find missing key if exist

    // Object.keys(en).forEach(enKey => {
    //     if(!Object.keys(es).includes(enKey)) {
    //         console.warn("FOUND MISSING KEY", enKey);
    //     }
    // })

    const {language} = useSelector(state => state.site);

    const currentLang = translations[language]

    return currentLang;
}