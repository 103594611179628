import React, { useEffect } from 'react';
import { useState } from 'react';
import './login.scss';
import logo from '../../../assets/ic_fallogo.png';
import '../../../config/firebase';
import { CgClose } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { showSignInModal, showSignUpModal } from '../../../provider/site/site';
import { PulseLoader } from 'react-spinners';
import { FcGoogle } from 'react-icons/fc';
import { FaFacebookF } from 'react-icons/fa';
import Auth from '../../../functions/auth';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../../localization/translations';
import ElementControl from '../../../functions/elementcontrol';

const LogInModal = () => {

  const translation = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { signInModal } = useSelector(state => state.site);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmail = e => {
    setEmail(e.target.value);
  }

  const handlePassword = e => {
    setPassword(e.target.value);
  }

  function closeModal() {
    setEmail('');
    setPassword('');
    setLoading(false);
    dispatch(showSignInModal(false));
  }

  useEffect(() => {

    function fetchLoginInfo() {
      const localStorageLogin = window.localStorage.getItem('login');
      const sessionStorageLogin = window.sessionStorage.getItem('login');
      console.warn("WINDOW EVENT: ",localStorageLogin);
      console.warn("WINDOW EVENT: ",sessionStorageLogin);
      if (localStorageLogin) {
        const login = JSON.parse(localStorageLogin);
        setEmail(login.email);
        setPassword(login.password);
      } else {
        if(sessionStorageLogin) {
          const login = JSON.parse(sessionStorageLogin);
          setEmail(login.email);
          setPassword(login.password);
        }
      }
    }

    fetchLoginInfo();
  }, [signInModal]);

  const signIn = async () => {
    setLoading(true);
    try {
      await Auth.signIn(email, password);
      closeModal();
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  }

  function goRoute() {
    dispatch(showSignInModal(false));
    ElementControl.removeFixedElements();
    navigate('/forgot_password');
  }

  return (
    signInModal && (
      <div className="modal-container" onClick={closeModal}>
        <div className="sign-modal modal-anim modal" onClick={(e) => e.stopPropagation()}>

          <div className='loading' style={{ display: loading ? "flex" : "none" }}>
            <PulseLoader color="var(--theme-color)" size={20} margin={5} />
          </div>

          <CgClose className="closebtn" onClick={closeModal} />

          <div className="topSection">
            <img src={logo} alt="" />
            <span>Zodiac Advisor</span>
          </div>

          <form action="">
            <input type="text" placeholder={translation.email} onChange={handleEmail} value={email} />
            <input type="password" placeholder={translation.password} onChange={handlePassword} value={password} />
          </form>

          <div className="signin-button noselect" onClick={() => signIn()}>
            <span>{translation.sign_in}</span>
          </div>

          <span className="forgot noselect" onClick={goRoute}>{translation.forgot_password}</span>

          <div className="orSign">
            <span>{translation.or_sign_in}</span>
            <div className="row">
              <div className="f-sign" onClick={() => Auth.signInWithFacebook()}><FaFacebookF className="icon" /></div>
              <div className="g-sign" onClick={() => Auth.signInWithGoogle()}><FcGoogle className="icon" /></div>
            </div>
          </div>

          <span className="dont-have" onClick={() => {
            dispatch(showSignInModal(false));
            dispatch(showSignUpModal(true));
          }}>{translation.dont_have}</span>

        </div>
      </div>
    )
  )
}

export default LogInModal