import {initializeApp} from 'firebase/app';
import { getAuth } from 'firebase/auth';
import * as firebaseStorage from "firebase/storage";
import { getDatabase } from 'firebase/database';
import { getFirestore } from 'firebase/firestore';
import { getRemoteConfig } from 'firebase/remote-config';
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rDb = getDatabase(app)
const messaging = getMessaging(app);
const storage = firebaseStorage.getStorage(app, "gs://true-advisor-6e55e.appspot.com");
const remoteConfig = getRemoteConfig(app);
const fbFunctions = getFunctions(app, "us-central1");

export default {db, rDb, auth, storage, remoteConfig, fbFunctions, messaging}