import React, { useEffect, useRef } from 'react';
import './signup.scss';
import logo from '../../../assets/ic_fallogo.png';
import { useState } from 'react';
import {CgClose} from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { showSignInModal, showSignUpModal } from '../../../provider/site/site';
import { PulseLoader } from 'react-spinners';
import {FcGoogle} from 'react-icons/fc';
import {FaFacebookF} from 'react-icons/fa';
import Auth from '../../../functions/auth';
import useTranslation from '../../../localization/translations';
import parse from 'html-react-parser'
import { useNavigate } from 'react-router-dom';

const SignInModal = () => {

  const translation = useTranslation();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const {signUpModal} = useSelector(state => state.site);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = new Auth();


  const handleEmail = e => {
    setEmail(e.target.value);
  }

  const handleName = e => {
    setName(e.target.value);
  }

  const handlePassword = e => {
    setPassword(e.target.value);
  }

  const signUp = async () => {
    setLoading(true);
    try {
      await auth.signUp(name,email,password);
      closeModal();
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  }

  function closeModal() {
    setName('');
    setEmail('');
    setPassword('');
    setLoading(false);
    dispatch(showSignUpModal(false));
  }

  function navigateTerms() {
    const terms = document.getElementById("terms");
    if(terms) {
      const asdasd = terms.getElementsByTagName("b")[0];
      terms.getElementsByTagName("b")[0].onclick = () => {
        dispatch(showSignUpModal(false));
        navigate('/privacy_policy');
      };
      terms.getElementsByTagName("b")[1].onclick = () => {
        dispatch(showSignUpModal(false));
        navigate('/terms_of_use')
      }
    }
  }


  useEffect(() => {
    navigateTerms();
  }, [signUpModal]);

  return (
    signUpModal && (
      <div className="modal-container" onClick={closeModal}>
        <div className="sign-up-modal modal-anim modal" onClick={(e) => e.stopPropagation()}>

          <div className='loading' style={{display: loading ? "flex" : "none"}}>
            <PulseLoader color="var(--theme-color)" size={20} margin={5}/>
          </div>

          <CgClose className="closebtn" onClick={closeModal}/>

            <div className="logo">
              <img src={logo} alt="" />
              <span>Zodiac Advisor</span>
            </div>

            <form action="">
              <input type="text" placeholder={translation.name} onChange= {handleName} value={name}/>
              <input type="text" placeholder={translation.email} onChange={handleEmail} value={email}/>
              <input type="password" placeholder={translation.password} onChange={handlePassword} value={password}/>
            </form>

            <div className="signin-button" onClick= {() => signUp()}>
              <span>{translation.create_account}</span>
            </div>

            <span className='terms' id='terms'>{parse(translation.sign_up_text)}</span>

            <div className="orSign"> 
              <span>{translation.or_sign_up}</span>
              <div className="row">
                <div className="f-sign" onClick={() => auth.signUpWithFacebook()}><FaFacebookF className="icon"/></div>
                <div className="g-sign" onClick={() => auth.signUpWithGoogle()}><FcGoogle className="icon"/></div>
              </div>
            </div>

            <span className="already-have" onClick={() => {
              dispatch(showSignUpModal(false));
              dispatch(showSignInModal(true)); 
            }}>{translation.already_have}</span>

        </div>
      </div>
    )
  )
}

export default SignInModal