export const es = {
    "en": "En",
    "es": "Es",
    "sign_in": "Registrarse",
    "sign_up": "Inscribirse",
    "forgot_password": "¿Olvidaste tu contraseña?",
    "enter_your_email": "Ingrese su dirección de correo electrónico para recuperar su contraseña.",
    "enter_name_surname": "Ingrese su nombre y apellido",
    "reset_password": "Restablecer contraseña",
    "write_a_message": "Escribir un mensaje...",
    "account": "Cuenta cuenta",
    "or_sign_in": "-O iniciar sesión con-",
    "or_sign_up": "-O regístrate con-",
    "sign_up_text": "Debes tener al menos 18 años para registrarte en Zodiac Advisor. Al registrarte, aceptas la <b>Política de privacidad</b> y las <b>Condiciones de servicio</b>",
    "create_account": "Crear una cuenta",
    "coin": "Moneda",
    "total_amount": "Cantidad total",
    "card_holder": "Titular de la tarjeta",
    "card_number": "Número de tarjeta",
    "expire_time": "Tiempo de caducidad",
    "cvv": "CVV",
    "cvv_text": "Los últimos tres dígitos en el reverso de la tarjeta.",
    "process_payment": "PROCESAR PAGO",
    "january": "Enero",
    "february": "Febrero",
    "march": "Marzo",
    "april": "Abril",
    "may": "Puede",
    "june": "Junio",
    "july": "Julio",
    "august": "Agosto",
    "september": "Septiembre",
    "october": "Octubre",
    "november": "Noviembre",
    "december": "Diciembre",
    "reject": "Rechazar",
    "reject_text": "Tu mensaje de excusa debe tener más de 50 caracteres.",
    "chat_call_text": "¡Alguien quiere iniciar una llamada de chat contigo!",
    "voice_call_text": "¡Alguien quiere iniciar una llamada de voz contigo!",
    "video_call_text": "¡Alguien quiere iniciar una videollamada contigo!",
    "join_meeting": "Unirse a la reunión",
    "not_available": "No estoy disponible",
    "waiting": "Espera",
    "about_zodiac_advisor": "SOBRE ZODIAC ADVISOR",
    "become_an_advisor": "CONVIÉRTETE EN ASESORA",
    "how_it_works": "CÓMO FUNCIONA",
    "advisor_terms_and_conditions": "Términos y condiciones de la asesora",
    "about_us": "Sobre Nosotras",
    "contact_us": "Contáctenos",
    "privacy_policy": "Política de privacidad",
    "terms_of_use": "Términos de Uso",
    "faq": "FAQ",
    "getting_started": "Empezando",
    "how_to_find_advisor": "Cómo encontrar una asesora",
    "we_accept": "NOSOTRAS ACEPTAMOS",
    "all_rights_reserved": "Reservados todos los derechos.",
    "get_coins": "Conseguir monedas",
    "see_more_advisors": "Ver más asesores",
    "all_advisors": "Todos los asesores",
    "save": "Ahorrar",
    "search_or_start": "Buscar o iniciar un nuevo chat",
    "online": "En línea",
    "busy": "Ocupada",
    "offline": "Desconectada",
    "start_chat": "Comenzar chat",
    "stop_meeting": "dejar de reunirse",
    "settings": "Ajustes",
    "name": "Nombre",
    "email": "Correo electrónico",
    "password": "Contraseña",
    "new_password": "Nueva Contraseña",
    "sign_out": "Desconectar",
    "chat_box": "Ventana de chat",
    "add_funds": "Añadir fondos",
    "customer_support": "Atención al cliente",
    "home": "Casa",
    "categories": "Categorías",
    "see_all": "Ver todo",
    "logout": "Cerrar sesión",
    "language": "Idioma",
    "select_language": "Elige tu idioma",
    "specialites": "Especialidades",
    "reviews": "Reseñas",
    "see_more": "Ver más",
    "about_me": "Acerca de mí",
    "about_my_services": "Acerca de mis servicios",
    "chat": "Charlar",
    "voice": "Voz",
    "voice_call": "Llamada",
    "video": "Video",
    "video_call": "Videollamada",
    "live": "Viva",
    "all": "Todas",
    "recommended": "Recomendada para ti",
    "recommended_text": "Encuentra a tus psíquicos recomendados para aprender el secreto de tu vida. Muchos psíquicos están aquí para ayudarte, son expertos en sus campos. ¡Explóralos, lee sus campos y elige uno de ellos!",
    "trending": "Tendencias",
    "trending_text": "¡Aprenda el secreto de su vida! Obtenga una lectura psíquica de uno de los psíquicos increíbles. ¡Haga su pregunta ahora! Desde su vida amorosa hasta su vida familiar, o su carta natal y nacimiento, y más...",
    "love_readings": "Lecturas de amor",
    "palm_readings": "Lecturas de la palma",
    "tarot_readings": "Lecturas de tarot",
    "psychics_readings": "Lecturas psíquicas",
    "astrology_horoscopes": "Astrología & horóscopos",
    "dream_analysis": "Análisis de los sueños",
    "clairvoyance": "Clarividencia",
    "career_readings": "Lecturas de carrera",
    "love": "Amor",
    "palm": "Palma",
    "tarot": "Tarot",
    "psychic": "Psíquicas",
    "astrology": "Astrología",
    "dream": "Sueños",
    "career": "Carrera",
    "since": "desde",
    "reading": "leyendo",
    "staff": "personal",
    "love_description": "Sabemos que el amor siempre es un tema complicado. Obtenga un pronóstico de su vida amorosa con nuestros psíquicos experimentados. Aborde su vida romántica con confianza. Encuentre el mejor adivino para usted y brinde claridad y equilibrio a su amor vida.",
    "psychic_description": "¿Estás pasando por una fase turbulenta de tu vida? ¿Tienes problemas para encontrar una solución? Zodiac Advisor está aquí para ayudarte. Lee ahora con nuestros psíquicos experimentados y supera todos tus problemas.",
    "tarot_description": " Lecturas de cartas de tarot para cada tema: amor, carrera o cualquier pregunta sin respuesta. Muchos psíquicos usan lecturas de tarot para encontrar significado y nuevas perspectivas sobre el trabajo, las relaciones, el amor y los misterios de la vida. Empiece a leer ahora con uno de nuestros Tarot experto. ¡Aprende el secreto!",
    "palm_description": "Realmente tienes los secretos de tu vida en tus manos, todo en la palma de tu mano. Nuestros asombrosos lectores de palmas analizarán tu palma y llegarán a las conclusiones más precisas sobre tu pasado, presente y futuro. Empieza a leer ¡ahora!",
    "astrology_description": "Nuestro destino está escrito en las estrellas en el momento en que nacimos. Descubre tu Natal - Carta natal. Habla hoy con el astrólogo talentoso para descubrir de qué material estelar estás hecho. Aprende profundamente tus horóscopos y signos.",
    "clairvoyance_description": "Mejora tu vida mágica con nuestros clarividentes experimentados. Siente la energía, aprende los campos de energía. Aumenta el poder de tu mente y conecta tu guía espiritual. ¡Empieza a leer hoy mismo!",
    "career_description": "¡Las lecturas de carrera te darán información y consejos para ayudar a suavizar y fortalecer tu camino profesional! Revela el mejor resultado posible para cualquier dilema de carrera. Empieza a leer ahora con nuestros psíquicos experimentados.",
    "best_advisor": "Mejor asesora de la semana",
    "description": "Descripción",
    "guarantee": "100% de garantía de satisfacción",
    "free": "3 MIN GRATIS + 60% DE DESCUENTO",
    "enjoy_reading": "DISFRUTA DE TU LECTURA",
    "use_coupon": "¡Usa el cupón ahora!",
    "how_to_find_yourself": "Cómo encontrarte a ti mismo",
    "new_advisors": "Nuevos asesores",
    "favourite_advisors": "Asesores favoritos",
    "my_favourites": "Mis favoritas",
    "not_favourites_yet": "Aún no favoritas",
    "tap_on_the_heart": "Toca el corazón en los perfiles de los asesores para guardarlos en tus favoritos.",
    "meeting_end": "¡Impresionante, tu reunión terminó!",
    "share_experience": "¿Podría tomarse un tiempo para compartir su experiencia con nosotros?",
    "submit": "Enviar",
    "maybe_later": "Quizas mas tarde",
    "from": "desde",
    "filters": "Filtros",
    "not_found": "¡Vaya! Página no encontrada.",
    "go_home": "Ir a casa",
    "more_information": "Más información",
    "apply": "Solicitar",
    "show_offline": "Mostrar sin conexión",
    "show_only": "Mostrar solo",
    "number_of_reviews": "Número de reseñas",
    "price_per_minute": "Precio por minuto",
    "over": "Sobre",
    "live_chat": "Chat en vivo",
    "any": "Ninguna",
    "reading_since": "Leyendo desde",
    "didnt_find": "¿No encontraste lo que buscabas?",
    "clear_search": "¡Borrar los filtros de búsqueda para ver asesores más relevantes!",
    "clear_filters": "Borrar filtros",
    "cookies": "Usamos cookies para que las interacciones con nuestros sitios web y servicios sean fáciles y significativas. Para obtener más información sobre las cookies que usamos o para averiguar cómo puede deshabilitar las cookies, haga clic aquí.",
    "accept": "Aceptar",
    "decline": "Rechazar",
    "select_language": "Seleccione su idioma:",
    "success_purchase": "Tu compra se completó con éxito.",
    "fail_purchase": "Hubo un problema al comprar.",
    "contact_us": "Contáctanos",
    "personal": "Personal",
    "question": "Pregunta",
    "question_placeholder": "Tus preguntas deben tener más de 20 caracteres...",
    "first_name": "Primer nombre",
    "surname": "Apellido",
    "close": "Cerca",
    "send_message": "Enviar mensaje",
    "message_success": "Tu mensaje se registró con éxito.",
    "message_fail": "Hubo un problema, inténtalo de nuevo.",
    "reset_success": "Tu contraseña cambió con éxito.",
    "reset_fail_field": "¡Los campos de contraseña no son los mismos!",
    "reset_fail_default": "Se produjo un problema desconocido al cambiar la contraseña.",
    "personal": "Personal",
    "didnt_find_advisors_with_discount": "No encontré asesores con descuento.",
    "advisors_with_discount": "Asesores con descuento",
    "wrong_password": "Contraseña incorrecta",
    "your_current_password": "Your current password",
    "for_advisors": "Para asesores",
    "already_have": "¿Tienes una cuenta?",
    "dont_have": "¿No tienes una cuenta?",
    "dont_have_coin": "No tienes suficientes monedas",
    "cancel_call": "Cancelar llamada",
    "advisor_busy": "EL ASESOR ESTÁ OCUPADO",
    "message_from_advisor": "Mensaje del asesor",
    "reject_meeting": "Rechazar reunión",
    "reject": "Rechazar",
    "do_you_know": "¿Sabes?",
    "average_meeting": "Una entrevista toma un promedio de 17 minutos. Puedes cargar monedas para que tu llamada no sea interrumpida.",
    "continue": "Continuar",
    "meeting_ended": "Genial, tu reunión ha terminado.",
    "could_you_share_experience": "¿Podría tomarse un tiempo para compartir su experiencia con nosotros?",
    "maybe_later": "Quizás más tarde",
    "total_earned": "Total ganado",
    "get_more_coins_to_continue": "Consigue más monedas para continuar chateando por video",
    "join_us": "Únete a nosotros",
    "faq": "Preguntas frecuentes"
}