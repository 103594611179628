import {
    useEffect,
    useState
} from "react";

export default function useDragElement(elementRef, elementId, options) {

    const [barrierElement, setBarrierElement] = useState(null);
    const [element, setElement] = useState(null);
    const [position, setPosition] = useState({
        x: options.x.current,
        y: 0
    });

    useEffect(() => {
        if(!element) {
            if(elementRef.current) {
                const element = document.getElementById(elementId);
                setElement(element);
            }
        }

        if(!barrierElement) {
            if(options.barrier.ref.current) {
                const barrierElement = document.getElementById(options.barrier.id);
                setBarrierElement(barrierElement);
            }
        }
    }, [elementRef, options.barrier && options.barrier.ref]);

    useEffect(() => {

        if (element) {
            element.style.left = options.x.current + "px";
            let positionX = options.x.current;
            let positionY = options.y.current;
            setPosition({
                x: positionX,
                y: positionY,
            })
            let moveElement = false;
            let deviceType = "";

            const events = {
                mouse: {
                    down: "mousedown",
                    move: "mousemove",
                    up: "mouseup"
                },
                touch: {
                    down: "touchstart",
                    move: "touchmove",
                    up: "touchend"
                }
            }

            const isTouchDevice = () => {
                try {
                    // Try to create touch event this event wont work on desktop so we can easily detect is desktop or touchable device
                    document.createEvent("TouchEvent");
                    deviceType = "touch";
                    return true;
                } catch (error) {
                    deviceType = "mouse"
                    return false;
                }
            }

            isTouchDevice();

            function downEventFunction(e) {
                e.preventDefault();
                // Initial x,y points
                positionX = !isTouchDevice() ? e.clientX : e.touches[0].clientX;
                positionY = !isTouchDevice() ? e.clientY : e.touches[0].clientY;
                // Start move
                moveElement = true;
            }

            function moveEventFunction(e) {
                if (moveElement) {
                    e.preventDefault();
                    let newX = !isTouchDevice() ? e.clientX : e.touches[0].clientX;
                    let newY = !isTouchDevice() ? e.clientY : e.touches[0].clientY;

                    if (options.y.active) {
                        element.style.top = element.offsetTop - (positionY - newY) + "px";   
                        setPosition(prev => ({
                            ...prev,
                            y: (element.offsetTop - (positionY - newY))
                        }));
                        positionY = newY;
                    }

                    if (options.x.active) {
                        if ((element.offsetLeft - (positionX - newX) >= options.x.min) && (element.offsetLeft - (positionX - newX) <= options.x.max)) {
                            if(options.barrier) {
                                if(barrierElement) {
                                    if((element.offsetLeft - (positionX - newX)) * options.barrier.path >= barrierElement.offsetLeft * options.barrier.path) {
                                        setPosition(prev => ({
                                            ...prev,
                                            x: barrierElement.offsetLeft,
                                        }));
                                        element.style.left = barrierElement.offsetLeft;
                                        positionX = newX;
                                    } else {
                                        setPosition(prev => ({
                                            ...prev,
                                            x: (element.offsetLeft - (positionX - newX))
                                        }));
                                        element.style.left = element.offsetLeft - (positionX - newX) + "px";
                                        positionX = newX;
                                    }    
                                }
                            } else {
                                element.style.left = element.offsetLeft - (positionX - newX) + "px";
                                setPosition(prev => ({
                                    ...prev,
                                    x: (element.offsetLeft - (positionX - newX))
                                }));
                                positionX = newX;
                            }
                        }
                    }
                }
            }

            function upEventFunction() {
                moveElement = false;
            }

            element.addEventListener(events[deviceType].down, downEventFunction);

            element.addEventListener(events[deviceType].move, moveEventFunction);

            element.addEventListener(events[deviceType].up, upEventFunction );
        }

        return () => {
            if(element) {
                element.removeEventListener("mousedown", () => null);
                element.removeEventListener("mousemove", () => null);
                element.removeEventListener("mouseup", () => null);
                element.removeEventListener("touchstart", () => null);
                element.removeEventListener("touchmove", () => null);
                element.removeEventListener("touchend", () => null);
            }
        }

    }, [element]);

    return position;
}