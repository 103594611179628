import { update, ref as dbRef } from 'firebase/database';
import { addDoc, collection, doc, setDoc, serverTimestamp, updateDoc, getDocs, query, where, limit, orderBy, deleteField, FieldValue, getDoc, startAfter as startAfterFirestore } from 'firebase/firestore';
import firebase from '../config/firebase';
import DateHooks from '../hooks/dateHook';
const db = firebase.db;

export default class Firestore {

  static async fetchAllReviews(advisorId, startAfter, limit_) {
    let reviews = [];
    let newStartAfter = null;
    let fetched = false;
    let q = undefined;
    try {
      if(startAfter) {
        q = query(collection(db, `users/${advisorId}/reviews`), where("point", ">=", 0), orderBy("point", "desc"), startAfterFirestore(startAfter), limit(limit_));
      } else {
        q = query(collection(db, `users/${advisorId}/reviews`), where("point", ">=", 0), orderBy("point", "desc"), limit(limit_));
      }
      const reviewsSnapshot = (await getDocs(q)).docs;
      if(reviewsSnapshot.length < limit_) {
        fetched = true;
      }
      newStartAfter = reviewsSnapshot[reviewsSnapshot.length - 1];
      reviewsSnapshot.forEach((review) => {
        reviews = [...reviews, {id: review.id, ...review.data()}];
      });
    } catch (error) {
      console.log(error);
    }
    return {reviews: reviews, startAfter: newStartAfter, fetched: fetched};
  }

  static async fetchPositiveReviews(advisorId, startAfter, limit_) {
    let reviews = [];
    let newStartAfter = null;
    let fetched = false;
    let q = undefined;
    try {
      if(startAfter) {
        q = query(collection(db, `users/${advisorId}/reviews`), where("point", ">", 2), orderBy("point", "desc"), startAfterFirestore(startAfter), limit(limit_));
      } else {
        q = query(collection(db, `users/${advisorId}/reviews`), where("point", ">", 2), orderBy("point", "desc"), limit(limit_));
      }
      const reviewsSnapshot = (await getDocs(q)).docs;
      if(reviewsSnapshot.length < limit_) {
        fetched = true;
      }
      newStartAfter = reviewsSnapshot[reviewsSnapshot.length - 1];
      reviewsSnapshot.forEach((review) => {
        reviews = [...reviews, {id: review.id, ...review.data()}];
      });
    } catch (error) {
      console.log(error);
    }
    return {reviews: reviews, startAfter: newStartAfter, fetched: fetched};
  }

  static async fetchNegativeReviews(advisorId, startAfter, limit_) {
    let reviews = [];
    let newStartAfter = null;
    let fetched = false;
    let q = undefined;
    try {
      if(startAfter) {
        q = query(collection(db, `users/${advisorId}/reviews`), where("point", "<=", 2), orderBy("point", "desc"), startAfterFirestore(startAfter), limit(limit_));
      } else {
        q = query(collection(db, `users/${advisorId}/reviews`), where("point", "<=", 2), orderBy("point", "desc"), limit(limit_));
      }
      const reviewsSnapshot = (await getDocs(q)).docs;
      if(reviewsSnapshot.length < limit_) {
        fetched = true;
      }
      newStartAfter = reviewsSnapshot[reviewsSnapshot.length - 1];
      reviewsSnapshot.forEach((review) => {
        reviews = [...reviews, {id: review.id, ...review.data()}];
      });
    } catch (error) {
      console.log(error);
    }
    return {reviews: reviews, startAfter: newStartAfter, fetched: fetched};
  }

  static async fetchNewAdvisors() {
    let newAdvisors = []
    const ref = query(collection(firebase.db, "users"), where('user_type', '==', 'advisor'), orderBy('status.is_online', 'desc'));
    await getDocs(ref).then(function(snapshot) {
      const advisors = snapshot.docs.filter(advisor => DateHooks.getDateDiffByDay(advisor.get('created_at')['seconds']) <= 365 * 5);
      advisors.forEach((advisor) =>
        newAdvisors = [...newAdvisors, {id: advisor.id, data: advisor.data()}]
      )
    });
    return newAdvisors;
  }

  // static async fetchFavouriteAdvisors(userFavourites) {
  //   let favourites = []
  //   userFavourites.forEach(async (favouriteId) => {
  //     const data = (await getDoc(doc(firebase.db, `users/${favouriteId}`))).data();
  //     favourites = [...favourites, {id: favouriteId, data: data}]
  //   });
  //   return favourites;
  // }

  static async fetchAllAdvisor() {
    var advisors = [];
    try {
      const q = query(collection(db, 'users'), where('user_type', '==', 'advisor'));
      await getDocs(q).then((advisorList) => {
        advisorList.docs.forEach(function (advisor) {
          advisors = [...advisors, { id: advisor.id, ...advisor.data() }];
        });
      });
    } catch (error) {
      console.log(error);
    }

    return advisors;
  }

  static async fetchTrendingAdvisors() {
    var advisors = [];
    try {
      const q = query(collection(db, 'users'), where('user_type', '==', 'advisor'), where('badges.trending', '==', true), limit(4));
      await getDocs(q).then((advisorList) => {
        advisorList.docs.forEach(function (advisor) {
          advisors = [...advisors, { id: advisor.id, ...advisor.data() }];
        });
      });
    } catch (error) {
      console.log(error);
    }
    
    return advisors;
  }

  static async fetchRecommendedAdvisors() {
    var advisors = [];
    try {
      const q = query(collection(db, 'users'), where('user_type', '==', 'advisor'), where('badges.recommended', '==', true), limit(4));
      await getDocs(q).then((advisorList) => {
        advisorList.docs.forEach(function (advisor) {
          advisors = [...advisors, {id: advisor.id, ...advisor.data()}];
        });
      });
    } catch (error) {
      console.log(error);
    }
    
    return advisors;
  }

  static createCustomerOnDatabase = async (dataAsMap) => {
    const { email, password, uid, name } = dataAsMap;
    const ref = doc(db, "users", uid);
    const payload = {
      preffered_language: ["en", "es"].includes(navigator.language.split('-')[0]) ? navigator.language.split('-')[0] : "en",
      profile: {
        email: email,
        password: password,
        name: name,
        uid: uid,
        //gender: 'male',
      },
      initial_promotion: {
        is_used: false,
        remaining_time: 3
      },
      notification_token: null,
      balance: 0,
      status: {
        'is_online': true,
        'busy': false,
      },
      user_type: 'customer',
    }
    await setDoc(ref, payload).then(async function () {
      // await getNotifyToken();
    });
  }

  static createReview = async (dataAsMap) => {

    const { comment, point, advisorId, customerId, customerName, meetingId } = dataAsMap;

    const customerPayload = {
      comment: comment,
      point: point,
      meeting_id: meetingId,
      advisor_id: advisorId,
      created_at: serverTimestamp(),
    }

    const advisorPayload = {
      comment: comment,
      point: point,
      meeting_id: meetingId,
      owner: customerId,
      created_at: serverTimestamp(),
      name: customerName,
    }

    try {
      const customerRef = collection(db, "users", customerId, "comments");
      await addDoc(customerRef, customerPayload).then(async (doc_) => {
        const advisorRef = doc(db, "users", advisorId, "reviews", doc_.id);
        await setDoc(advisorRef, advisorPayload);
      });
    } catch (e) {
      throw e.message;
    }
  }

  static createMeetingRequest = async (props) => {

    const { advisorId, customerId, meetingType, price } = props;
    const meetingRef = collection(db, `meetings`);

    const q = query(collection(db, 'meetings'), where('advisor_id', '==', advisorId), where('customer_id', '==', customerId))
    const meetings = (await getDocs(q))

    try {
      if(meetings.empty) {
        //ADD
        await update(dbRef(firebase.rDb, `busy_status`), {
          [advisorId]: true
        }).then(async () => {
          await addDoc(meetingRef, {
            "status": "pending",
            "created_at": serverTimestamp(),
            "need_purchase": false,
            "meeting_type": meetingType,
            "customer_id": customerId,
            "advisor_id": advisorId,
            "price": price,
            "terminated_at": null,
          }).then(async function (document) {
  
            await updateDoc(doc(db, `users/${advisorId}`), {
              "status.meeting": document.id,
            });
  
            await updateDoc(doc(db, `users/${customerId}`), {
              "status.meeting": document.id,
            });
          });
        });
      }
      else {
        const meeting = meetings.docs.at(0);
        await update(dbRef(firebase.rDb, `busy_status`), {
          [advisorId]: true
        }).then(async () => {
          await updateDoc(doc(db, `meetings/${meeting.id}`), {
            "status": "pending",
            "price": price,
            "meeting_type": meetingType,
            "terminated_at": null,
            "will_terminate": null,
            "will_automatically_terminate_in": null,
            "cancelled_by": deleteField()
          }).then(async function() {
  
            await updateDoc(doc(db, `users/${advisorId}`), {
              "status.meeting": meeting.id,
            });
  
            await updateDoc(doc(db, `users/${customerId}`), {
              "status.meeting": meeting.id,
            });
          })
        })
        
      }
    } catch (e) {
      throw e;
    }
  }
}