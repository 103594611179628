import React, { useEffect, useRef } from 'react';
import './filtertool.scss';
import { useState } from 'react';
import useTranslation from '../../localization/translations';
import useDragElement from '../../hooks/useDragElement';
import coinImg from '../../assets/ic_coin.png';

const FilterTool = (props) => {

    const translation = useTranslation();

    const {
        initialIsShowingOfflineFilter, updateIsShowingOfflineFilter,
        initialMeetingTypeFilter, updateMeetingTypeFilter,
        initialNumberOfReviewsFilter, updateNumberOfReviewsFilter,
        initialPricePerMinuteFilter, updatePricePerMinuteFilter,
        showFilter
    } = props;

    console.log(initialIsShowingOfflineFilter, initialMeetingTypeFilter, initialNumberOfReviewsFilter, initialPricePerMinuteFilter);

    const [isShowingOfflineFilter, setIsShowingOfflineFilter] = useState(initialIsShowingOfflineFilter);
    const [meetingTypeFilter, setMeetingTypeFilter] = useState(initialMeetingTypeFilter.index);
    const [numberOfReviewsFilter, setNumberOfReviewsFilter] = useState(initialNumberOfReviewsFilter.index);
    const [pricePerMinuteFilter, setPricePerMinuteFilter] = useState(initialPricePerMinuteFilter);

    const leftSliderRef = useRef('');
    const rightSliderRef = useRef('');
    const positionSliderLeft = useDragElement(leftSliderRef, "left-slider", {
        barrier: {
            id: "right-slider",
            ref: rightSliderRef,
            path: 1
        },
        x: {
            active: true,
            current: (pricePerMinuteFilter.min * (9/25)),
            min: 0,
            max: 180,
        },
        y: {
            active: false,
            current: 0,
            min: 0,
            max: 180,
        },
    });
    const positionSliderRight = useDragElement(rightSliderRef, "right-slider", {
        barrier: {
            id: "left-slider",
            ref: leftSliderRef,
            path: -1
        },
        x: {
            active: true,
            current: ((pricePerMinuteFilter.max) * (9/25)),
            min: 0,
            max: 180,
        },
        y: {
            active: false,
            current: 0,
            min: 0,
            max: 180,
        },
    });

    const filter_values = [
        {
            "title": translation.show_only,
            "elements": [
                { "text": translation.any, "value": "" },
                { "text": translation.live_chat, "value": "chat" },
                { "text": translation.voice_call, "value": "voice" },
                { "text": translation.video_call, "value": "video" }
            ],
        },
        {
            "title": translation.number_of_reviews,
            "elements": [
                { "text": `${translation.over} 0`, "value": 0 },
                { "text": `${translation.over} 10`, "value": 10 },
                { "text": `${translation.over} 100`, "value": 100 },
                { "text": `${translation.over} 500`, "value": 500 }
            ],
        },
        {
            "title": translation.price_per_minute,
            "elements": {
                "min": 0,
                "max": 500
            }
        }
    ];

    const DropDownFilter = (props) => {
        const { filter, onSelect, value } = props;
        const [visible, setVisible] = useState(false);

        return (
            <div className="dropdown">
                <div className="ui">
                    <span className="title">{filter.title}</span>
                    <div onClick={() => setVisible(prev => !prev)}>
                        <span>{filter.elements[value].text}</span>
                    </div>
                </div>

                <div className="dropdownList" style={{ padding: "10px", width: "calc(100% - 15px)", left: "0", borderRadius: "7px", background: "white", border: "1px solid var(--border-color)", position: "absolute", top: "calc(100% + 5px)", zIndex: "20", display: visible ? "flex" : "none", flexDirection: "column" }}>
                    {
                        filter.elements.map(function (el, index) {
                            return (
                                <div className="dropdownItem" onClick={() => onSelect(index)} style={{ width: "100%", height: "45px", background: index === value ? "rgb(0,0,0,.05)" : "white", borderRadius: "5px", padding: "10px", display: "flex", alignItems: "center" }}>
                                    <span style={{ fontSize: "18px", fontWeight: index === value ? "bold" : "normal" }}>{el.text}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    // Updates filters in order to refresh data in reading screen.
    function updateFilters() {
        updateIsShowingOfflineFilter(isShowingOfflineFilter);
        updateMeetingTypeFilter(
            {
                "index": meetingTypeFilter,
                "value": filter_values.at(0)["elements"].at(meetingTypeFilter).value
            }
        );
        updateNumberOfReviewsFilter(
            {
                "index": numberOfReviewsFilter,
                "value": filter_values.at(1)["elements"].at(numberOfReviewsFilter).value
            }
        );
        updatePricePerMinuteFilter(pricePerMinuteFilter);
    }

    useEffect(() => {
        setPricePerMinuteFilter(prev => ({...prev, min: ((positionSliderLeft.x) * (25/9)).toFixed(0)}));
    }, [positionSliderLeft]);

    useEffect(() => {
        setPricePerMinuteFilter(prev => ({...prev, max: ((positionSliderRight.x) * (25/9)).toFixed(0)}));
    }, [positionSliderRight]);

    return (
        <div className="filtertool noselect" style={{ display: showFilter ? "flex" : "none" }}>

            <div style={{position: "absolute", top: "450px", left: "400px"}}></div>
            
            <DropDownFilter filter={filter_values[0]} onSelect={setMeetingTypeFilter} value={meetingTypeFilter} />
            <DropDownFilter filter={filter_values[1]} onSelect={setNumberOfReviewsFilter} value={numberOfReviewsFilter} />

            <div className="range-offline">
                <div className="rangefilter" id="rangefilter">
                    <span className="title">{translation.price_per_minute}</span>
                    <div className="slider">
                        <div className="rangeslider">
                            <div className="min" id="left-slider" ref={leftSliderRef}></div>
                            <div className="max" id="right-slider" ref={rightSliderRef}></div>
                            <div className="bg" style={{height: "100%", position: "absolute", top: "0", left: `${((pricePerMinuteFilter.min) * (9/25) + 10).toFixed(0)}px`, width: `${((pricePerMinuteFilter.max - pricePerMinuteFilter.min) * (9/25)).toFixed(0)}px`, background: "black"}}></div>
                        </div>
                        <div className="price">
                            <span className="min">{filter_values[2].elements.min}</span>
                            <div className="current">
                                <div className="row">
                                    <img src={coinImg} alt="" className="coin-image"/>
                                    <span className="current-min-price">{pricePerMinuteFilter.min}</span>
                                </div>
                                <div className="brace">-</div>
                                <div className="row">
                                    <img src={coinImg} alt="" className="coin-image"/>
                                    <span className="current-max-price">{pricePerMinuteFilter.max}</span>
                                </div>
                            </div>
                            <span className="max">{filter_values[2].elements.max}</span>
                        </div>
                    </div>
                </div>

                <div className="online-status">
                    <div className="switch-button" onClick={() => setIsShowingOfflineFilter(!isShowingOfflineFilter)} style={{ paddingLeft: isShowingOfflineFilter ? "32px" : "5px", background: isShowingOfflineFilter ? "var(--green-color)" : "rgb(250,250,250)", boxShadow: isShowingOfflineFilter ? "inset 0px 0px 5px 5px #0002" : "inset 0px 0px 5px 5px #0002" }}>
                        <div></div>
                    </div>
                    <span>{translation.show_offline}</span>
                </div>
            </div>

            <div className="apply-button" onClick={updateFilters}>
                <div className="button">
                    {translation.apply}
                </div>
            </div>
        </div>
    )
}

export default FilterTool