import React from 'react';
import './seemore.scss';
import seeMoreImg from '../../../assets/ic_seemore.svg';
import useTranslation from '../../../localization/translations';

const SeeMore = (props) => {

  const translation = useTranslation();

  return (
    <div className= "seemore-button noselect" onClick= {props.onClick}>
        <div className="container">
            <img src={seeMoreImg} alt="" />
            <span>{translation.see_more_advisors}</span>
        </div>
    </div>
  )
}

export default SeeMore