import firebase from "../config/firebase";
import { httpsCallable } from "firebase/functions";
const cors = require('cors')({ origin: true });

export default class Payment {

    static payWithCreditCard = httpsCallable(firebase.fbFunctions, 'pay');

    static pay = (
        productId,
        cardOwnerName,
        cardNumber,
        cardMonth,
        cardYear,
        cardCvc,
        cardOwnerPhone
    ) => {
        return Payment.payWithCreditCard({
            productId: productId,
            cardOwnerName: cardOwnerName,
            cardNumber: cardNumber,
            cardMonth: cardMonth,
            cardYear: cardYear,
            cardCvc: cardCvc,
            cardOwnerPhone: cardOwnerPhone
        })
    }

}