import AgoraRTC from "agora-rtc-sdk-ng"
import AgoraRTM from "agora-rtm-sdk";
import Agora from "../functions/agora";
import { useEffect, useState } from "react";

export default function useAgora(globalUser, meeting, streamOptions) {

    AgoraRTC.setLogLevel(2);
    const uid = Math.floor(Math.random() * 2023)

    const [options, setOptions] = useState({
        appId: process.env.REACT_APP_AGORA_APP_ID,
        channel: meeting && meeting.data.advisor_id,
        rtcToken: null,
        rtmToken: null,
        rtcUid: uid,
        rtmUid: String(uid)
    })

    const [channelParameters, setChannelParameters] = useState({
        localVideoTrack: null,
        localAudioTrack: null,
        remoteUsers: {}
    })

    const cameraVideoTrackConfig = {
        encoderConfig: {
            width: 1920,
            // Specify a value range and an ideal value
            height: { ideal: 1080, min: 1080, max: 1080 },
            frameRate: 60,
            bitrateMin: 1000, bitrateMax: 5000,
        },
        optimizationMode: "detail"
    }

    const rtcConfig = {
        mode: "rtc",
        codec: "vp8"
    }

    let rtcClient = AgoraRTC.createClient(rtcConfig);
    let rtmClient = AgoraRTM.createInstance(options.appId)
    let channel = rtmClient.createChannel(options.channel)

    useEffect(() => {
        rtcClient.on("connection-state-change", handleConnectionStateChange)
        rtcClient.on("user-published", handleUserPublished)
        //rtcClient.on("user-joined", handleUserJoined)
        rtcClient.on("user-left", handleUserLeft)
        rtcClient.on("token-privilege-will-expire", handleTokenPrivilegeWillExpire)
        rtmClient.on("TokenPrivilegeWillExpire", handleTokenPrivilegeWillExpireRtm)
        // channel.on("MemberJoined", handleMemberJoined)
        // channel.on("MemberLeft", handleMemberLeft)
        
        return () => {
            rtcClient.removeAllListeners()
            rtmClient.removeAllListeners()
            channel.removeAllListeners()
            leaveRoom()
        }
    }, [])

    useEffect(() => {
        if(channelParameters.localAudioTrack) {
            channelParameters.localAudioTrack.setMuted(streamOptions.isLocalMicrophoneMuted)
        }
    }, [streamOptions.isLocalMicrophoneMuted])

    useEffect(() => {
        const updateVideoTrack = async () => {
            if(channelParameters.localVideoTrack) {
                if(streamOptions.isLocalVideoStopped) {
                    channelParameters.localVideoTrack.stop()
                    channelParameters.localVideoTrack.close()
                    rtcClient.unpublish()
                } else {
                    const localVideoTrack = await AgoraRTC.createCameraVideoTrack()
                    const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
                    const localPlayerContainer = document.getElementById("user-stream")
                    localVideoTrack.play(localPlayerContainer)
                    localAudioTrack.play()

                    setChannelParameters(prev => ({
                        ...prev,
                        localAudioTrack: localAudioTrack,
                        localVideoTrack: localVideoTrack
                    }))
                }
            }
        }
        
       updateVideoTrack()
    }, [streamOptions.isLocalVideoStopped]);

    useEffect(() => {
        if(Object.keys(channelParameters.remoteUsers).length > 0) {
            let remoteUserUidList = []
            Object.keys(channelParameters.remoteUsers).forEach(remoteUserUid => {
                remoteUserUidList = [
                    ...remoteUserUidList,
                    remoteUserUid
                ]
            })
            console.warn("REMOTE USERS LIST CHANGED: ", remoteUserUidList)
        }
    }, [channelParameters.remoteUsers])

    const handleConnectionStateChange = async (connectionState) => {
        console.warn("CONNECTION STATE CHANGED: ", connectionState)
    }

    const initRtc = async () => {
        await rtcClient.enableDualStream()
        // Get token
        const rtcToken = (await Agora.generateRtcToken(options.channel, options.rtcUid)).data
        // Rtc client join
        console.log(options, rtcToken)
        await rtcClient.join(options.appId, options.channel, rtcToken, options.rtcUid)
        await createAndPublishTracks()

        setOptions(prev => ({
            ...prev,
            rtcToken: rtcToken
        }))
    }

    const initRtm = async () => {
        const rtmToken = (await Agora.generateRtmToken(options.rtmUid)).data
        await rtmClient.login({uid: options.rtmUid, token: rtmToken})
        await channel.join().then(() => console.log("JOINED TO THE CHANNEL: ", options.channel))
        
        setOptions(prev => ({
            ...prev,
            rtmToken: rtmToken
        }))
    }

        let createAndPublishTracks = async () => {
            const meetingType = meeting.data.meeting_type
            const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
            let localVideoTrack = null

            if(meetingType === "video") {
                localVideoTrack = await AgoraRTC.createCameraVideoTrack(cameraVideoTrackConfig)
                await rtcClient.publish([localAudioTrack, localVideoTrack])
                const localPlayerContainer = document.getElementById("user-stream")
                localVideoTrack.play(localPlayerContainer)
                localAudioTrack.play()
            } else {
                await rtcClient.publish(localAudioTrack)
                localAudioTrack.play()
            }

            setChannelParameters(prev => ({
                ...prev,
                localAudioTrack: localAudioTrack,
                localVideoTrack: localVideoTrack
            }))
    }
    
    let handleUserPublished = async (user, mediaType) => {
    await rtcClient.subscribe(user, mediaType);
        await rtcClient.setRemoteVideoStreamType(user.uid, 0)
        await rtcClient.setStreamFallbackOption(user.uid, 0)
        console.warn("USER PUBLISHED: ", {[user.uid]: mediaType});

        if(meeting && [meeting.data.customer_id, meeting.data.advisor_id].includes(globalUser.uid)) {
            user.audioTrack.play()

            if(meeting.data.meeting_type === "video") {
                const remotePlayerContainer = document.getElementById("contact-stream")
                // Play the remote video track.
                user.videoTrack.play(remotePlayerContainer, cameraVideoTrackConfig);
            }
        }

        setChannelParameters(prev => ({
            ...prev,
            remoteUsers: {
                ...prev.remoteUsers,
                [user.uid]: {
                    audioTrack: user.audioTrack,
                    videoTrack: user.videoTrack
                }
            }
        }))
    }
    
    let handleTokenPrivilegeWillExpireRtm = async () => {
        const rtmToken = (await Agora.generateRtmToken(options.rtmUid)).data
        await rtmClient.renewToken(rtmToken);
        setOptions(prev => ({
            ...prev,
            rtmToken: rtmToken
        }))
    }

    let handleTokenPrivilegeWillExpire = async () => {
        const rtcToken = (await Agora.generateRtcToken(options.channel, options.rtcUid)).data
        await rtcClient.renewToken(rtcToken);
        setOptions(prev => ({
            ...prev,
            rtcToken: rtcToken
        }))
    }

    let handleUserLeft = async (user, reason) => {
        console.warn("USER LEFT: ", {[user.uid]: reason})
        //document.getElementById("contact-stream").remove()
        const remoteUsers = channelParameters.remoteUsers
        delete remoteUsers[user.uid]
        setChannelParameters(prev => ({
            ...prev,
            remoteUsers: remoteUsers
        }))
    }

    let handleUserJoined = async (user, mediaType) => {
        await rtcClient.subscribe(user, mediaType)
        console.warn("USER JOINED: ", user)
        setChannelParameters(prev => ({
            ...prev,
            remoteUsers: {
                [user.uid]: {
                    audioTrack: user.audioTrack,
                    videoTrack: user.videoTrack
                }
            }
        }))
    }

    let handleMemberJoined = async (memberId) => {
        const remoteUsers = {
            ...channelParameters.remoteUsers,
            memberId
        }
        console.log("new member joined:", memberId)
        getChannelMembers()
        setChannelParameters(prev => ({
            ...prev, 
            remoteUsers: remoteUsers
        }))
    }
    
    let handleMemberLeft = (memberId) => {
        const remoteUsers = delete channelParameters.remoteUsers[memberId]
        console.log("member left:", memberId)
        setChannelParameters(prev => ({
            ...prev, 
            remoteUsers: remoteUsers
        }))
    }

    let leaveRtmChannel = async () => {
        await channel.leave()
        await rtmClient.logout()
    }

    let getChannelMembers = async () => {
        let members = await channel.getMembers()
        console.log("MEMBERS: ", members);

    
        for (let i = 0; members.length > i; i++) {
            console.log("MEMBER: ", members[i]);
        }
    }

    let enterRoom = async () => {
        try {
            // Init rtc
            await initRtc()
            // Init rtm
            await initRtm()
            console.log("publish success!");
        } catch (error) {
            console.log(error);
        }
    }
    
    let leaveRoom = async () => {
       try {
            channelParameters.localAudioTrack.stop()
            channelParameters.localAudioTrack.close()
            if(channelParameters.localVideoTrack) {
                channelParameters.localVideoTrack.stop()
                channelParameters.localVideoTrack.close()
                await rtcClient.unpublish([channelParameters.localAudioTrack, channelParameters.localVideoTrack])
            } else {
                await rtcClient.unpublish(channelParameters.localAudioTrack)
            }
            await rtcClient.leave().then(() => console.log("You left the channel"))
            //await leaveRtmChannel()
       } catch (error) {
        console.error("Error accured when trying to leave: ", error)
       }
    }

    return { enterRoom, leaveRoom }
}