import { addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, startAfter, Timestamp, updateDoc } from 'firebase/firestore';
import React, { Component, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GrAttachment } from 'react-icons/gr';
import { IoIosSend } from 'react-icons/io';
import { CgClose } from 'react-icons/cg';
import { FaUser, FaInfoCircle } from 'react-icons/fa';
import firebase from '../../../config/firebase';
import Storage from '../../../functions/storage';
import DateHooks from '../../../hooks/dateHook';
import useTranslation from '../../../localization/translations';
import './chat.scss';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { PulseLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';
import ElementControl from '../../../functions/elementcontrol';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import OfferModal from '../../../components/modal/offermodal/offermodal';
import CircularProgress from '@mui/material/CircularProgress';
import useImageResizer from '../../../hooks/useImageResizer';
import { update, ref as dbRef } from 'firebase/database';

const db = firebase.db;
const auth = firebase.auth;

const fetchMessageAmount = 10;

const Chat = () => {

    const { meeting, user, related_users } = useSelector(state => state.site);
    const imageResizer = useImageResizer();
    const navigate = useNavigate();
    const translation = useTranslation();
    const [image, setImage] = useState({
        file: null,
        load_type: "quick",
    });
    const [currentUser, setCurrentUser] = useState(null);
    const [contact, setContact] = useState(null);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [pastMessagesFetchState, setPastMessagesFetchState] = useState({
        fetching: false,
        fetchedAll: false,
        startAfter: null,
    });
    const [prefferedLanguage, setPrefferedLanguage] = useState(navigator.language.substring(0, 2));
    const [selectedImageUrl, setSelectedImageUrl] = useState('');
    const [imageLoadingState, setImageLoadingState] = useState({
        id: "",
        state: "",
    });
    const [circleSpecialOfferStatus, setCircleSpecialOfferStatus] = useState({
        id: "circle_1_60",
        open: false,
        is_used: false,
        duration: null,
        remainingTime: null,
        visible_when: null,
        price: null,
        coin: null,
        fetched: false,
    });
    const [popupSpecialOfferStatus, setPopupSpecialOfferStatus] = useState({
        id: "popup_1_180",
        open: false,
        is_used: false,
        duration: null,
        remainingTime: null,
        visible_when: null,
        price: null,
        coin: null,
        fetched: false,
    });
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const [leaving, setLeaving] = useState(false);
    const { width } = useWindowDimensions();
    const messagesRef = useRef('');
    const welcomeMessageRef = useRef('');

    async function updateIsUsedFieldOfferForFirebase(offer_id) {
        await updateDoc(doc(firebase.db, `meetings/${meeting.id}`), {
            [`offers.${offer_id}.is_used`]: true
        });
    }

    useEffect(() => {
        if(meeting) {
            if(user && user.children) {
                const user_ = [...related_users, user].filter(user => user.uid === meeting.data.advisor_id)[0]
                setCurrentUser(user_);
            } else {
                setCurrentUser(user);
            }
        }
    }, [user, meeting]);

    useEffect(() => {
        if(welcomeMessageRef.current) {
            const welcomeMessage = document.getElementById('welcome-message');
            if(welcomeMessage) {
                setTimeout(() => {
                    welcomeMessage.style.display = "flex";
                }, 1000 * 1);
            }
        }
    }, [welcomeMessageRef.current]);

    useEffect(() => {
        if (meeting) {
            const circleOffer = meeting.data.offers[circleSpecialOfferStatus.id];
            const popupOffer = meeting.data.offers[popupSpecialOfferStatus.id];
            setCircleSpecialOfferStatus(prev => ({ ...prev, fetched: true, is_used: circleOffer.is_used, duration: circleOffer.data.duration, price: circleOffer.data.price, coin: circleOffer.data.coin, visible_when: circleOffer.data.visible_when }));
            setPopupSpecialOfferStatus(prev => ({ ...prev, fetched: true, is_used: popupOffer.is_used, duration: popupOffer.data.duration, price: popupOffer.data.price, coin: popupOffer.data.coin, visible_when: popupOffer.data.visible_when }));
        }
    }, [meeting && meeting.data.offers]);

    useEffect(() => {
        if (meeting && circleSpecialOfferStatus.fetched && popupSpecialOfferStatus.fetched) {

            let circleSpecialOfferTimer = null;
            let popupSpecialOfferTimer = null;

            const will_automatically_terminate_in = meeting.data.will_automatically_terminate_in;

            if (will_automatically_terminate_in <= circleSpecialOfferStatus.visible_when.max && will_automatically_terminate_in >= circleSpecialOfferStatus.visible_when.min && !circleSpecialOfferStatus.is_used) {
                updateIsUsedFieldOfferForFirebase(circleSpecialOfferStatus.id).then(() => {
                    setCircleSpecialOfferStatus(prev => ({ ...prev, open: true, is_used: true, remainingTime: prev.duration }));
                    circleSpecialOfferTimer = setInterval(() => {
                        setCircleSpecialOfferStatus((prev) => {
                            if (prev.remainingTime === 1) {
                                clearInterval(circleSpecialOfferTimer);
                                const circleOffer = document.getElementById('circle-offer');
                                if (circleOffer) {
                                    circleOffer.style.scale = 0;
                                }
                                setTimeout(() => {
                                    setCircleSpecialOfferStatus(prev => ({ ...prev, open: false }));
                                }, 200);
                            }
                            return ({ ...prev, remainingTime: prev.remainingTime - 1 });
                        });
                    }, 1000 * 1);
                })
            }

            if (will_automatically_terminate_in <= popupSpecialOfferStatus.visible_when.max && will_automatically_terminate_in >= popupSpecialOfferStatus.visible_when.min && !popupSpecialOfferStatus.is_used) {
                updateIsUsedFieldOfferForFirebase(popupSpecialOfferStatus.id).then(() => {
                    setPopupSpecialOfferStatus(prev => ({ ...prev, open: true, is_used: true, remainingTime: prev.duration }));
                    popupSpecialOfferTimer = setInterval(() => {
                        setPopupSpecialOfferStatus(prev => {
                            if (prev.remainingTime === 1) {
                                clearInterval(popupSpecialOfferTimer);
                                const offerModal = document.getElementById('offer-modal');
                                if (offerModal) {
                                    offerModal.style.bottom = "-600px";
                                    setTimeout(() => {
                                        setPopupSpecialOfferStatus(prev => ({ ...prev, open: false }));
                                    }, 200);
                                }
                            }
                            return ({ ...prev, remainingTime: prev.remainingTime - 1 });
                        });
                    }, 1000 * 1);
                })
            }

            return () => {
                if (circleSpecialOfferTimer) {
                    clearInterval(circleSpecialOfferTimer);
                }
                if (popupSpecialOfferTimer) {
                    clearInterval(popupSpecialOfferTimer);
                }
            }
        }
    }, [meeting && meeting.data.will_automatically_terminate_in, popupSpecialOfferStatus.fetched, circleSpecialOfferStatus.fetched]);

    useEffect(() => {
        if (!meeting) {
            navigate('/');
        }
        ElementControl.removeFixedElements();
        return () => ElementControl.addFixedElements();
    }, []);

    useEffect(() => {

        let keyboardSubscribe = null;

        if ('virtualKeyboard' in navigator) {
            // The VirtualKeyboard API is supported!

            navigator.virtualKeyboard.overlaysContent = true;
            navigator.virtualKeyboard.show();

            keyboardSubscribe = navigator.virtualKeyboard.addEventListener('geometrychange', (event) => {
                const { height } = event.target.boundingRect;
                setKeyboardHeight(height);
            });
        }

        return () => {
            if (keyboardSubscribe) {
                keyboardSubscribe();
            }
        }
    });

    useEffect(() => {
        if (keyboardHeight) {
            const chat = document.getElementById('chat');
            if (chat) {
                chat.style.bottom = `${keyboardHeight}px`;
                chat.style.height = `calc(100% - ${keyboardHeight}px)`;
            }
        } else {
            const chat = document.getElementById('chat');
            if (chat) {
                chat.style.bottom = 0;
                chat.style.height = '100%';
            }
        }
    }, [keyboardHeight]);

    useEffect(() => {
        let contactSubscribe = null;
        let messagesSubscribe = null;

        if (meeting) {

            async function fetchContact() {
                const contactId = [meeting.data.customer_id, meeting.data.advisor_id].filter(id => id !== firebase.auth.currentUser.uid)[0];
                contactSubscribe = onSnapshot(doc(db, `users/${contactId}`), function (contact) {
                    setContact(contact.data());
                    console.log('-Contact fetched-', contact.data());
                });
            }

            fetchContact();
            // When the meeting id changed, subscribes to its messages,
            // and marks the meeting contact as selected.
            console.log("Listening to meeting messages: ", meeting.id);
            const messagesRef = query(collection(firebase.db, `meetings/${meeting.id}/messages`), orderBy("created_at", "desc"), limit(fetchMessageAmount));
            let isFirst = true;
            messagesSubscribe = onSnapshot(messagesRef, (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    console.log("MESSAGE:", change.doc.get('text'));
                    console.log("Messages changed: ", change.doc.id, " - ", change.type);
                    if (change.type === "added") {
                        if (change.doc.data().id === firebase.auth.currentUser.uid) {
                            if (!change.doc.get('image')) {
                                setMessages((prev) => [...prev, { 'id': change.doc.id, 'data': change.doc.data() }]);
                            } else {
                                if (isFirst) {
                                    setMessages((prev) => [...prev, { 'id': change.doc.id, 'data': change.doc.data() }]);
                                }
                            }
                        } else if (!change.doc.get("text") || change.doc.get('translated')) {
                            setMessages((prev) => [...prev, { 'id': change.doc.id, 'data': change.doc.data() }]);
                        }
                    }
                    if (change.type === "modified") {
                        if (change.doc.data().id !== firebase.auth.currentUser.uid) {
                            if (change.doc.get('translated')) {
                                setMessages((prev) => [...prev, { 'id': change.doc.id, 'data': change.doc.data() }]);
                            }
                        }
                    }
                });
                if(isFirst) {
                    if(snapshot.docs.length >= fetchMessageAmount) {
                        setPastMessagesFetchState(prev => ({
                            ...prev, 
                            fetching: true,
                            startAfter: snapshot.docs[snapshot.docs.length - 1]
                        }));
                    } else {
                        setPastMessagesFetchState(prev => ({
                            ...prev,
                            fetchedAll: true,
                        }))
                    }
                }
                isFirst = false;
            });
        }

        return () => {
            setMessages([]);

            if (messagesSubscribe) {
                messagesSubscribe();
            }

            if (contactSubscribe) {
                contactSubscribe();
            }
        }
    }, [meeting.id]);

    useEffect(() => {
        if(pastMessagesFetchState.fetching && pastMessagesFetchState.startAfter && !pastMessagesFetchState.fetchedAll) {
            const q = query(collection(db, `meetings/${meeting.id}/messages`), orderBy("created_at", "desc"), startAfter(pastMessagesFetchState.startAfter), limit(fetchMessageAmount));
            getDocs(q).then(pastMessagesSnapshot => {
                if(pastMessagesSnapshot.empty) {
                    setPastMessagesFetchState({
                        fetchedAll: true,
                        fetching: false,
                    });
                }
                pastMessagesSnapshot.docs.forEach(pastMessage => {
                    console.warn(pastMessage);
                    setMessages(prev => [
                        ...prev,
                        {id: pastMessage.id, data: pastMessage.data()}
                    ]);
                });
                if(pastMessagesSnapshot.docs.length < fetchMessageAmount) {
                    setPastMessagesFetchState({
                        fetchedAll: true,
                        fetching: false,
                    });
                } else {
                    setPastMessagesFetchState(prev => ({
                        ...prev,
                        fetching: false,
                        startAfter: pastMessagesSnapshot.docs[pastMessagesSnapshot.docs.length - 1]
                    }));
                }
            })
        }
    }, [pastMessagesFetchState.fetching, pastMessagesFetchState.startAfter]);

    const translatedLanguages = {
        en: "English",
        es: "Espanol",
        de: "Deutsch",
        fr: "Français"
    }

    const onFileChange = event => setImage(prev => ({...prev, file: event.target.files[0]}));
    const onInputClick = event => event.target.value = '';
    const handleMessage = e => setMessage(e.target.value);

    function showTime(dt) {
        const getDateDiffByDayFromSec = (t2) => {
            const t1 = new Date().getTime() / 1000;
            return Math.abs(Math.floor((t1 - t2) / (60 * 60 * 24)));
        };

        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(len, chr);
        const diffByDay = Math.abs(getDateDiffByDayFromSec(dt.getTime() / 1000));

        if (diffByDay < 7) {
            const days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday"
            ];
            switch (diffByDay) {
                case 0:
                    return `${padL(dt.getHours())}.${padL(dt.getMinutes())}`;
                case 1:
                    return "Yesterday";
                default:
                    const today = dt.getDay();
                    if (today < diffByDay) {
                        return days[days.length - Math.abs(today - diffByDay)];
                    } else {
                        return days[today - diffByDay];
                    }
            }
        } else if (diffByDay < 365) {
            const months = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];
            return `${months[dt.getMonth()].substring(0, 3)} ${dt.getDate()}`;
        } else {
            return `${padL(dt.getMonth() + 1)}.${padL(dt.getDate())}.${dt.getFullYear()}`;
        }
    }

    function strip(str, remove) {
        while (str.length > 0 && remove.indexOf(str.charAt(0)) !== -1) {
            str = str.substr(1);
        }
        while (str.length > 0 && remove.indexOf(str.charAt(str.length - 1)) !== -1) {
            str = str.substr(0, str.length - 1);
        }
        return str;
    }

    async function sendMessageToChat() {
        if (!meeting) {
            return;
        }
        try {
            setMessage('');
            setImage(prev => ({...prev, file: ""}));
            const customerId = meeting.data.customer_id;
            const advisorId = meeting.data.advisor_id;
            const message_ = message ?? strip(message, ' ');
            const date = Timestamp.now();
            let imageURL = null;

            if (!image.file && !message_) {
                return;
            }
            if (image.file) {
                const imageURL_ = URL.createObjectURL(image.file);
                console.log(imageURL_);
                setMessages(prev => [...prev, {
                    id: `image${messages.length}`,
                    data: {
                        id: auth.currentUser.uid,
                        image: imageURL_,
                        text: message_,
                        created_at: date,
                    }
                }]);
                setImageLoadingState({
                    id: `image${messages.length}`,
                    state: "loading"
                });
                const path = `/chat/${meeting.id}`;
                
                if(image.load_type === "original") {
                    imageURL = await Storage.uploadImage(path, image.file);
                } 
                else {
                    const resizedImage = await imageResizer(image.file);
                    imageURL = await Storage.uploadImage(path, resizedImage);
                }
            }

            await addDoc(collection(db, `meetings/${meeting.id}/messages`), {
                id: auth.currentUser.uid,
                image: imageURL,
                text: message_,
                created_at: date,
            }).then(() => setImageLoadingState({
                id: `image${messages.length}`,
                state: 'success',
            }));

            if (message) {
                await updateDoc(doc(db, `users/${customerId}/chat/${advisorId}`), {
                    'last_message': message,
                    'last_message_date': date,
                });
                await updateDoc(doc(db, `users/${advisorId}/chat/${customerId}`), {
                    'last_message': message,
                    'last_message_date': date,
                });
            }
        } catch (e) {
            if (image) {
                setImageLoadingState({
                    id: `image${messages.length}`,
                    state: "failed",
                });
            }
            alert(e.message);
        }
    }

    async function terminateMeeting() {
        setLeaving(true);
        try {
            await updateDoc(doc(db, `meetings/${meeting.id}`), {
                status: "terminated",
            });
            await updateDoc(doc(db, `users/${meeting.data.advisor_id}`), {
                'status.meeting': null,
            });

            await updateDoc(doc(db, `users/${meeting.data.customer_id}`), {
                'initial_promotion.is_used': true,
                'status.meeting': null,
            });

            await update(dbRef(firebase.rDb, `busy_status`), {
                [meeting.data.advisor_id]: false
            })
            
            const advisorId = meeting.data.advisor_id;
            const advisorData = (await getDoc(doc(firebase.db, `users/${advisorId}`))).data();
            await updateDoc(doc(firebase.db, `users/${meeting.data.customer_id}`), {
                review_needed: {
                    meeting_id: meeting.id,
                    advisor_id: advisorId,
                    advisor_name: advisorData.profile.name,
                    advisor_image: advisorData.profile.pic_url ? advisorData.profile.pic_url : null
                }
            });
            setLeaving(false);
        } catch (e) {
            console.log(e);
            alert(e);
            setLeaving(false);
        }
    }

    return (
        (user && currentUser && meeting && contact && popupSpecialOfferStatus.fetched && circleSpecialOfferStatus.fetched) ? (
            <div className="chat" id="chat">

                {
                    currentUser.user_type === "customer" && (
                        <OfferModal state={popupSpecialOfferStatus} setState={setPopupSpecialOfferStatus} />
                    )
                }

                <div className="modal-container" id="question-modal" onClick={() => document.getElementById("question-modal").style.display = "none"}>
                    <div className={`${width > 500 ? "modal-anim question-modal-pc" : "modal-anim-mobile question-modal-mobile"} question-modal`}>
                        {
                            leaving ? (
                                <PulseLoader color="var(--theme-color)" size="15px" />
                            ) : (
                                <>
                                    <div className="button yes" onClick={(e) => {
                                        e.stopPropagation();
                                        terminateMeeting();
                                    }}><span>Leave</span></div>
                                    <div className="button no" onClick={() => document.getElementById("question-modal").style.display = "none"}><span>Stay</span></div>
                                </>
                            )
                        }
                    </div>
                </div>

                {
                    selectedImageUrl && (
                        <div className="image-fullscreen">
                            <img src={selectedImageUrl} alt="" />
                            <CgClose className="close-icon" onClick={() => setSelectedImageUrl('')} />
                        </div>
                    )
                }

                {/* CONTACT */}
                {
                    <div className="contact noselect">
                        <div className="contact-img">
                            {contact.profile.pic_url ? <img src={contact.profile.pic_url} alt="" /> : <FaUser className="contact-icon" />}
                            <div className="status-color"><div style={{ background: contact.status.is_online ? "var(--green-color)" : "var(--red-color)" }}></div></div>
                        </div>

                        <div className="col">
                            <span className="contact-name">{contact.profile.name}</span>
                            <span className="contact-status">{contact.status.is_online ? translation.online : translation.offline}</span>
                        </div>

                        <select className="preffered-lang" onChange={(e) => setPrefferedLanguage(e.target.value)}>
                            {
                                Object.entries(translatedLanguages).map(([key, value], index) => <option id={index} value={key}>{value}</option>)
                            }
                        </select>
                    </div>
                }

                {/* MESSAGES */}
                <div ref={messagesRef} className="messages" id="messages"
                 style={{paddingBottom: user && user.children ? "50px" : "15px"}}
                 onScroll={() => {
                    if(!pastMessagesFetchState.fetchedAll) {
                        const messagesWrapper = document.getElementById("messages");
                        if(Math.abs(messagesWrapper.scrollTop) >= (messagesWrapper.scrollHeight - messagesWrapper.clientHeight - 100)) {
                            setPastMessagesFetchState(prev => ({
                                ...prev,
                                fetching: true
                            }));
                        }
                    }
                }}>
                    {
                        user && user.children && user.uid !== currentUser.uid && (
                            <div className="using">
                                <FaInfoCircle className="icon">
                                    <div className="info">
                                        <span>You are using this account</span>
                                    </div>
                                </FaInfoCircle>
                                <img src={currentUser.profile.pic_url} alt="" />
                                <div className="col">
                                    <span className="name">{currentUser.profile.name}</span>
                                    <span className="skill">{currentUser.profile.skills.join(', ')}</span>
                                </div>
                            </div>
                        )
                    }
                    {
                        currentUser.user_type === "customer" && (
                            <div className="special-offer noselect" id="circle-offer" style={{ display: circleSpecialOfferStatus.open ? "flex" : "none" }}>
                                <div className="linear"></div>
                                <CountdownCircleTimer
                                    isPlaying={circleSpecialOfferStatus.open}
                                    duration={circleSpecialOfferStatus.duration}
                                    initialRemainingTime={circleSpecialOfferStatus.remainingTime}
                                    colors={['#548900', '#F7B801', '#A30000', '#C60000']}
                                    colorsTime={[7, 5, 2, 0]}
                                    size="112"
                                    strokeWidth={2}
                                    trailColor="rgba(0,0,0,.2)"
                                >
                                    {({ }) => (
                                        <div className="offer">
                                            <span className="coin">{circleSpecialOfferStatus.coin}</span>
                                            <img className="coin-img" src={require('../../../assets/ic_coin-in-bowl.png')} alt="" />
                                            <span className="price">{`SAR ${circleSpecialOfferStatus.price}`}</span>
                                        </div>
                                    )}
                                </CountdownCircleTimer>
                            </div>
                        )
                    }
                    <div className="stop-button noselect" style={{ display: meeting ? "flex" : "none" }} onClick={() => document.getElementById("question-modal").style.display = "flex"}>{translation.stop_meeting}</div>
                    <div style={{ width: "100%", flex: "1", background: "rgba(255, 255, 255, 0)" }}></div>
                    {
                        messages.length > 0 && messages.sort((a,b) => b.data.created_at.seconds - a.data.created_at.seconds).map(function (message_, index) {

                            const id = message_.id;
                            const data = message_.data;
                            const image = data.image;
                            const isOwner = data.id === firebase.auth.currentUser.uid;
                            const message = (data.text && data.text.length > 0) && (isOwner ? data.text : data.translated[prefferedLanguage]);
                            const date = DateHooks.getDateFromSec(data.created_at.seconds);
                            const dateString = DateHooks.getHMByStringFromSec(data.created_at.seconds);
                            const isContinue = index > 0 && messages[index - 1].data.id === messages[index].data.id;
                            const transformOrigin = isOwner ?  "100% 100%" : "0 100%";
                            const animation = index === 0 ? "bounce .2s linear" : null;
                            const timeSeperator = 
                                (messages.length > index + 1) && (messages[index].data.created_at.seconds - messages[index + 1].data.created_at.seconds) > (60 * 60 * 24) ?
                                showTime(date) :
                                index === messages.length - 1 ? 
                                showTime(date) :
                                null;

                            return (
                                <>
                                    <div className="message" key={id} style={{animation: animation, transformOrigin: transformOrigin, borderRadius: isOwner && !isContinue ? "7px 7px 0 7px" : !isContinue ? "7px 7px 7px 0" : "7px", marginBottom: index === 0 ? "0" : isContinue ? "3px" : "20px", padding: image !== null ? "5px" : isOwner ? "7px 10px 10px 38px" : "7px 38px 10px 10px", background: isOwner ? "rgb(220,215,225)" : "var(--chat-selected-color)", alignSelf: isOwner ? "flex-end" : "flex-start", alignItems: isOwner ? "flex-end" : "flex-start" }}>
                                        {
                                            image && (
                                                <div className="image-container">
                                                    <img className="message-img" src={image} alt="" onClick={() => setSelectedImageUrl(image)} />
                                                    {
                                                        id === imageLoadingState.id && imageLoadingState.state !== "success" && (
                                                            <div className="image-state-info-container">
                                                                {
                                                                    imageLoadingState.state === "loading" ? (
                                                                        <CircularProgress color="grey"/>
                                                                    ) : (
                                                                        <></>
                                                                    )
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            )
                                        }
                                        <span className="message-text" style={{ color: isOwner ? "var(--default-text-color)" : "rgb(245,245,245)", marginBottom: image ? "10px" : "" }}>{message}</span>
                                        <span className="message-date noselect" style={{ left: image ? "" : isOwner ? "8px" : "calc(100% - 33px)", color: isOwner ? "rgb(120,120,120)" : "rgb(190,190,190)", alignSelf: isOwner ? "flex-start" : "flex-end" }}>{dateString}</span>
                                    </div>
                                    {timeSeperator && <div className="time-seperator noselect" onClick={() => setPastMessagesFetchState(prev => ({...prev, fetching: true}))}>{timeSeperator}</div>}
                                </>
                            )
                        })
                    }
                    {pastMessagesFetchState.fetching && !pastMessagesFetchState.fetchedAll && <PulseLoader color="var(--theme-color)" size="10px" style={{marginTop: "20px"}}/>}
                    <div className="message" id="welcome-message" ref={welcomeMessageRef} style={{display: "none",  borderRadius: "7px", padding: "10px 15px", animation: "bounce .2s linear", transformOrigin: currentUser.user_type === "advisor" ?  "100% 100%" : "0 100%", background: currentUser.user_type === "advisor" ? "rgb(220,215,225)" : "var(--chat-selected-color)", alignSelf: currentUser.user_type === "advisor" ? "flex-end" : "flex-start", alignItems:  currentUser.user_type === "advisor" ? "flex-end" : "flex-start"}}>
                        <span className="message-text" style={{ color: currentUser.user_type === "advisor" ? "var(--default-text-color)" : "rgb(245,245,245)", marginBottom: "" }}>{currentUser.user_type === "customer" ? contact.live_modes.welcome_message : currentUser.live_modes.welcome_message}</span>
                    </div>
                </div>
                <div className="input-container" style={{ display: meeting ? "flex" : "none" }}>
                    <label className="attach" id="attach">
                        <input accept="image/*" type="file" name="myImage" onChange={onFileChange} onClick={onInputClick} />
                        <GrAttachment className="attach-icon" />
                    </label>
                    {/* onKeyDown="return /[a-z]/i.test(event.key)" */}
                    <input type="text" onKeyUp={e => {
                        if (e.keyCode === 13) {
                            e.preventDefault();
                            e.target.blur();
                            sendMessageToChat()
                        }
                    }} id="message-input" value={message} onChange={handleMessage} placeholder={translation.write_a_message} className="message-input" />
                    <div className="send-button" onClick={sendMessageToChat}><IoIosSend className="send-icon" /></div>
                </div>
                {
                    image.file && (
                        <div className="images">
                            <CgClose className="close-icon" onClick={function () {
                                setImage('');
                                setMessage('');
                            }} />
                            <div className="image-container">
                                <ImageC src={URL.createObjectURL(image.file)} />
                            </div>
                            <div className="image-input">
                                <input type="text" value={message} onChange={handleMessage} placeholder={translation.write_a_message} />
                                <div className="send-button" onClick={sendMessageToChat}><IoIosSend className="send-icon" /></div>
                            </div>
                        </div>
                    )
                }
                {/* <div style={{ zIndex: "100", width: "100%", background: "white", display: "flex", flexDirection: "column", padding: "20px" }}>
                    <span style={{ fontSize: "14px" }}>{`Will automatically terminate in: ${meeting.data.will_automatically_terminate_in}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Circle offer is fetched: ${circleSpecialOfferStatus.fetched}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Circle offer is used: ${circleSpecialOfferStatus.is_used}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Circle offer is open: ${circleSpecialOfferStatus.open}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Circle offer is visible when: max-${circleSpecialOfferStatus.visible_when.max}, min-${circleSpecialOfferStatus.visible_when.min}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Circle offer remaining time: ${circleSpecialOfferStatus.remainingTime}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Popup offer is fetched: ${popupSpecialOfferStatus.fetched}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Popup offer is used: ${popupSpecialOfferStatus.is_used}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Popup offer is open: ${popupSpecialOfferStatus.open}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Popup offer is visible when: max-${popupSpecialOfferStatus.visible_when.max}, min-${popupSpecialOfferStatus.visible_when.min}`}</span>
                    <span style={{ fontSize: "14px" }}>{`Popup offer remaining time: ${popupSpecialOfferStatus.remainingTime}`}</span> 
                </div> */}
            </div>
        ) : <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}><PulseLoader size="16px" color="var(--theme-color)" /></div>
    )
}

class ImageC extends Component {
    constructor(props) {
        super(props);
        this.state = { dimensions: {}, loaded: false };
        this.onImgLoad = this.onImgLoad.bind(this);
    }

    onImgLoad({ target: img }) {
        this.setState(
            {
                dimensions: { height_: img.offsetHeight, width_: img.offsetWidth },
                loaded: true
            }
        );
    }

    render() {
        const { src, key } = this.props;
        const { width_, height_ } = this.state.dimensions;
        const { loaded } = this.state.loaded;
        const aspectRatio = width_ / height_;

        var imageWidth = width_;
        var imageHeight = height_;

        if (width_ >= window.innerWidth && height_ >= window.innerHeight) {
            if (aspectRatio > 1) {
                imageWidth = window.innerWidth;
                imageHeight = (1 / aspectRatio) * window.innerWidth;
            } else {
                imageWidth = aspectRatio * window.innerHeight;
                imageHeight = window.innerHeight;
            }
        }

        return (
            <img onLoad={this.onImgLoad} alt="" key={key} src={src} style={{ width: imageWidth, height: imageHeight, maxWidth: "100%", maxHeight: "100%"}} />
        );
    }
}

export default Chat