import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './provider/store';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
// import SplashScreen from './components/splashscreen/splashscreen';
import ScrollToTop from './hooks/scrolltotop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
      <BrowserRouter>
          <ScrollToTop/>
          <App/>
      </BrowserRouter>
    </Provider>
);

