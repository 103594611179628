import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import './index.scss';

const RouteAdvisorStatus = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if(window && window.location && window.location.search) {
            console.warn(window.location.search);

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);

            const id = urlParams.get("id");
            const name = urlParams.get("name");

            if(id && name) {
                navigate(`/advisor/${name}`, {state: {id: id}});
            }
        }
    }, [window && window.location && window.location.search]);

    return (
        <div className="loading">
            <PulseLoader color="var(--theme-color)" size={18}/>
        </div>
    )
}

export default RouteAdvisorStatus