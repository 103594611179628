import './video.scss'
import React, { useEffect, useState } from 'react'
import ElementControl from '../../../functions/elementcontrol';
import useAgora from '../../../hooks/useAgora';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BsFillMicMuteFill, BsFillMicFill } from 'react-icons/bs'
//import { GrAttachment } from 'react-icons/gr'
import { IoIosSend } from 'react-icons/io';
import { update, ref as dbRef } from 'firebase/database';
import firebase from '../../../config/firebase';
import { addDoc, collection, doc, getDoc, onSnapshot, orderBy, query, Timestamp, updateDoc } from 'firebase/firestore';
import DateHooks from '../../../hooks/dateHook';

const db = firebase.db
const rDb = firebase.rDb

const Video = () => {

  const { user, meeting } = useSelector(state => state.site)
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [streamOptions, setStreamOptions] = useState({
    isLocalMicrophoneMuted: false,
    isLocalVideoStopped: false,
  })

  const { leaveRoom, enterRoom } = useAgora(user, meeting, streamOptions)
  const navigate = useNavigate()

  useEffect(() => {
    if (!user) {
      navigate('/')
    }
  }, [user]);

  useEffect(() => {
    if(!meeting) {
      leaveRoom()
    } else {
      enterRoom()
    }
  }, [meeting])

  useEffect(() => {
    ElementControl.removeFixedElements()
    setStreamOptions(prev => ({
      ...prev,
      isLocalMicrophoneMuted: true
    }))
    return () => ElementControl.addFixedElements()
  }, []);

  useEffect(() => {
    setMessages([])
    const messagesRef = query(collection(firebase.db, `meetings/${meeting.id}/messages`), orderBy("created_at", "desc"));
    const messagesListener = onSnapshot(messagesRef, (snapshots) => {
      snapshots.docChanges().forEach(change => {
        if(change.type === "added") {
          const id = change.doc.id
          const data = change.doc.data()
          setMessages(prev => [{id: id, ...data},...prev])
        }
      })
    }, (error) => console.log(error)) 

    return () => {
      messagesListener()
    }
  }, [])

  function strip(str, remove) {
    while (str.length > 0 && remove.indexOf(str.charAt(0)) !== -1) {
      str = str.substr(1);
    }
    while (str.length > 0 && remove.indexOf(str.charAt(str.length - 1)) !== -1) {
      str = str.substr(0, str.length - 1);
    }
    return str;
  }

  async function terminateMeeting() {
    try {
      await updateDoc(doc(db, `meetings/${meeting.id}`), {
        status: "terminated",
      });
      await updateDoc(doc(db, `users/${meeting.data.advisor_id}`), {
        'status.meeting': null,
      });

      await updateDoc(doc(db, `users/${meeting.data.customer_id}`), {
        'initial_promotion.is_used': true,
        'status.meeting': null,
      });

      await update(dbRef(rDb, `busy_status`), {
        [meeting.data.advisor_id]: false
      })

      const advisorId = meeting.data.advisor_id;
      const advisorData = (await getDoc(doc(db, `users/${advisorId}`))).data();
      await updateDoc(doc(db, `users/${meeting.data.customer_id}`), {
        review_needed: {
          meeting_id: meeting.id,
          advisor_id: advisorId,
          advisor_name: advisorData.profile.name,
          advisor_image: advisorData.profile.pic_url ? advisorData.profile.pic_url : null
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function sendMessageToChat() {
      if (!meeting) {
        return;
      }
      try {
        setMessage('');
        const customerId = meeting.data.customer_id;
        const advisorId = meeting.data.advisor_id;
        const message_ = message ?? strip(message, ' ');
        const date = Timestamp.now();

        if (!message_) {
          return;
        }

        await addDoc(collection(db, `meetings/${meeting.id}/messages`), {
          id: firebase.auth.currentUser.uid,
          image: null,
          text: message_,
          created_at: date,
        });

        if(message) {
          await updateDoc(doc(db, `users/${customerId}/chat/${advisorId}`), {
              'last_message': message,
              'last_message_date': date,
          }); 
          await updateDoc(doc(db, `users/${advisorId}/chat/${customerId}`), {
              'last_message': message,
              'last_message_date': date,
          });
        }
      } catch (e) {
        alert(e.message);
      }
  }

  function showTime(dt) {
    const getDateDiffByDayFromSec = (t2) => {
        const t1 = new Date().getTime() / 1000;
        return Math.abs(Math.floor((t1 - t2) / (60 * 60 * 24)));
    };

    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(len, chr);
    const diffByDay = Math.abs(getDateDiffByDayFromSec(dt.getTime() / 1000));

    if (diffByDay < 7) {
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ];
        switch (diffByDay) {
          case 0:
            return `${padL(dt.getHours())}.${padL(dt.getMinutes())}`;
          case 1:
            return "Yesterday";
          default:
            const today = dt.getDay();
            if (today < diffByDay) {
                return days[days.length - Math.abs(today - diffByDay)];
            } else {
                return days[today - diffByDay];
            }
        }
    } else if (diffByDay < 365) {
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ];
      return `${months[dt.getMonth()].substring(0, 3)} ${dt.getDate()}`;
    } else {
      return `${padL(dt.getMonth() + 1)}.${padL(dt.getDate())}.${dt.getFullYear()}`;
    }
  }

  const handleMessage = e => setMessage(e.target.value);

  return (
    user && meeting && (
      <div className="video" id="video"> 
        <div className="bg"></div>

        <div className="stream">
          <div className="contact-stream" id="contact-stream">
            <div className="buttons">
              <div className="leave-button" onClick={leaveRoom}>Leave room</div>
              <div className="stop-button" onClick={terminateMeeting}>Stop meeting</div>
            </div>
          </div>

          <div className="bottom">
            <div className="chat-space">
              <div className="chat_">
                {
                  messages.length > 0 && messages.sort((a,b) => b.created_at.seconds - a.created_at.secondss).map((message_, index) => {

                    const id = message_.id;
                    //const image = data.image;
                    const isOwner = message_.id === firebase.auth.currentUser.uid;
                    const message = message_.text;
                    //(data.text && data.text.length > 0) && (isOwner ? data.text : data.translated[prefferedLanguage]);
                    const date = DateHooks.getDateFromSec(message_.created_at.seconds);
                    const dateString = DateHooks.getHMByStringFromSec(message_.created_at.seconds);
                    const isContinue = index > 0 && messages[index - 1].id === messages[index].id;
                    const transformOrigin = isOwner ?  "100% 100%" : "0 100%";
                    const animation = index === 0 ? "bounce .2s linear" : null;
                    const timeSeperator = 
                        (messages.length > index + 1) && (messages[index].created_at.seconds - messages[index + 1].created_at.seconds) > (60 * 60 * 24) ?
                        showTime(date) :
                        index === messages.length - 1 ? 
                        showTime(date) :
                        null;

                    return (
                      <div className="message" style={{animation: animation, transformOrigin: transformOrigin, borderRadius: isOwner && !isContinue ? "5px 5px 0 5px" : !isContinue ? "5px 5px 5px 0" : "5px", marginBottom: index === 0 ? "0" : isContinue ? "3px" : "10px", padding: isOwner ? "5px 5px 7px 27px" : "5px 27px 7px 5px", background: isOwner ? "rgb(220,215,225)" : "var(--theme-color)", alignSelf: isOwner ? "flex-end" : "flex-start", alignItems: isOwner ? "flex-end" : "flex-start" }}>
                          <span className="message-text" style={{ color: isOwner ? "var(--default-text-color)" : "rgb(245,245,245)"}}>{message}</span>
                          <span className="message-date noselect" style={{ left: isOwner ? "3px" : "calc(100% - 25px)", color: isOwner ? "rgb(120,120,120)" : "rgb(210,210,210)", alignSelf: isOwner ? "flex-start" : "flex-end" }}>{dateString}</span>
                      </div>
                    )
                  })
                }
              </div>
              <div className="input-space">
                <div className="row">
                  <input type="text" placeholder="Write a message.." value={message} onChange={handleMessage}/>
                  <div className="send-button" onClick={sendMessageToChat}>
                    <IoIosSend className="send-icon"/>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="user-stream" id="user-stream" onClick={() => {
              setStreamOptions(prev => ({...prev, isLocalVideoStopped: !prev.isLocalVideoStopped}))
              console.log("clicked")
            }}></div>
          </div>
        </div>
      </div>
    )
  )
}

   {/* <div className="input-container" style={{ display: meeting ? "flex" : "none" }}>
              <label className="attach" id="attach">
                  <input accept="image/*" type="file" name="myImage" onChange={onFileChange} onClick={onInputClick} />
                  <GrAttachment className="attach-icon" />
              </label>
              <input type="text" onKeyUp={e => {
                  if (e.keyCode === 13) {
                      e.preventDefault();
                      e.target.blur();
                      sendMessageToChat()
                  }
              }} id="message-input" value={message} onChange={handleMessage} placeholder={translation.write_a_message} className="message-input" />
              <div className="send-button" onClick={sendMessageToChat}><IoIosSend className="send-icon" /></div>
            </div> */}

     {/* <div className="settings">
        {
          streamOptions.isLocalMicrophoneMuted ? (
            <BsFillMicMuteFill className="microphone setting" style={{color: "rgba(255, 0, 0, .6)"}} onClick={() => setStreamOptions(prev => ({ ...prev, isLocalMicrophoneMuted: false }))} />
          ) : (
            <BsFillMicFill className="microphone setting" style={{color: "rgba(0, 255, 0, .6)"}} onClick={() => setStreamOptions(prev => ({ ...prev, isLocalMicrophoneMuted: true }))} />
          )
        }
      </div> */}

export default Video