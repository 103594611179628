import React from 'react';
import { Routes, Route } from 'react-router';
import ChatBox from './screens/chatBox/chatbox';
import FavouriteAdvisors from './screens/favouriteAdvisors/favouriteadvisors';
import Love from './screens/readings/love';
import Palm from './screens/readings/palm';
import Psychic from './screens/readings/psychic';
import Dream from './screens/readings/dream';
import Astrology from './screens/readings/astrology';
import Tarot from './screens/readings/tarot';
import Clairvoyance from './screens/readings/clairvoyance';
import Career from './screens/readings/career';
import Chat from './screens/call/chat/chat';
import PrivacyPolicy from './screens/_static/static-privacypolicy';
import AdvisorTermsAndConditions from './screens/_static/static-advisortermsandconditions';
import AdvisorManage from './screens/advisorManage/advisormanage';
import TermOfUse from './screens/_static/static-termsofuse';
import NotFound from './screens/notFound/notfound';
import ForgotPassword from './screens/forgotPassword/forgotpassword';
import Home from './screens/home/home_screen';
import AdvisorProfile from './screens/advisorProfile/advisorProfile';
import Settings from './screens/settings/settings';
import AdvisorsWithDiscount from './screens/advisorsWithDiscount/advisorswithdiscount';
import ForAdvisors from './screens/forAdvisors/foradvisors';
import RouteAdvisorDiscount from './screens/_route/route-advisordiscount';
import RouteAdvisorStatus from './screens/_route/route-advisorstatus';
import Video from './screens/call/video/video';
import Voice from './screens/call/voice/voice';



const Router = () => {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/video" element={<Video />} />
        <Route path="/voice" element={<Voice />} />
        <Route path="/chat_box" element={<ChatBox />} />
        <Route path="/advisors_with_discount" element={<AdvisorsWithDiscount/>}/>
        <Route path="/advisor/manage" element={<AdvisorManage/>}/>
        <Route path="/favourite_advisors" element={<FavouriteAdvisors />} />
        <Route path="/for_advisors" element={<ForAdvisors />} />
        <Route path="/advisor/:content" element={<AdvisorProfile />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/skills/tarot_readings" element={<Tarot />} />
        <Route path="/skills/love_readings" element={<Love />} />
        <Route path="/skills/palm_readings" element={<Palm />} />
        <Route path="/skills/career_readings" element={<Career />} />
        <Route path="/skills/clairvoyance_readings" element={<Clairvoyance />} />
        <Route path="/skills/psychic_readings" element={<Psychic />} />
        <Route path="/skills/dream_analysis" element={<Dream />} />
        <Route path="/skills/astrology_horoscopes" element={<Astrology />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/advisor_terms" element={<AdvisorTermsAndConditions />} />
        <Route path="/terms_of_use" element={<TermOfUse />} />
        <Route path="/discount_notify/advisor" element={<RouteAdvisorDiscount/>} />
        <Route path="/status_notify/advisor" element={<RouteAdvisorStatus/>} />
        <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Router