import './skillscard.scss';
import React from 'react';
import loveImg from '../../assets/ic_love-readings.webp';
import psychicImg from '../../assets/ic_psychic-readings.webp';
import tarotImg from '../../assets/ic_tarot-readings.webp';
import palmImg from '../../assets/ic_palm-readings.webp';
import astrologyImg from '../../assets/ic_astrology-horoscopes.webp';
import dreamImg from '../../assets/ic_dream-analysis.webp';
import clairvoyanceImg from '../../assets/ic_clairvoyance.png';
import careerImg from '../../assets/ic_career.png';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../localization/translations';

const Card = (props) => {

    const navigate = useNavigate();
    const translation = useTranslation();
    const {skill} = props;

    function goRoute () {
        navigate(skillList[skill]["path"]);
    }

    var skillList = {
        Love: {
            img: loveImg,
            text: translation.love_readings,
            textColor: "var(--love-text-color)",
            backgroundColor: "var(--love-card-color)",
            path: "/skills/love_readings"
        },
        Psychic: {
            img: psychicImg,
            text: translation.psychics_readings,
            textColor: "var(--psychic-text-color)",
            backgroundColor: "var(--psychic-card-color)",
            path: "/skills/psychic_readings"
        },
        Tarot: {
            img: tarotImg,
            text: translation.tarot_readings,
            textColor: "var(--tarot-text-color)",
            backgroundColor: "var(--tarot-card-color)",
            path: "/skills/tarot_readings"
        },
        Palm: {
            img: palmImg,
            text: translation.palm_readings,
            textColor: "var(--palm-text-color)",
            backgroundColor: "var(--palm-card-color)",
            path: "/skills/palm_readings"
        },
        Astrology: {
            img: astrologyImg,
            text: translation.astrology_horoscopes,
            textColor: "var(--astrology-text-color)",
            backgroundColor: "var(--astrology-card-color)",
            path: "/skills/astrology_horoscopes"
        },
        Dream: {
            img: dreamImg,
            text: translation.dream_analysis,
            textColor: "var(--dream-text-color)",
            backgroundColor: "var(--dream-card-color)",
            path: "/skills/dream_analysis"
        },
        Clairvoyance: {
            img: clairvoyanceImg,
            text: translation.clairvoyance,
            textColor: "var(--clairvoyance-text-color)",
            backgroundColor: "var(--clairvoyance-card-color)",
            path: "/skills/clairvoyance_readings"
        },
        Career: {
            img: careerImg,
            text: translation.career_readings,
            textColor: "var(--career-text-color)",
            backgroundColor: "var(--career-card-color)",
            path: "/skills/career_readings"
        },
    }

  return (
    <div className= "skill-card noselect" style={{background: skillList[skill]["backgroundColor"]}} onClick={goRoute}>
        <img src={skillList[skill]["img"]} alt=""/>
        <span style={{color: skillList[skill]["textColor"]}}>
            {skillList[skill]["text"]} 
        </span>
    </div>
  )
}

export default Card