import Readings from '../../components/readingscreen/readingscreen';
import tarotImg from '../../assets/ic_tarot-readings.webp'
import useTranslation from '../../localization/translations';

const Tarot = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"tarot"} img={tarotImg} header={"Tarot readings"} text={translation.tarot_description} skillFilter={"Tarot"}/>
  )
}

export default Tarot