import React from 'react';
import './profilecardcircle.scss';

const ProfileCardCircle = ({advisor, translation, navigate}) => {
  return (
    <div className="profile-card-circle" onClick={() => navigate(`/advisor/${advisor.data.profile.name}`, {state: {id: advisor.id}})}>
      <div className="profile-image">
        <div>
          <img src={advisor.data.profile.pic_url} alt=""/>
          <div className="status" style={{background: advisor.data.status.is_online ? "var(--green-color)" : "var(--red-color)"}}></div>
        </div>
      </div>

      <div className="name-skill">
        <span className='name noscrollbar'>{advisor.data.profile.name}</span>
        <div className="row noscrollbar">
            {
                advisor.data.profile.skills.map(function (skill, index) {
                  const skill_ = translation[skill.toLowerCase()];
                  const isLast = index === (advisor.data.profile.skills.length - 1);
                  return (
                      <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                  )
                })
            }
        </div>
      </div>
    </div>
  )
} 

export default ProfileCardCircle