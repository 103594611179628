import { createSlice } from "@reduxjs/toolkit";

export const modal = createSlice({
    name: 'modal',
    initialState: {
      averageCallModal: {
        open: false,
        isShowed: false,
        advisorId: "",
        meetingType: "",
      },
    },
    reducers: {
      setAverageCallModal: (state, action) => {
        state.averageCallModal = action.payload;
      }
    }
})

export const { setAverageCallModal } = modal.actions;
export default modal.reducer;