import React, { useEffect, useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import {BsFillCheckCircleFill, BsExclamationCircleFill} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../localization/translations';
import { setProduct } from '../../../provider/site/site';
import './paymentModal.scss';
import Payment from "../../../functions/payment";
import { PulseLoader } from 'react-spinners';
import DateHooks from '../../../hooks/dateHook';

const PaymentModal = () => {

    
    const dateString = DateHooks.getDateByStringFromDate(new Date());
    const currentYear = parseInt(dateString.split(' ')[0].split('/')[2]);
    const currentMonth = parseInt(dateString.split(' ')[0].split('/')[1]);

    const dispatch = useDispatch();
    const {product} = useSelector(state => state.site);
    const translation = useTranslation();

    const [name, setName] = useState('');
    const [cardNumber1, setCardNumber1] = useState('');
    const [cardNumber2, setCardNumber2] = useState('');
    const [cardNumber3, setCardNumber3] = useState('');
    const [cardNumber4, setCardNumber4] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [cvv, setCvv] = useState('');

    const [paymentResult, setPaymentResult] = useState('');
    const [isPurchasing, setIsPurchasing] = useState(false);

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }
      

    const handleName = e => setName(e.target.value.replace(/[^a-z, ]/gi, '').toUpperCase());
    const handleCardNumber1 = e => {
        setCardNumber1(e.target.value.slice(0, 4));
        if(e.target.value.length >= 4) {
            setInputFocus2();
        }
    } 
    const handleCardNumber2 = e => {
        setCardNumber2(e.target.value.slice(0, 4));
        if(e.target.value.length >= 4) {
            setInputFocus3();
        }
    };
    const handleCardNumber3 = e => {
        setCardNumber3(e.target.value.slice(0, 4));
        if(e.target.value.length >= 4) {
            setInputFocus4();
        }
    };
    const handleCardNumber4 = e => setCardNumber4(e.target.value.slice(0, 4));
    const handleMonth = e => setMonth(e.target.value);
    const handleYear = e => setYear(e.target.value);
    const handleCvv = e => setCvv(e.target.value.slice(0,3));

    const closeModal = () => dispatch(setProduct(''));

    const useFocus = () => {
        const htmlElRef = useRef(null);
        const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    
        return [ htmlElRef, setFocus ]
    }

    const [numberRef2, setInputFocus2] = useFocus();
    const [numberRef3, setInputFocus3] = useFocus();
    const [numberRef4, setInputFocus4] = useFocus();

    useEffect(() => {
        setCardNumber(cardNumber1 + cardNumber2 + cardNumber3 + cardNumber4);
    }, [cardNumber1, cardNumber2, cardNumber3, cardNumber4])

    async function purchase() {
        setIsPurchasing(true);
        await Payment.pay(
            product.id,
            name,
            cardNumber,
            month,
            year,
            cvv,
            ""
        ).then(async (result) => {
            setIsPurchasing(false);
            console.log("Purchase success: ", result);

            if (!result.data.error) {
                setPaymentResult('success');
            } else {
                setPaymentResult('failed');
            }

            await delay(2000).then(resetAll);
        }).catch(async (error) => {
            setIsPurchasing(false);
            console.log("Purchase error: ", error)
            setPaymentResult('failed');
            await delay(2000).then(resetAll);
        })
    }

    function resetAll() {
        setIsPurchasing(false);
        setPaymentResult('');
        dispatch(setProduct(''));
        setCardNumber('');
        setCardNumber1('');
        setCardNumber2('');
        setCardNumber3('');
        setCardNumber4('');
        setName('');
        setCvv('');
        setMonth(1);
        setYear(2023);
    }

    return (
        product && (
            <div className="modal-container" onClick={() => !isPurchasing && closeModal()}>
            {
                paymentResult ? 
                paymentResult === 'success' ? (
                    <div className="success-modal modal modal-anim">
                        <CgClose className="close-icon" onClick={resetAll}/>
                        <BsFillCheckCircleFill className="icon success-icon"/>
                        <span>{translation.success_purchase}</span>
                    </div>
                ) : 
                (
                    <div className="failed-modal modal modal-anim">
                        <CgClose className="close-icon" onClick={resetAll}/>
                        <BsExclamationCircleFill className="icon failed-icon"/>
                        <span>{translation.fail_purchase}</span>
                    </div>
                )
                : (
                    <div className="payment-modal modal modal-anim" onClick={(e) => e.stopPropagation()}>
                        <CgClose className="close-icon" style={{opacity: isPurchasing ? ".5" : "1"}} onClick={() => !isPurchasing && closeModal()}/>
                        <div className="product">
                            <div className="left">
                                <img src={product.img} alt="" />
                                <div className="col">
                                    <span className="coin">{product.coin + ' ' + translation.coin}</span>
                                    <span className="price">${product.price}</span>
                                </div>
                            </div>
                            <div className="right">
                                <h3>{translation.total_amount}</h3>
                                <span>${product.price}</span>
                            </div>
                        </div>

                        <div className="cards">
                            <div className="visa"></div>
                            <div className="master"></div>
                            <div className="american"></div>
                            <div className="discover"></div>
                        </div>
                        
                        <form action="">
                            <label>{translation.card_holder}</label>
                            <input type="text" maxLength={50} className="inputName" onChange={handleName} value={name} placeholder={translation.enter_name_surname}/>
                            <label>{translation.card_number}</label>
                            <div className="row">
                                <input type="number" className="inputCardNumber" onChange={handleCardNumber1} value={cardNumber1} placeholder="****"/>
                                <input type="number" className="inputCardNumber" onChange={handleCardNumber2} value={cardNumber2} placeholder="****" ref={numberRef2}/>
                                <input type="number" className="inputCardNumber" onChange={handleCardNumber3} value={cardNumber3} placeholder="****" ref={numberRef3}/>
                                <input type="number" className="inputCardNumber" onChange={handleCardNumber4} value={cardNumber4} placeholder="****" ref={numberRef4}/>
                            </div>
                            <label>{translation.expire_time}</label>
                            <div className="row">
                                <select onChange={handleMonth}>
                                    <option value={1}>{translation.january}</option>
                                    <option value={2}>{translation.february}</option>
                                    <option value={3}>{translation.march}</option>
                                    <option value={4}>{translation.april}</option>
                                    <option value={5}>{translation.may}</option>
                                    <option value={6}>{translation.june}</option>
                                    <option value={7}>{translation.july}</option>
                                    <option value={8}>{translation.august}</option>
                                    <option value={9}>{translation.september}</option>
                                    <option value={10}>{translation.october}</option>
                                    <option value={11}>{translation.november}</option>
                                    <option value={12}>{translation.december}</option>
                                </select>
                                <select onChange={handleYear}>
                                    <option value={2023}>2023</option>
                                    <option value={2024}>2024</option>
                                    <option value={2025}>2025</option>
                                    <option value={2026}>2026</option>
                                    <option value={2027}>2027</option>
                                    <option value={2028}>2028</option>
                                    <option value={2029}>2029</option>
                                    <option value={2030}>2030</option>
                                    <option value={2031}>2031</option>
                                    <option value={2032}>2032</option>
                                    <option value={2033}>2033</option>
                                    <option value={2034}>2034</option>
                                    <option value={2035}>2035</option>
                                    <option value={2036}>2036</option>
                                    <option value={2037}>2037</option>
                                    <option value={2038}>2038</option>
                                    <option value={2039}>2039</option>
                                    <option value={2040}>2040</option>
                                </select>
                            </div>
                            <label htmlFor="">CVV</label>
                            <div className="row">
                                <input type="number" maxLength="3" className="inputCvv" onChange={handleCvv} value={cvv} placeholder="***"/>
                                <span className="cvv-text">{translation.cvv_text}</span>
                            </div>
                            <div className="purchase-button" onClick={purchase} style={{opacity: year && month && cardNumber.length >= 16 && name && cvv.length >= 3 && !isPurchasing ? "1" : ".5", pointerEvents: year && month && cardNumber.length >= 16 && name && cvv.length >= 3  && !isPurchasing ? "all" : "none"}}>
                                {
                                    isPurchasing ? <PulseLoader size="12px" color="white"/> : translation.process_payment
                                }
                            </div>
                        </form>
                    </div>
                )
            }
            </div>
        )
    )
}

export default PaymentModal