import React from 'react';
import './videoplayer.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoUrl } from '../../provider/site/site';
import { CgClose } from 'react-icons/cg';

const VideoPlayer = () => {

  const { videoUrl } = useSelector(state => state.site);
  const dispatch = useDispatch();

  return (
    videoUrl && (
      <div id="videoplayer" className="videoplayer" onClick={() => dispatch(setVideoUrl(''))}>
        <div className="videoplayer-container" id="videoplayer-container">
          <div className="cont"><CgClose className="closebtn" id="closebtn" onClick={() => dispatch(setVideoUrl(''))} /></div>
          <video controls autoPlay preload="auto" data-setup="{}" src={videoUrl} className="video" onClick={(e) => e.stopPropagation()} />
        </div>
      </div>
    )
  )
}

export default VideoPlayer