import React, { useEffect } from 'react';
import ElementControl from '../../functions/elementcontrol';
import './index.scss';

const AdvisorTermsAndConditions = () => {

  useEffect(() => {

    ElementControl.removeFixedElements();

    return () => ElementControl.addFixedElements();

  }, []);

  return (
    <div className="static-page">
      <h1>Advisor Terms and Conditions</h1>
      <h3 className="last-updated">Last updated January 3, 2023</h3>
      <span>Please read these terms and conditions carefully before using the Zodiac Advisor web site and/or mobile application (the “Service”).</span>
      <span>THIS SERVICE IS FOR ENTERTAINMENT PURPOSES ONLY. YOU UNDERSTAND THAT BY ACCESSING, DOWNLOADING OR USING THE SERVICE, OPERATED BY ZODIAC ADVISOR (“ZODIAC ADVISOR,” “US,” “WE,” OR “OUR”), YOU ARE SIGNIFYING THAT YOU ARE EIGHTEEN (18) YEARS OF AGE OR OLDER AND ARE OTHERWISE CAPABLE OF FORMING LEGALLY BINDING CONTRACTS UNDER APPLICABLE LAW AND THAT YOU HAVE READ, UNDERSTAND AND ACCEPT THESE ADVISOR TERMS AND CONDITIONS (THE “ADVISOR TERMS”) AND AGREE TO BE BOUND BY THEM.</span>
      <span>The terms and conditions below are in addition to the terms and condition contained in Our Terms of </span>
      <span>Use (“Terms of Use”) and limit our liability and obligations to You, as an Advisor, and allow Us to change, suspend or terminate your access to and use of the Service at any time in Our sole discretion. It is your responsibility to read and agree to the following terms and conditions, along with any other policies on Our website including, but not limited to, Our Privacy Policy and Our Terms of Use BY ACCESSING OR USING THE SERVICE YOU AGREE TO BE BOUND BY THESE ADVISOR TERMS AS WELL AS ANY ADDITIONAL TERMS AND CONDITIONS CONTAINED IN OUR TERMS OF USE AND OUR OTHER POLICIES AND AGREEMENTS THAT MAY APPLY TO YOU, INCLUDING, BUT NOT LIMITED TO INDEMNIFICATION, DISCLAIMERS, AND LIMITATIONS OF LIABILITY.</span>
      <span>THESE ADVISOR TERMS APPLY TO ALL ADVISORS WHO ACCESS OR USE THE SERVICE. You understand and agree that your access to and use of the Service is conditioned on your acceptance of and compliance with these Advisor Terms. If You disagree with any part of the Advisor Terms then You must not use or access the Service.</span>
      <h2>BECOMING AN ADVISOR</h2>
      <span>The Service is a psychic advice marketplace that brings advice-seekers and independent, psychic advisors (“Advisor(s)” or “You”) together through video, chat, and messaging. Users will search, engage, submit questions to You, or chat live via text or video with You, via orders that they place. Advisors record their responses or respond in real time for live video or chat sessions, along with any optional text, and deliver it to the clients. If your client desires a live chat-based psychic reading platform, You may invite them to try your services on Zodiac Advisor, available at www.zodiacadvisor.com</span>
      <span>To become an Advisor, You must first download the application and create an advisor profile. You will be required to submit your legal name, address, and telephone number so that We may identify You and contact You. You must also provide Us with your bank account information in order to receive payment through the Service. You must upload a photograph of yourself (other images, icons, or illustrations are not permitted), create a profile video and provide profile information to describe the types of services that You offer. Once You complete your profile, You will create and submit a sample reading for Us to review. After We receive your information and review your reading, You will be notified whether You have been accepted as an Advisor. We reserve the right to verify your identity and other background information at any time and You agree that You will provide Us with information to do so at Our reasonable request. You understand and agree that it is your responsibility to make sure that your profile is appropriate, and that all information that You provide is true, complete, up to date, and accurately reflects your credentials and is in compliance with all applicable laws and regulations.</span>
      <span>By registering as an Advisor, You are not bound to continue to use the Service or to accept orders and provide readings for any length of time. You are free to use the Service when and how you choose, subject to the Advisor Terms. You may stop accepting orders for any period of time or stop using the Service all together for any reason or for no reason at all, provided that You fulfill outstanding orders and existing obligations.</span>
      <h2>YOUR RESPONSIBILITIES AS AN ADVISOR</h2>
      <span>If a client selects You to be their Advisor, You should receive a push notification and an email message. You must have push notifications enabled in order to receive them. We encourage You to log in often to see if You have any new orders. If You have a pending order, it will be contained in the “My Jobs” section of the app. We recommend that You respond as soon as You can to encourage repeat orders and positive client feedback. If You know that You will be unavailable to fulfill orders You may simply turn your availability status to “off” in the “My Jobs” screen and, provided that no clients were in the process of placing an order, no new orders will be received.</span>
      <span>As You fulfill orders, keep in mind your clients will be able to provide feedback and reviews that will appear on your profile. Clients may edit their review for up to 2 weeks after it is entered and We do not remove or edit reviews except for extreme circumstances or to comply with Our Terms of Use.</span>
      <span>We will not be liable for any delays or failures in your receipt of any push notifications or emails as delivery is subject to effective transmission from your network operator and processing by your mobile device. These services are provided on an AS IS, AS AVAILABLE basis.</span>
      <h2>INDEPENDENT CONTRACTOR</h2>
      <span>You understand and agree that, as an Advisor and/or user of the Service, You are acting as an independent contractor and not as Our employee, agent, consultant or representative. You have exclusive control of the manner and means of giving your advice, subject to certain prohibited actions referenced below. As an Advisor, You do not have the right to enter into any contract on our behalf. Similarly, by your use of the Service, we are not establishing a partnership, franchise or joint venture between us. Under no circumstances shall You be considered Our employee. By using the Service, You represent and warrant that You have any and all permissions as may be required by federal, state, local, or international law, as applicable, to use the Service as an Advisor.</span>
      <h2>ADVISOR FEES</h2>
      <span>Advisors will be paid each time that they timely fulfill an order for a reading. As an Advisor, You will receive all payments for fulfilling your orders through PayPal or a similar service to be chosen at Our sole discretion. Any revenue that You earn through Our service is held for a period of two (2) weeks from the time that it is generated and there is a minimum withdrawal sum of $35. In the event that We receive a complaint about your service from a client and We find the complaint to have merit, We may choose to reverse the payment to your account and credit the client the amount paid for the order. In addition to reversing the payment to your account, depending on your behavior, You may be subject to disciplinary action in accordance with Our Advisor Terms. To withdraw money from your account, simply tap on the “Withdraw” button in the revenue screen of your account. Payment is usually deposited into your payment account within 2 to 4 days of your withdrawal request. If an order is placed, but You do not deliver the reading, the payment goes back to the client, You will receive zero compensation, and your account may be subject to suspension or termination.</span>
      <span>Notwithstanding the foregoing, Zodiac Advisor reserves sole discretion, for any reason, to either withhold any amount due to You for completed orders or to reverse payments posted to your account.</span>
      <h2>ADVISOR DISCIPLINE</h2>
      <span>If You fail to fulfill an order for any reason, the client’s order will be cancelled and your Advisor profile will no longer appear on the Service. You will be unable to accept any new orders until your profile is relisted. However, if for any reason You turn your availability off or You fail to fulfill an order and your profile is removed, You are still responsible for on time delivery of any orders that were pending prior to the change in status. In the event that You violate any of these Advisor Terms, the terms and conditions contained in Our other policies, or You fail to fulfill client orders, Zodiac Advisor retains sole discretion to either (i) suspend an Advisor’s account; (ii) take away or suspend your ability to enter earn bonus compensation through “Rush Delivery” mode; (iii) outright terminate your account; or (iv) take any other action We deem appropriate.</span>
      <span>If You feel that your profile was wrongly removed, contact the support team and tell them why.</span>
      <h2>ADVISOR CODE OF CONDUCT</h2>
      <span>You, the Advisor, will be solely and fully liable for all conduct, services, advice, postings and transmissions that are made in connection with your use of the Service. We reserve the right to monitor all videos, messages, communications, and content to ensure that You are complying with these Advisor Terms, as well as the terms and conditions contained in our other policies. You understand and agree that all of your communications with clients must be exclusively carried out via the Service. If You fail to abide by this code of conduct, in addition to pursuing any and all other legal remedies available to Us, your account may be suspended or terminated at Our sole discretion.</span>
      <span>When using the Service, the following guidelines are to be followed by all Advisors:</span>
      <ul>
        <li>You will not knowingly engage in any conduct designed to defraud, mislead, or otherwise deceive any user of the Service.</li>
        <li>You will not using vulgar, racist, offensive or obscene language.</li>
        <li>You will not harass, threaten, or embarrass a client or other user of the Service.</li>
        <li>You will not wear revealing clothing.</li>
        <li>You will present a pleasant visual atmosphere for your client free of disruptions, background noise and outside interferences. For example, You will not record your Advisor responses from a loud party, while driving, or while eating.</li>
        <li>You will maintain a professional relationship with your clients at all times and treat them with dignity and respect.</li>
        <li>You will not badger or retaliate against a client who left you a negative rating.</li>
        <li>You will not post or exchange contact information with any client (or any other information which would allow You to interact with a client outside of the Service), except for your Zodiac Advisor user name if You so desire. This includes, but is not limited to, your telephone number, postal address, e-mail address, social media accounts, other sites where You offer services, or the name You are operating under on any of these sites.</li>
        <li>You will not interact or communicate, or attempt to interact or communicate, with a client outside of the Service, other than through Zodiac Advisor.</li>
        <li>You will not give advice that is of the legal or medical nature. Such advice may only be provided by a properly licensed medical or legal professional, with an established physician-patient relationship, attorney-client, or other relationship. You understand and agree that if a client is suicidal or is indicating potential self-harm, You will tell them that they should seek immediate professional medical care provided by a properly licensed medical professional.</li>
        <li>You will not offer services that guarantee the direct altering of the future, including, but not limited to, spells or spell removal.</li>
        <li>You will advise clients, whom You have been introduced to through the Service, only by, on, or through the Service.</li>
        <li>You will comply with any applicable rules, regulations, laws, or statutes in using the Service as an Advisor.</li>
        <li>You will not provide false information or misleading information in connection with your Advisor profile.</li>
        <li>You will regularly maintain your profile and assure that the information is accurate and up-to-date.</li>
        <li>You will assure that at all times You accurately list and represent your skills, qualifications, and background.</li>
        <li>You will not perform services as an Advisor in any jurisdiction where it is unlawful to do so.</li>
        <li>You will not provide any information or advice or recommendations pertaining to the value, viability, or investment or purchase value of any security, sweepstakes, lottery, games of chance, etc.</li>
        <li>You will not offer additional or alternative services for additional payment or compensation.</li>
        <li>You will not engage in any conduct or take any actions that may manipulate, undermine, and/or interfere with any ratings of any Advisor on the Service. We reserve the right to exclude without explanation any rating that We think may compromise the integrity of the Advisors feedback system.</li>
        <li>You will not engage in conduct that disparages or otherwise negatively affects the Zodiac Advisor.</li>
        <li>You will not attempt to send any “spam” or “junk” or any other form of unsolicited emails or communications to any user of the Service..</li>
        <li>You will not promote, advertise, introduce or refer any other service to any other user.</li>
        <li>You will take all necessary measures to safeguard any and all confidential information provided to You by any client.</li>
        <li>You will not share or disclose any personal information about a client with anyone, including, but not limited to, your friends, family members, and professional colleagues. If You learn personal information about a client from sources outside of the Service, You will disclose such information to the client as well as the source of your information. You will not conceal such information and otherwise use it to your advantage as an Advisor.</li>
        <li>You will not register multiple accounts as an Advisor and shall at all times have at most one active Advisor account.</li>
        <li>You will not, under any circumstances whatsoever, accept, solicit or request any payment, tips, compensation or renumeration of any kind, either directly or indirectly, from a client.</li>
      </ul>
      <span>If We suspect that You have violated the Advisor Code of Conduct or have otherwise breached this Agreement, in addition to all other rights and remedies available to Us herein or by law, Zodiac Advisor reserves the right to withhold or reverse payments, levy fines, and/or suspend or terminate your account in its sole and absolute discretion.</span>
      <h2>PRIVACY & CONFIDENTIALITY</h2>
      <span>All information disclosed to You as an Advisor by a client should be treated as private and confidential information. This information must not be shared, posted, or disclosed to any person (including other Advisors), entity, group, publication, forum, website, or any other place, whatsoever. You understand that your clients are not required to maintain confidentiality with regard to any advice that You provide to a client as an Advisor.</span>
      <span>Any information or content that You post or transmit through the Service will not be considered your confidential information. You grant Zodiac Advisor an unlimited, irrevocable, royalty-free license to use, reproduce, display, edit, copy, transmit, process, control, publicly perform and create derivative works, communicate to the public or any third party any such information and content on a world-wide basis.</span>
      <span>You also acknowledge that you will maintain compliance with the rules of GDPR (General Data Protection Regulation). This includes the following rights to which Clients are entitled:</span>
      <ul>
        <li>Right of access</li>
        <li>Right to erasure of personal data</li>
        <li>Data portability</li>
        <li>Data protection</li>
      </ul>
      <span>This means that You are not to do anything that might infringe upon the Client’s privacy rights, including but not limited to:</span>
      <ul>
        <li>Keeping notes physically or on your own computer/device containing any of the Client’s personal data</li>
        <li>Downloading files or web pages containing any of the Client’s personal data</li>
        <li>Sharing personal Client data with anyone</li>
        <li>Failure to take reasonable measures to protect personal Client data</li>
      </ul>
      <span>The full text of the GDPR can be found here.</span>
      <span><b>BY BEING AN ADVISOR ON ZODIAC ADVISOR AND/OR ZODIAC ADVISOR MOBILE APP, YOU ACKNOWLEDGE THAT YOU ARE LIABLE FOR THE COMPLIANCE WITH THE RULES OF GDPR AS OUTLINED ABOVE. FAILURE TO COMPLY WITH THESE RULES MAY EXPOSE YOU TO MONETARY FINES OR OTHER PENALTIES BY EU AUTHORITIES, AS WELL AS TERMINATION OF YOUR ZODIAC ADVISOR ACCOUNT.</b></span>
      <h2>ADVISOR TAX COMPLIANCE</h2>
      <span>As an Advisor, You may be required to provide Us with completed tax forms as prescribed by law, such as a Form W-9 or Form W-8BEN. If so required, your failure to submit such forms may result in delay of payment to You and/or the termination of your account with Us. As applicable and if required by law, You will be sent a Form 1099 for You to comply with your income tax obligations. Remember, You are solely responsible for reporting, paying, and filing any and all income, taxes, and documents associated with any compensation You receive through your use of the Service.</span>
      <h2>WAIVER & RELEASE</h2>
      <span>You acknowledge and understand that, as an Advisor, You will be solely responsible and liable for any damages and/or claims suffered by or asserted by a client in connection with services that You provided.</span>
      <span>IN THE EVENT OF YOU ARE INVOLVED IN A DISPUTE REGARDING ANY CLIENT OR TRANSACTION, YOU HEREBY RELEASE ZODIAC ADVISOR AND ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, REPRESENTATIVES AND AGENTS FROM ALL MANNER OF ACTIONS, CLAIMS OR DEMANDS AND FROM ANY AND ALL LOSSES (DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL), DAMAGES, COSTS OR EXPENSES, INCLUDING, WITHOUT LIMITATION, COURT COSTS AND ATTORNEY’S FEES.</span>
      <h2>INDEMNIFICATION</h2>
      <span>You agree to defend, indemnify and hold harmless Zodiac Advisor and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of or in connection with: (i) your use and access of the Service, by You or any person using your account and password, including, but not limited to, any interaction or communications with another user, client, advisor, or third party; (ii) a violation or breach of the Terms of Use, the Advisor Terms, or any other agreement that governs your use of the Service; (iv) a violation of any of your representations or warranties made to Us, (v) a violation of any law or the rights of any third party; or (vi) any user Content, third party content, third party sites and any other content posted on the Service.</span>
      <h2>LIMITATION OF LIABILITY</h2>
      <span>In no event shall Zodiac Advisor, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any user, client, advisor, or third party on the Service; (iii) any Content obtained from or provided to the Service; (iv) reliance by any other person or entity on your recommendations or advice or the actions You take; and (v) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not We have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</span>
      <span>Zodiac Advisor is not responsible for (i) any Content or conduct, whether online or offline, made in connection with the Service, whether caused by visitors, users, Advisors, third parties and others; (ii) any error, omission, interruption, deletion, defect, delay in operation or transmission, communication lines failure, theft or destruction or unauthorized access to, or alteration of user communications or Advisor communications; (iii) any problems or technical malfunction of any telephone network or lines, cellular data provider or network, computer online systems, servers or providers, computer equipment, software, failure of email, traffic congestion or downtime on the Service, including injury or damage to users or to any other person’s computer and/or mobile device; or (iv) any loss or damage, including personal injury or death, resulting from anyone’s use of the Service, any content posted or transmitted to users, or any interactions between users of the Service, whether online or offline.</span>
      <h2>GOVERNING LAW</h2>
      <span>You expressly agree that these Advisor Terms and any dispute arising out of these Advisor Terms or use of the Service shall be governed, construed, and enforced in accordance with the laws of New York, United States, without regard to its conflict of law provisions and excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG). You further agree and consent to the exclusive personal and subject matter jurisdiction and venue of the state and federal courts located in the Bergen County, New Jersey, for the resolution of any such dispute and You agree and submit to personal jurisdiction in such courts. In addition, you forever waive any argument or defense based on personal jurisdiction, venue, or forum non conveniens.</span>
      <h2>CHANGES</h2>
      <span>We reserve the right, at Our sole discretion, to modify or replace these Advisor Terms at any time, without notification. The latest Advisor Terms will be posted on Our website. It is your responsibility to review the latest Advisor Terms before You use the Service. By continuing to access or use Our Service after new Advisor Terms become effective, You agree to be bound by the new Advisor Terms. If You do not agree to the new Advisor Terms, please stop using the Service.</span>
      <span>THIS IS A LEGAL AGREEMENT BETWEEN YOU AND ZODIAC ADVISOR BY CLICKING ON THE “CONTINUE” OR “SAVE” BUTTON ON THE ADVISOR SIGNUP PAGE YOU ARE AFFIRMATIVELY STATING THAT YOU HAVE READ AND UNDERSTAND THE ADVISOR TERMS SET FORTH HEREIN AND ARE AFFIRMATIVELY INDICATING YOUR ACCEPTANCE OF THIS AGREEMENT AND YOU AGREE TO BE BOUND BY THE ADVISOR TERMS HEREOF.</span>
    </div>
  )
}

export default AdvisorTermsAndConditions