import {
    useDispatch, useSelector
} from "react-redux";
import {
    setAverageCallModal
} from "../provider/modal/modal";
import {
    setNotEnoughCoinModal,
    showSignUpModal
} from "../provider/site/site";

const useMeetingRequestController = () => {

    const dispatch = useDispatch();
    const { averageCallModal } = useSelector(state => state.modal);

    function meetingRequestController(customer, advisor, meetingType, price) {
        let isValidRequest = true;

        if (!customer) {
            dispatch(showSignUpModal(true));
            isValidRequest = false;
        }

        if (customer.user_type === "advisor") {
            alert("Advisors can't start a meeting.");
            isValidRequest = false;
        }

        if (customer.status.meeting) {
            alert('You have a meeting');
            isValidRequest = false;
        }

        if (advisor.status.busy) {
            alert("Advisor is busy!");
            isValidRequest = false;
        }

        if (customer.initial_promotion.is_used && (customer.balance < price)) {
            dispatch(setNotEnoughCoinModal(true));
            isValidRequest = false;
        }

        if (isValidRequest && (customer.balance < (price * 17) && !averageCallModal.isShowed)) {
            dispatch(setAverageCallModal({
                open: true,
                isShowed: true,
                advisorId: advisor.id,
                meetingType: meetingType
            }));
            isValidRequest = false;
        }

        return isValidRequest;
    }

    return meetingRequestController;
}

export default useMeetingRequestController