import React, { useEffect, useRef, useState } from 'react';
import './home_screen.scss';
import lovers from '../../assets/ic_banner-lovers.png';
import bannerChatEnglish from '../../assets/ic_banner-chat-english.png';
import bannerChatSpanish from '../../assets/ic_banner-chat-spanish.png';
import { BsPlayFill, BsShieldCheck } from 'react-icons/bs';
import allAdvisorImg from '../../assets/ic_alladvisors-icon.webp';
import trendingImg from '../../assets/ic_trending-icon.webp';
import recommendedImg from '../../assets/ic_recommended-icon.webp';
import ProfileCardCircle from '../../components/profilecardcircle/profilecardcircle';
import Card from '../../components/skillscard/skillscard';
import coinImg from '../../assets/ic_coin.png';
import Profilecard from '../../components/profilecard/profilecard';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import { doc, getDoc, onSnapshot} from 'firebase/firestore';
import { getString } from 'firebase/remote-config';
import { GiRoundStar } from 'react-icons/gi';
import { HiEmojiHappy, HiEmojiSad } from 'react-icons/hi';
import { ImFire } from 'react-icons/im';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../localization/translations';
import Firestore from '../../functions/firestore';
import ProfileCardSkeletion from '../../components/profilecard/profilecardskeletion';
import exploreAdvisorsIMG from '../../assets/ic_explore-advisors.svg';
import ProfileCardCircleSkeletion from '../../components/profilecardcircle/profilecardcircleskeletion';

const Home = () => {

  const subscribe = useRef('');
  const navigate = useNavigate();
  const [advisorsState, setAdvisorsState] = useState({
    all: [],
    recommended: [],
    trending: [],
    favourites: [],
    new: [],
    state_fn: "new",    
    bestAdvisorOfTheWeek: null,
    fetched: false,
  });
  const { language, user } = useSelector(state => state.site);
  const advisorId = getString(firebase.remoteConfig, 'best_advisor_of_the_week');
  const skills = ["Love", "Palm", "Tarot", "Astrology", "Dream", "Psychic", "Clairvoyance", "Career"];
  const translation = useTranslation();

  useEffect(() => {
    if (advisorId) {
      subscribe.current = onSnapshot(doc(firebase.db, `users/${advisorId}`), function (snapshot) {
        setAdvisorsState(prev => ({ ...prev, bestAdvisorOfTheWeek: snapshot.data() }));
      })
    }

    return () => {
      if (subscribe.current) {
        subscribe.current();
      }
    };
  }, [advisorId]);

  useEffect(() => {
    async function fetchAdvisors() {
      setAdvisorsState(prev => ({...prev, all: [], recommended: [], trending: [], favourites: [], new: [], state_fn: "new"}));
      const allAdvisors = await Firestore.fetchAllAdvisor();
      const recommendedAdvisors = await Firestore.fetchRecommendedAdvisors();
      const trendingAdvisors = await Firestore.fetchTrendingAdvisors();
      const newAdvisors = await Firestore.fetchNewAdvisors();
      setAdvisorsState(prev => ({ ...prev, all: allAdvisors, trending: trendingAdvisors, recommended: recommendedAdvisors, new: newAdvisors, fetched: true}));
    }
    fetchAdvisors();
  }, []);

  useEffect(() => {
    if(user && user.favourites) {
      async function fetchFavourites() {
        setAdvisorsState(prev => ({...prev, favourites: []}))
        user.favourites.forEach(async (favouriteId) => {
          const data = (await getDoc(doc(firebase.db, `users/${favouriteId}`))).data();
          setAdvisorsState(prev => ({...prev, favourites: [...prev.favourites, {id: favouriteId, data: data}]}))
        })
      }

      fetchFavourites();
    }
  }, [user && user.favourites])

  return (
      <div className="home noscrollbar">
        <section id='banner'>
          <div className="bannerTop">
            <div className="bannerContainer">
              <img src={lovers} alt="" className="loversImg noselect" />
              <div className="imgTop noselect"></div>

              <div className="leftSec">
                <span className="header">{translation.how_to_find_yourself}</span>
                <div className="couponButton" onClick={() => navigate('/advisors_with_discount')}>
                  <span className="noselect">{translation.use_coupon}</span>
                </div>
                <div className="text">
                  <span>{translation.enjoy_reading}</span>
                  <span>{translation.free}</span>
                </div>

                <div className="guarantee" onClick={() => document.getElementById('getcoin-popup').style.display = "block"}>
                  <BsShieldCheck className="check" />
                  <span>{translation.guarantee}</span>
                </div>
              </div>

              <div className="rightSec">
                <img src={language === "en" ? bannerChatEnglish : bannerChatSpanish} alt="" />
              </div>
            </div>
          </div>
        </section>

        <section id='skills'>
          <div className="skills">
            <div className="skills-container noscrollbar">
              {
                skills.sort().map((skill) => {
                  return (
                    <Card key={skill} skill={skill} />
                  )
                })
              }
            </div>
          </div>
        </section>

        {
          advisorsState.bestAdvisorOfTheWeek && (
            <section id="advisor-of-the-week" className='noselect modal-anim' onClick={() => navigate(`advisor/${advisorsState.bestAdvisorOfTheWeek.profile.name}`, { state: { id: advisorId } })}>
              <div className="bar">
                <ImFire className="icon" />
                <h3 className="header">{translation.best_advisor}</h3>
              </div>
              <div className="profile-container">
                <div className="img">
                  <div className="col">
                    <div className="top">
                      <div className="point">
                        <GiRoundStar className="icon" />
                        <span>{advisorsState.bestAdvisorOfTheWeek.reviews.point_avg}</span>
                      </div>
                      <BsPlayFill className="play" />
                    </div>
                    <div className="bottom">
                      <span className="name">{advisorsState.bestAdvisorOfTheWeek.profile.name}</span>
                      <div className="row">
                        {
                          advisorsState.bestAdvisorOfTheWeek.profile.skills.map(function (skill, index) {
                            const skill_ = translation[skill.toLowerCase()];
                            const isLast = index === (advisorsState.bestAdvisorOfTheWeek.profile.skills.length - 1);
                            return (
                              <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                  <img src={advisorsState.bestAdvisorOfTheWeek.profile.pic_url} alt="" />
                  <div className="status" style={{ background: advisorsState.bestAdvisorOfTheWeek.status.is_online ? "var(--green-color)" : "var(--red-color)" }}>{advisorsState.bestAdvisorOfTheWeek.status.is_online ? translation.online : translation.offline}</div>
                </div>
                <div className="info">

                  <div className="description">
                    <h4 className="header">{translation.about_my_services}</h4>
                    <span className="text">{advisorsState.bestAdvisorOfTheWeek.profile.translated_description ? advisorsState.bestAdvisorOfTheWeek.profile.translated_description[language ? language.toLowerCase() : "en"] : advisorsState.bestAdvisorOfTheWeek.profile.description}</span>
                  </div>

                  <div className="expanded"></div>

                  <div className="call">
                    <div className="stream">
                      <div className="chat-call"></div>
                      <div className="voice-call"></div>
                      <div className="video-call"></div>
                    </div>
                    <div className="row" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <span style={{ marginRight: "5px" }}>{translation.from}</span>
                      {
                        advisorsState.bestAdvisorOfTheWeek.live_modes.chat.price.discount_percent > 0 ? (
                          <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <span style={{ marginRight: "2px", fontSize: "15px", textDecoration: "line-through", textDecorationColor: "red" }}><b style={{ color: "red" }}>{advisorsState.bestAdvisorOfTheWeek.live_modes.chat.price.normal_price}</b></span>
                            <span style={{ marginRight: "2px", fontSize: "17px" }}><b>{advisorsState.bestAdvisorOfTheWeek.live_modes.chat.price.discount_price}</b></span>
                          </div>
                        ) : (
                          <span style={{ marginRight: "2px" }}><b>{advisorsState.bestAdvisorOfTheWeek.live_modes.chat.price.normal_price}</b></span>
                        )
                      }
                      <img src={coinImg} alt="" style={{ width: "20px", height: "20px" }} />
                      <span><b>/min</b></span>
                    </div>
                  </div>

                  <div className="reading">
                    <h4>{`${advisorsState.bestAdvisorOfTheWeek.reviews.count.total + ' ' + translation.reading}`}</h4>
                    <div className="row">
                      <div className="positive">
                        <HiEmojiHappy className="emoji-icon" />
                        <span>{advisorsState.bestAdvisorOfTheWeek.reviews.count.positive}</span>
                      </div>
                      <div className="negative">
                        <HiEmojiHappy className="emoji-icon" />
                        <span>{advisorsState.bestAdvisorOfTheWeek.reviews.count.negative}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        }

        <section id='new-favourite-advisors'>
            <div className="bar">
                <div className="row_ noselect">
                    <div className="new" onClick={() => setAdvisorsState(prev => ({...prev, state_fn: "new"}))} style={{ background: advisorsState.state_fn === "new" ? "var(--theme-color)" : "#ebedee", color: advisorsState.state_fn === "new" ? "white" : "rgb(80,80,80)" }}>
                        <span>{translation.new_advisors}</span>
                    </div>
                    <div className="favorite" onClick={() => setAdvisorsState(prev => ({...prev, state_fn: "favourites"}))} style={{ background: advisorsState.state_fn === "favourites" ? "var(--theme-color)" : "#ebedee", color: advisorsState.state_fn === "favourites" ? "white" : "rgb(80,80,80)" }}>
                        <span>{translation.favourite_advisors}</span>
                    </div>
                </div>
            </div>

            <div className="advisors noscrollbar">
                <>
                    {
                        !advisorsState.fetched && advisorsState[advisorsState.state_fn].length === 0  && (
                            <>
                                <ProfileCardCircleSkeletion/>
                                <ProfileCardCircleSkeletion/>
                                <ProfileCardCircleSkeletion/>
                                <ProfileCardCircleSkeletion/>
                                <ProfileCardCircleSkeletion/>
                                <ProfileCardCircleSkeletion/>
                                <ProfileCardCircleSkeletion/>
                            </>
                        )
                    }
                    {
                        advisorsState.fetched && advisorsState[advisorsState.state_fn].length === 0 && (
                            <div className="explore-advisors">
                                <img src={exploreAdvisorsIMG} alt=""/>
                                <span>Explore Advisors</span>
                            </div>
                        )
                    }
                    {   
                        advisorsState[advisorsState.state_fn].length > 0  && (
                            advisorsState[advisorsState.state_fn].sort((a,b) => b.data.status.is_online - a.data.status.is_online).map((advisor) => {
                                return (
                                    <ProfileCardCircle advisor={advisor} translation={translation} navigate={navigate}/>
                                )
                            })
                        )
                    }
                </>
            </div>
        </section>

        <section id='trending'>
          <div className="advisor-profiles-container">
            <div className="bar">
              <div className="header">
                <img src={trendingImg} alt="" />
                <span>{translation.trending}</span>
              </div>
              {/* <SeeAll /> */}
            </div>

            <div className="tag-text">{translation.trending_text}</div>


            <div className="profile-card-container">
              {
                advisorsState.trending.length > 0 ? (
                    advisorsState.trending.map(function (advisor) {
                    return <Profilecard advisor={advisor} />
                  })
                ) : (
                  <>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                  </>
                )
              }
            </div>
          </div>
        </section>


        <section id='recommended'>
          <div className="advisor-profiles-container">
            <div className="bar">
              <div className="header">
                <img src={recommendedImg} alt="" />
                <span>{translation.recommended}</span>
              </div>
              {/* <SeeAll /> */}
            </div>

            <span className="tag-text">{translation.recommended_text}</span>

            <div className="profile-card-container">
              {
                advisorsState.recommended.length > 0 ? (
                    advisorsState.recommended.map(function (advisor) {
                    return <Profilecard advisor={advisor} />
                  })
                ) : (
                  <>
                  <ProfileCardSkeletion/>
                  <ProfileCardSkeletion/>
                  <ProfileCardSkeletion/>
                  <ProfileCardSkeletion/>
                </>
                )
              }
            </div>
          </div>
        </section>

        <section id='all-advisors'>
          <div className="advisor-profiles-container">
            <div className="bar">
              <div className="header">
                <img src={allAdvisorImg} alt="" />
                <span>{translation.all_advisors}</span>
              </div>
            </div>

            <div className="profile-card-container">
              {
                advisorsState.all.length > 0 ? (
                    advisorsState.all.map(function (advisor) {
                    return <Profilecard advisor={advisor} />
                  })
                ) : (
                  <>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                    <ProfileCardSkeletion/>
                  </>
                )
              }
            </div>
          </div>
        </section>

      </div>
    )
}

export default Home