import Readings from '../../components/readingscreen/readingscreen';
import psychicImg from '../../assets/ic_psychic-readings.webp'
import useTranslation from '../../localization/translations';

const Psychic = () => {

  const translation = useTranslation();

  return (
    <Readings skill={"psychic"} img={psychicImg} header={"Psychic readings"} text={translation.psychic_description} skillFilter={"Psychic"}/>
  )
}

export default Psychic