import { ref as dbRef, set} from 'firebase/database';
import { 
    signInWithPopup, 
    FacebookAuthProvider, 
    createUserWithEmailAndPassword, 
    updateProfile, 
    signInWithEmailAndPassword, 
    signInWithRedirect, 
    GoogleAuthProvider, 
    sendEmailVerification,
    sendPasswordResetEmail,
    updatePassword,
    getAuth,
    reauthenticateWithPopup,
    EmailAuthProvider,
    signInWithCredential,
    } from 'firebase/auth';
import firebase from '../config/firebase';
import Firestore from './firestore';
import CookieCRUD from '../storage/cookie';
import UserService from "./UserService";
import bcrypt from 'bcryptjs';

const auth = firebase.auth;
const loginAuth = getAuth();
const googleProvider = new GoogleAuthProvider();

export default class Auth {

    static reauthWithGoogle() {
        return reauthenticateWithPopup(loginAuth, googleProvider);
    }

    static async changePassword(currentPassword, newPassword) {
        const cred = EmailAuthProvider.credential(firebase.auth.currentUser.email, currentPassword);
        await signInWithCredential(firebase.auth, cred).then(async (user) => {
          if(user) {
            console.log(user.user.email, user.user.displayName);
            await updatePassword(firebase.auth.currentUser, newPassword);
          } else {
            throw("Wrong password");
          }
        }).catch((error) => {
          throw(error);
        });
    } 

    static reauthenticate = (currentPassword) => {
      var user = firebase.auth.currentUser;
      const cred = EmailAuthProvider.credential(user.email, currentPassword);
      return user.reauthenticateAndRetrieveDataWithCredential(cred);
    }
    

    async resetPassword(email) {
        const actionCodeSettings = {
          url: `https://zodiacadvisor.com/forgot_password?success=true`
        }
        await sendPasswordResetEmail(auth, email, actionCodeSettings).then(() => alert('PASSWORD RESET SENT'));
    }

    async sendVerification() {
        const user = auth.currentUser;
        const actionCodeSettings = {
          url: `https://zodiacadvisor.com/forgot_password?success=true`
        }
        try {
            await sendEmailVerification(user, actionCodeSettings).then(() => console.log('EMAIL VERIFICATION SENT'));
        } catch (e) {
            throw e.message;
        }
    }

    async signUpWithGoogle () {
      googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      googleProvider.setCustomParameters({
        'login_hint': 'user@example.com'
      });
      signInWithPopup(auth, googleProvider)
        .then(async (result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const credential = GoogleAuthProvider.credentialFromResult(result);
          const token = credential.accessToken;
          // The signed-in user info.
          const user = result.user;

          await Firestore.createCustomerOnDatabase({
            'uid': user.uid,
            'email': user.email,
            'email_verification': user.emailVerified,
            'password': '***********',
            'name': user.displayName,
            'status': {
              'is_online': true,
              'busy': false,
            },
            'initial_promotion': {
              'is_used': false,
              'remaining_time': 3
            },
            'user_type': 'customer',
          });
          CookieCRUD.setCookie('uid', user.uid);
          window.localStorage.setItem('login',JSON.stringify({type: "google"}));
          // ...
        }).catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = GoogleAuthProvider.credentialFromError(error);
          
          // ...
        });
    }
  
    static async signInWithGoogle () {
      googleProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      googleProvider.setCustomParameters({
        'login_hint': 'user@example.com'
      });
      signInWithRedirect(auth, googleProvider);
    }
    
    static async signInWithFacebook () {
      const provider = new FacebookAuthProvider();
      provider.setCustomParameters({
        'display': 'popup'
      })
      provider.addScope('user_birthday');
      signInWithRedirect(auth, provider);
    }
  
    async signUpWithFacebook () {
      const provider = new FacebookAuthProvider();
      provider.setCustomParameters({
        'display': 'popup'
      })
      provider.addScope('user_birthday');
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // The signed-in user info.
          const user = result.user;
  
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          const credential = FacebookAuthProvider.credentialFromResult(result);
          const accessToken = credential.accessToken;
  
          await Firestore.createCustomerOnDatabase({
            'uid': user.uid,
            'email_verification': user.emailVerified,
            'email': user.email,
            'name': user.displayName,
            'password': '*********',
            'gender': 'male',
            'status': {
              'is_online': true,
              'busy': false,
            },
            'initial_promotion': {
              'is_used': false,
              'remaining_time': 3
            },
            'user_type': 'customer',
          });
          CookieCRUD.setCookie('uid', user.uid);
          window.localStorage.setItem('login',JSON.stringify({type: "facebook"}));
  
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.customData.email;
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error);
  
          // ...
        });
    }
  
    async signUp (name, email, password) {
      
      try {
        await createUserWithEmailAndPassword(auth, email, password).then(async (userCredential) => {
          if (userCredential != null) {
            const uid = userCredential.user.uid;
            await updateProfile(userCredential.user, {
              displayName: name
            }).then(async () => {
              await this.sendVerification();
              await Firestore.createCustomerOnDatabase({
                'uid': uid,
                'email': email,
                'email_verification': false,
                'password': password,
                'name': name,
                'gender': 'male',
                'status': {
                  'is_online': true,
                  'busy': false,
                },
                'initial_promotion': {
                  'is_used': false,
                  'remaining_time': 3
                },
                'user_type': 'customer',
              });
              CookieCRUD.setCookie('uid', uid);
              window.localStorage.setItem('login',JSON.stringify({email: email, password: password, type: "email"}));
              window.sessionStorage.setItem('login', JSON.stringify({email: email, password: password, type: "email"}));
              console.log(JSON.parse(window.sessionStorage.getItem('login')));
              console.log(JSON.parse(window.localStorage.getItem('login')));
            });
          } else {
            var msg = 'An unknown problem has occurred, please try again later.';
            throw msg;
          }
        })
      } catch (e) {
        throw e.message;
      }
    }
  
    static async signIn (email, password) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        CookieCRUD.setCookie('uid', userCredential.user.uid);
        window.localStorage.setItem('login',JSON.stringify({email: email, password: password, type: "email"}));
        window.sessionStorage.setItem('login', JSON.stringify({email: email, password: password, type: "email"}));
        console.log(JSON.parse(window.sessionStorage.getItem('login')));
        console.log(JSON.parse(window.localStorage.getItem('login')));
      } catch (e) {
        throw e.message;
      }
    }
  
    static async logout () {
      const uid = auth.currentUser.uid;
      try {
        // Before logging out, set user status to offline
        const ref = dbRef(firebase.rDb, '/status/' + uid);
        await set(ref, UserService.offlineStatus);

        await auth.signOut();
        CookieCRUD.removeCookie('uid');
      } catch (e) {
        throw e.message;
      }
    }
  
    static async authErrorToString (err) {
      const a = 'a(asd$asd)';
      const b = a.substring(a.indexOf('('), a.indexOf(')'))
      return b.substring(4,b.length-1).replace('-', " ");
    }
  }