import { BiSearchAlt } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import {
  collection,
  deleteDoc,
  doc,
  startAfter,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import firebase from '../../config/firebase';
import { useSelector } from 'react-redux';
import coinImg from '../../assets/ic_coin.png';
import { FiChevronDown } from 'react-icons/fi';
import { PulseLoader } from 'react-spinners';
import { CgClose } from 'react-icons/cg';
import { TbArrowBackUp } from 'react-icons/tb';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import DateHooks from '../../hooks/dateHook';
import useTranslation from '../../localization/translations';
import './chatbox.scss';
import { useNavigate } from 'react-router-dom';
import useSendMeetingRequest from '../../hooks/useSendMeetingRequest';
import { BsThreeDotsVertical } from 'react-icons/bs';

const fetchMessageAmount = 10;

const ParentChatBox = () => {

  const { meeting, user, related_users } = useSelector(state => state.site);
  const translation = useTranslation();
  const [runs, setRuns] = useState(true);
  const [search, setSearch] = useState('');
  const [contacts, setContacts] = useState([]);
  const [fetchMessagesState, setFetchMessagesState] = useState({
    messages: [],
    fetchedAll: false,
    fetching: false,
    startAfter: null,
  });
  const [currentUser, setCurrentUser] = useState(user);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [currentContactId, setCurrentContactId] = useState('');
  const [currentContact, setCurrentContact] = useState('');
  const [currentContacts, setCurrentContacts] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [deletedContactId, setDeletedContactId] = useState("");
  var { width } = useWindowDimensions();
  const navigate = useNavigate();

  const sendMeetingRequest = useSendMeetingRequest();

  const browserLang = navigator.language.substring(0, 2);

  const translatedLanguages = [
    "en",
    "es",
    "de",
    "fr"
  ];

  const showingLang = translatedLanguages.includes(browserLang) ? browserLang : "en";

  function handleSearch(e) {
    setSearch(e.target.value);
    if (contacts.length > 0) {
      const filteredContacts = contacts.filter(contact => contact.data.profile.name.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilteredContacts(filteredContacts);
    }
  }

  function selectContact(id) {
    setCurrentContactId(id);
    if (width <= 750) {
      document.getElementById("contacts-container").style.display = "none";
    }
  }

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    if (search) {
      setCurrentContacts(filteredContacts);
    } else {
      setCurrentContacts(contacts);
    }
  }, [search, contacts, filteredContacts]);

  // When a contact selected, fetch its previous messages (containing all the previous meetings)
  useEffect(() => {
    console.log("currentContactId changed:", currentContactId);
    setCurrentContact('');
    let unsubscribe = null;

    if (currentContactId) {
      const currentContact = contacts.filter(contact => contact.id === currentContactId)[0];
      const chat = currentContact.chat;
      unsubscribe = onSnapshot(doc(firebase.db, `users/${currentContactId}`), function (snapshot) {
        setCurrentContact({ id: currentContactId, data: snapshot.data(), chat: chat });
      })
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  }, [meeting.id, currentContactId]);

  useEffect(() => {
    if(currentContactId) {
      const el = document.getElementById("chatbox-messages");
      if(el) {
        el.scrollTo(0, 0);
      }

      setFetchMessagesState({
        messages: [],
        fetchedAll: false,
        fetching: false,
        startAfter: null,
      });

      if (contacts.length === 0) {
        return;
      }
      const meetingId = contacts.filter(contact => contact.id === currentContactId)[0].chat.meeting;
  
      if (!meetingId) {
        return;
      }
  
      const q = query(collection(firebase.db, `meetings/${meetingId}/messages`), orderBy('created_at', 'desc'), limit(10))
      
      getDocs(q).then(messages => {
        messages.docs.forEach(message => {
          setFetchMessagesState(prev => ({
            ...prev,
            messages: [
              ...prev.messages,
              {id: message.id, data: message.data()}
            ],
          }))
        })
        setFetchMessagesState(prev => ({
          ...prev,
          startAfter: messages.docs[messages.docs.length - 1],
          fetching: true
        }))
      })

    }
  }, [currentContactId]);

  useEffect(() => {
    if(fetchMessagesState.fetching && fetchMessagesState.startAfter && !fetchMessagesState.fetchedAll) {

        const meetingId = contacts.filter(contact => contact.id === currentContactId)[0].chat.meeting;
        const q = query(collection(firebase.db, `meetings/${meetingId}/messages`), orderBy('created_at', 'desc'), startAfter(fetchMessagesState.startAfter), limit(10))
        

        getDocs(q).then(messages => {
          messages.docs.forEach(message => {
            setFetchMessagesState(prev => ({
              ...prev,
              messages: [
                ...prev.messages,
                {id: message.id, data: message.data()}
              ],
            }))
          })
          if(messages.docs.length < fetchMessageAmount) {
            setFetchMessagesState(prev => ({
              ...prev,
              fetchedAll: true,
            }))
          }
          else {
            setFetchMessagesState(prev => ({
              ...prev,
              fetching: false,
              startAfter: messages.docs[messages.docs.length - 1]
            }))
          }
        })
        
    }
  }, [fetchMessagesState.fetching])

  // Fetches contacts
  // Initially the first contact will be selected after fetching contacts.
  useEffect(() => {
    async function getContacts() {
      if (!currentUser) {
        return [];
      }

      const q = query(collection(firebase.db, `users/${currentUser.uid}/chat`), orderBy('last_message_date', 'desc'))
      const contactList = (await getDocs(q)).docs;

      return await Promise.all(contactList.map(async function (contact) {
        const id = contact.id;
        const chat = contact.data();
        const data = (await getDoc(doc(firebase.db, `users/${id}`))).data();
        return { id: id, data: data, chat: chat };
      }));
    }

    function removeEndSection() {
      setTimeout(() => {
        const endSection = document.getElementById('end-section') ?? null;

        if (endSection) {
          endSection.style.display = "none";
        }
      }, 200);
    }

    function addEndsection() {
      setTimeout(() => {
        const endSection = document.getElementById('end-section') ?? null;

        if (endSection) {
          endSection.style.display = "flex";
        }
      }, 200);
    }

    removeEndSection();

    setRuns(true);
    getContacts().then((contactList) => {
      console.log("Contacts: ", contactList);
      console.log("Contacts fetched: ", contactList.length, " - selected contact: ", currentContactId);
      setContacts(contactList);
      if (contactList.length > 0) {
        setCurrentContactId(contactList[0].id);
      }
      setRuns(false);
    });

    return () => addEndsection();
  }, [currentUser]);

  function showTime(dt) {
    const getDateDiffByDayFromSec = (t2) => {
        const t1 = new Date().getTime() / 1000;
        return Math.abs(Math.floor((t1 - t2) / (60 * 60 * 24)));
    };

    const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(len, chr);
    const diffByDay = Math.abs(getDateDiffByDayFromSec(dt.getTime() / 1000));

    if (diffByDay < 7) {
        const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
        ];
        switch (diffByDay) {
            case 0:
                return `${padL(dt.getHours())}.${padL(dt.getMinutes())}`;
            case 1:
                return "Yesterday";
            default:
                const today = dt.getDay();
                if (today < diffByDay) {
                    return days[days.length - Math.abs(today - diffByDay)];
                } else {
                    return days[today - diffByDay];
                }
        }
    } else if (diffByDay < 365) {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return `${months[dt.getMonth()].substring(0, 3)} ${dt.getDate()}`;
    } else {
        return `${padL(dt.getMonth() + 1)}.${padL(dt.getDate())}.${dt.getFullYear()}`;
    }
}

  async function deleteChat(contactId) {
    await deleteDoc(doc(firebase.db, `users/${currentUser.uid}/chat/${contactId}`));
    setContacts(prev => {
      const index = prev.findIndex(object => {
        console.log("OBJECT ID: ", object.id);
        console.log("CONTACT ID: ", contactId);
        return object.id === contactId;
      });
      console.log("INDEX: ", index);
      prev.splice(index, 1);
      console.log([...prev]);
      return [...prev];
    });
    console.log("CONTACT DELETED: ", contactId);
    setDeletedContactId("");
  }

  function routeAdvisor() {
    const advisorName = currentContact.data.profile.name;
    navigate(`/advisor/${advisorName}`, { state: { id: currentContact.id } });
  }

  return (
    !runs && currentUser ? (
      <div className="chat_box">

        <div className="modal-container" id="question-modal" onClick={() => setDeletedContactId("")} style={{ display: deletedContactId ? "flex" : "none" }}>
          <div className={`${width > 500 ? "modal-anim question-modal-pc" : "modal-anim-mobile question-modal-mobile"} question-modal`}>
            <div className="button yes" onClick={(e) => {
              e.stopPropagation();
              deleteChat(deletedContactId);
            }}><span>{"Delete"}</span></div>
            <div className="button no" onClick={() => setDeletedContactId("")}><span>{"Keep it"}</span></div>
          </div>
        </div>

        {/* <QuestionModal toggle={questionModal} yesText="Delete" noText="deneme" func={() => deleteChat()} /> */}
        <div className="contacts-container noselect" id="contacts-container">
          {/* USER */}
          <div className="user_">
            {currentUser.profile.pic_url ? <img src={currentUser.profile.pic_url} className="user-img"></img> : <FaUser className="user-icon" />}
            <span className="user-name">{currentUser.profile.name}</span>
            <div className="related_users" style={{display: "flex"}}>
                {
                    user && user.children && [user, ...related_users].map((user, index) => {
                        const isCurrent = [user, ...related_users][index].uid === currentUser.uid; 
                        return (
                            <div onClick={() => setCurrentUser(user)} style={{width: "32px", height: "32px", borderRadius: "50%", overflow: "hidden", marginRight: related_users.length !== index ? "5px" : "0", border: isCurrent ? "1px solid var(--theme-color)" : "none"}}>
                                <img src={user.profile.pic_url} alt="" style={{width: "100%", height: "100%", filter: isCurrent ? "brightness(80%)" : "brightness(30%)"}}/>
                            </div>
                        )
                    })
                }
            </div>
          </div>
          <div className="search">
            <BiSearchAlt className="search-icon" style={{ color: search ? "var(--chat-primary-text-color)" : "#fff5" }} />
            <input type="text" value={search} onChange={handleSearch} placeholder="Aratın ya da yeni bir sohbet başlatın" />
            {/* onKeyDown="return /[a-z]/i.test(event.key)"  */}
          </div>
          {/* CONTACTS */}
          <div className="contacts">
            {
              // sort((a,b) => b.last_message_date.seconds - a.last_message_date.seconds)
              currentContacts.length > 0 ? (
                currentContacts.map(function (contact) {
                  const id = contact.id;
                  const data = contact.data;
                  const chat = contact.chat;
                  const isSelected = id === currentContactId;

                  return (
                    <div key={id} className="contact" id={id} onClick={() => selectContact(id)} style={{ background: width <= 750 ? "var(--chat-primary-color)" : isSelected ? "var(--chat-selected-color)" : "var(--chat-primary-color)" }}>
                      {
                        currentUser.user_type === "advisor" && chat.total_earning && (
                          <div className="total-gain row">
                            <span>{chat.total_earning}</span>
                            <img src={coinImg} />
                          </div>
                        )
                      }
                      <div className="contact-img">
                        {contact.data.profile.pic_url ? <img src={contact.data.profile.pic_url} alt="" /> : <FaUser className="contact-icon" />}
                      </div>
                      <div className="col">
                        <div className="row" style={{ marginBottom: chat.last_message ? "0" : "10px" }}>
                          <span className="contact-name">{data.profile.name}</span>
                          {chat.last_message_date && <span className="last-message-time">{showTime(DateHooks.getDateFromSec(chat.last_message_date.seconds))}</span>}
                        </div>
                        {
                          chat.last_message && (
                            <div className="row">
                              <span className="last-message">{chat.last_message}</span>
                            </div>
                          )
                        }
                      </div>
                    </div>
                  )
                }
                )
              ) :
                <div className="find-contact">
                  {/* Find contact */}
                </div>
            }
          </div>
        </div>

        <div className="chat-container" id="chat-container">

          {/* ADVISOR */}
          <div className="contact noselect">
            {
              currentContact && (
                <>
                  <div className="back" onClick={() => document.getElementById('contacts-container').style.display = "flex"} style={{ display: width < 750 ? "flex" : "none" }}><TbArrowBackUp className="back-icon" /></div>
                  <div className="advisor-img" onClick={() => currentContact.data.user_type === "advisor" && !meeting && routeAdvisor()} style={{ cursor: currentContact.data.user_type === "advisor" && !meeting && "pointer" }}>
                    <div className="contact-img">
                      {currentContact.data.profile.pic_url ? <img src={currentContact.data.profile.pic_url} alt="" /> : <FaUser className="contact-icon" />}
                    </div>
                    {currentContact.data.user_type === "advisor" && <div className="status-color"><div style={{ background: currentContact.data.status.is_online ? "var(--green-color)" : "var(--red-color)" }}></div></div>}
                  </div>

                  <div className="col">
                    <span className="advisor-name">{currentContact.data.profile.name}</span>
                    {currentContact.data.user_type === "advisor" && <span className="advisor-status">{currentContact.data.status.is_online ? translation.online : translation.offline}</span>}
                  </div>

                  <BsThreeDotsVertical className="three-dots" onClick={(e) => {
                    e.stopPropagation();
                    const contactMenu = document.getElementById("contact-menu");
                    if (contactMenu) {
                      const outsideClickListener = event => {
                        if (!contactMenu.contains(event.target)) { // or use: event.target.closest(selector) === null
                          contactMenu.style.display = 'none';
                          contactMenu.removeEventListener('click', outsideClickListener);
                        }
                      }
                      contactMenu.addEventListener('click', outsideClickListener);
                      if (contactMenu.style.display === "none") {
                        contactMenu.style.display = "flex"
                      } else {
                        contactMenu.style.display = "none";
                      }
                    }
                  }}/>
                  <div className="contact-menu" id="contact-menu">
                    <div className="delete menu-item">
                      <span onClick={() => setDeletedContactId(currentContactId)}>Delete chat</span>
                    </div>
                  </div>

                </>
              )
            }

          </div>
          {/* CHAT */}
          <div className="chat_">

            {
              selectedImageUrl && (
                <div className="image-fullscreen">
                  <img src={selectedImageUrl} alt="" />
                  <CgClose className="close-icon" onClick={() => setSelectedImageUrl('')} />
                </div>
              )
            }


            <div className="messages" id="chatbox-messages" style={{ paddingBottom: (currentUser.user_type === "advisor" || (currentUser.user_type === "customer" && currentContact && currentContact.data.status.is_online && !currentContact.data.status.busy && !meeting)) ? "80px" : "20px" }}
            onScroll={() => {
              if(!fetchMessagesState.fetchedAll) {
                const messagesWrapper = document.getElementById("chatbox-messages");
                if(Math.abs(messagesWrapper.scrollTop) >= (messagesWrapper.scrollHeight - messagesWrapper.clientHeight - 100)) {
                    setFetchMessagesState(prev => ({
                        ...prev,
                        fetching: true
                    }));
                }
              }
            }}>
              {
                currentContactId ? (
                  <>
                    {currentUser.user_type === "customer" && currentContact && currentContact.data.status.is_online && !currentContact.data.status.busy && !meeting && <div className="start-button noselect" onClick={() => sendMeetingRequest(currentContactId, "chat")}>{translation.start_chat}</div>}
                    {
                      currentUser.user_type === "advisor" && currentContact && currentContact.chat.total_earning && (
                        <div className="meeting-info noselect">
                          <span>{`Gained: ${currentContact.chat.total_earning}`}</span>
                          <img src={coinImg} alt="" />
                        </div>
                      )
                    }
                    {
                      fetchMessagesState.messages.length > 0 && fetchMessagesState.messages.sort((a, b) => b.data.created_at.seconds - a.data.created_at.seconds).map(function (message_, index) {
                        console.log(fetchMessagesState.messages.length);

                        const id = message_.id;
                        const data = message_.data;
                        const image = data.image;
                        const isOwner = data.id === user.uid;
                        const date = DateHooks.getDateFromSec(data.created_at.seconds);
                        const dateString = DateHooks.getHMByStringFromSec(data.created_at.seconds);
                        const message = (data.text && data.text.length > 0) && (isOwner ? data.text : data.translated[showingLang]);
                        const isContinue = index > 0 && fetchMessagesState.messages[index - 1].data.id === fetchMessagesState.messages[index].data.id;
                        const timeSeperator = 
                                (fetchMessagesState.messages.length > index + 1) && (fetchMessagesState.messages[index].data.created_at.seconds - fetchMessagesState.messages[index + 1].data.created_at.seconds) > (60 * 60 * 24) ?
                                showTime(date) :
                                index === fetchMessagesState.messages.length - 1 ? 
                                showTime(date) :
                                null;

                        return (
                          <>
                            <div className="message" key={id} style={{ borderRadius: isOwner && !isContinue ? "7px 7px 0 7px" : !isContinue ? "7px 7px 7px 0" : "7px", marginBottom: index === 0 ? "0" : isContinue ? "3px" : "20px", padding: image !== null ? "5px" : isOwner ? "7px 7px 7px 38px" : "7px 38px 7px 7px", background: isOwner ? "rgb(220,215,225)" : "var(--chat-selected-color)", alignSelf: isOwner ? "flex-end" : "flex-start", alignItems: isOwner ? "flex-end" : "flex-start" }}>
                              {
                                image && (
                                  <div className="image-container">
                                    <img className="message-img" src={image} alt="" onClick={() => setSelectedImageUrl(image)}/>
                                  </div>
                                )
                              }
                              <span className="message-text" style={{ color: isOwner ? "var(--default-text-color)" : "rgb(245,245,245)", marginBottom: image ? "10px" : "" }}>{message}</span>
                              <span className="message-date noselect" style={{ left: image ? "" : isOwner ? "8px" : "calc(100% - 33px)", color: isOwner ? "rgb(60,60,60)" : "rgb(200,200,200)", alignSelf: isOwner ? "flex-start" : "flex-end" }}>{dateString}</span>
                            </div>
                            {timeSeperator && <div className="time-seperator noselect" onClick={() => fetchMessagesState(prev => ({...prev, fetching: true}))}>{timeSeperator}</div>}
                          </>
                        )
                      })
                    } 
                  </>
                  
                ) : (
                  <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "0 20px" }}>
                    <img src={require('../../assets/ic_heart.png')} style={{ width: "100%", maxWidth: "400px", aspectRatio: "5/4" }}></img>
                    <span style={{ marginTop: "20px", fontSize: "24px", color: "var(--chat-selected-color)" }}>Not chat yet</span>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    ) :
      (
        <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <PulseLoader color="var(--theme-color)" />
        </div>
      )
  )
}

export default ParentChatBox