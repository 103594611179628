import { getToken } from 'firebase/messaging';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from "firebase/firestore";
import firebase from '../config/firebase';

const db = firebase.db;
const auth = firebase.auth;
const messaging = firebase.messaging;

export default class NotificationControl {

    static createScheduledNotification = async (tag, title, body, icon) => {
        if('serviceWorker' in navigator) {
            console.log("CP1");
            Notification.requestPermission(async (result) => {
                console.log("CP2");
                if (result === "granted") {
                    console.log("CP3");
                    navigator.serviceWorker.ready.then((registration) => {
                        registration.showNotification(title, {
                            tag: tag,
                            body: body,
                            vibrate: [200, 100, 200, 100, 200, 100, 200],
                            icon: icon,
                        });
                        registration.update();
                    })
                }
            });
        } else {
            console.log("-CP1");
        }
    };

    static requestNotificationPermission() {
        if ('Notification' in window && Notification.permission !== "granted") {
            Notification.requestPermission();
        }
    }

    static checkNotificationPermissionStatus() {
        let permissionStatus = false;
        if (Notification.permission === "granted") {
            permissionStatus = true;
        }
        return permissionStatus;
    }

    static async updateNotificationToken() {
        const hasNotificationToken = await this.checkIfUserHasNotificationToken();
        const notificationToken = await this.getNotificationToken();
        if(hasNotificationToken) {
            await updateDoc(doc(db, `users/${firebase.auth.currentUser.uid}`), {'notification_tokens': arrayUnion(notificationToken)});
        } else {
            await updateDoc(doc(db, `users/${firebase.auth.currentUser.uid}`), {'notification_tokens': [notificationToken]});
        }
        console.warn('TOKEN UPDATED SUCCESSFULLY');
    }
   
    static async getNotificationToken() {
        var notificationToken = null;
        const isGranted = this.checkNotificationPermissionStatus();
        if(!isGranted) {
            throw 'Dont have notification permission';
        }
        await getToken(messaging, {vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY}).then(async function(token) {
            console.warn("TOKEN: ", token);
            notificationToken = token;
        });
        return notificationToken;
    }

    static async checkIfUserHasNotificationToken() {
        var hasNotificationToken = false;
        const token = (await getDoc(doc(firebase.db, `users/${auth.currentUser.uid}`))).get('notification_tokens');
        if(token) {
            hasNotificationToken = true;
        }
        return hasNotificationToken;
    }

    static async addNotify(uid) {
        try {
            updateDoc(doc(firebase.db, `users/${firebase.auth.currentUser.uid}`), {
                'notify': arrayUnion(uid),
            })
        } catch (e) {
            throw e.message;
        } 
    }

    static async removeNotify(uid) {
        try {
            updateDoc(doc(firebase.db, `users/${firebase.auth.currentUser.uid}`), {
                'notify': arrayRemove(uid),
            })
        } catch (e) {
            throw e.message;
        } 
    }
}