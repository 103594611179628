import React, { useEffect } from 'react'
import './notfound.scss'
import { useNavigate } from 'react-router-dom'
import NotFoundIMG from '../../assets/ic_404.svg';
import useTranslation from '../../localization/translations';
import ElementControl from '../../functions/elementcontrol';


const NotFound = () => {

  const translation = useTranslation();
  const navigate = useNavigate();

  function goHome () {
    navigate('/');
  }


  useEffect(() => {
    const endSection = document.getElementById('end-section');
    if(endSection) {
      endSection.style.display = "none";
    }

    return () => ElementControl.addFixedElements();
  }, []);
  
  return (
    <div className="_aB1kC0">
        <img src={NotFoundIMG} alt="404" className="_aB1k1C"/>
        <span className="_aB1k1D">{translation.not_found}</span>
        <div className="_aB1k2f" onClick={goHome}>{translation.go_home}</div>
    </div>
  )
}

export default NotFound