import React, { useEffect, useState } from 'react';
import './settings.scss';
import { HiOutlineLogout } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import useTranslation from '../../localization/translations';
import { useNavigate } from 'react-router-dom';
import Auth from '../../functions/auth';
import { BsFillCheckCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { doc, updateDoc } from 'firebase/firestore';
import firebase from '../../config/firebase';
//import { doc, updateDoc } from 'firebase/firestore';


const Settings = () => {

  const translation = useTranslation();
  const { user } = useSelector(state => state.site);
  const [password, setPassword] = useState('');
  const [rpassword, setRPassword] = useState('');
  const [status, setStatus] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  const updatePassword = async () => {
    await Auth.changePassword(password, rpassword).then(async () => {
      await updateDoc(doc(firebase.db, `users/${firebase.auth.currentUser.uid}`), {
        'profile.password': rpassword,
      }).then(() => {
        setStatus('success');
        setPassword('');
        setRPassword('');
      });
    }).catch((error) => {
      setStatus('failed');
      console.log(error);
    });
  }

  function resetAll() {
    setStatus('');
    setPassword('');
    setRPassword('');
  }

  const message = {
    success: translation.reset_success,
    failed: translation.wrong_password,
    //not_same: translation.reset_fail_field,
  }

  return (
    <>
      {
        status && (
          <div className="modal-container">
            {
              <>
                <div className="success-modal modal modal-anim" style={{ display: status === "success" ? "flex" : "none" }}>
                  <CgClose className="close-icon" onClick={resetAll} />
                  <BsFillCheckCircleFill className="icon success-icon" />
                  <span>{message[status]}</span>
                </div>

                <div className="failed-modal modal modal-anim" style={{ display: status !== "success" ? "flex" : "none" }}>
                  <CgClose className="close-icon" onClick={resetAll} />
                  <BsFillExclamationCircleFill className="icon failed-icon" />
                  <span>{message[status]}</span>
                </div>
              </>
            }
          </div>
        )
      }
      <div className="settings_">
        <div className="settings-container_ noselect">
          <h1><b>{translation.settings}</b></h1>
          <div className="container">

            <div className="account">
              <div className="save" onClick={updatePassword} style={{ opacity: password && rpassword && password.length >= 6 && rpassword.length >= 6 ? '1' : '0', pointerEvents: password && rpassword && password.length >= 6 && rpassword.length >= 6 ? 'all' : 'none' }}></div>
              <h3><b>{translation.account}</b></h3>
              <div className="email">
                <span>{translation.email}</span>
                <span>{user && user.profile.email}</span>
              </div>
              <div className="change-pass">
                <div style={{padding: "0", justifyContent: "flex-start"}}>
                  <span style={{marginRight: "5px"}}>{translation.password}</span>
                  <span style={{ color: "grey", fontSize: "12px" }}>{`(${translation.your_current_password})`}</span>
                </div>
                <input type="password" value={password} placeholder="●●●●●●●●●●" onChange={(e) => setPassword(e.target.value)} />
              </div>
              <div className="rchange-pass">
                  <span>{translation.new_password}</span>
                <input type="password" value={rpassword} placeholder="●●●●●●●●●●" autoComplete="new-password" onChange={(e) => setRPassword(e.target.value)} />
              </div>
            </div>

            <div className="more" style={{ borderTopLeftRadius: user ? "20px" : 0, borderTopRightRadius: user ? "20px" : 0 }}>
              <h3><b>{translation.more_information}</b></h3>
              <div className="about-us" onClick={() => navigate('/advisor_terms')}>{translation.advisor_terms_and_conditions}</div>
              <div className="terms-of-service" onClick={() => navigate('/terms_of_use')}>{translation.terms_of_use}</div>
              <div className="privacy-policy" onClick={() => navigate('/privacy_policy')}>{translation.privacy_policy}</div>
            </div>

            <div className="logout" onClick={() => Auth.logout()}>
              <HiOutlineLogout className="logout-icon" />
              <span><b>{translation.sign_out}</b></span>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default Settings