export const en = {
    "en": "En",
    "es": "Es",
    "sign_in": "Sign in",
    "sign_up": "Sign up",
    "forgot_password": "Forgot your password?",
    "enter_your_email": "Enter your email address to retrieve your password.",
    "enter_name_surname": "Enter your name and surname",
    "reset_password": "Reset password",
    "write_a_message": "Write a message..",
    "account": "Account",
    "or_sign_in": "-Or sign in with-",
    "or_sign_up": "-Or sign up with-",
    "sign_up_text": "You must be at least 18 years old to sign up for Zodiac Advisor. By signing up you agree to the <b> Privacy Policy</b> and <b>Terms of Service</b>",
    "create_account": "Create account",
    "coin": "Coin",
    "total_amount": "Total amount",
    "card_holder": "Card holder name",
    "card_number": "Card number",
    "expire_time": "Expire time",
    "cvv": "CVV",
    "cvv_text": "The last three digits on the back of the card.",
    "process_payment": "PROCESS PAYMENT",
    "january": "January",
    "february": "February",
    "march": "March",
    "april": "April",
    "may": "May",
    "june": "June",
    "july": "July",
    "august": "August",
    "september": "September",
    "october": "October",
    "november": "November",
    "december": "December",
    "reject": "Reject",
    "reject_text": "Your excuse message has to be more than 50 character.",
    "chat_call_text": "Someone wants to start a chat call with you!",
    "voice_call_text": "Someone wants to start a voice call with you!",
    "video_call_text": "Someone wants to start a video call with you!",
    "join_meeting": "Join meeting",
    "not_available": "I'm not available",
    "waiting": "Waiting",
    "about_zodiac_advisor": "ABOUT ZODIAC ADVISOR",
    "become_an_advisor": "BECOME AN ADVISOR",
    "how_it_works": "HOW IT WORKS",
    "advisor_terms_and_conditions": "Advisor terms and conditions",
    "about_us": "About Us",
    "contact_us": "Contact Us",
    "privacy_policy": "Privacy policy",
    "terms_of_use": "Terms of Use",
    "faq": "FAQ",
    "getting_started": "Getting started",
    "how_to_find_advisor": "How to find an advisor",
    "we_accept": "WE ACCEPT",
    "all_rights_reserved": "All rights reserved.",
    "get_coins": "Get coins",
    "see_more_advisors": "See more advisors",
    "all_advisors": "All advisors",
    "save": "Save",
    "search_or_start": "search or start a new chat",
    "online": "Online",
    "busy": "Busy",
    "offline": "Offline",
    "start_chat": "Start chat",
    "stop_meeting": "Stop meeting",
    "settings": "Settings",
    "name": "Name",
    "email": "Email",
    "password": "Password",
    "new_password": "New Password",
    "sign_out": "Sign out",
    "chat_box": "Chat box",
    "add_funds": "Add funds",
    "customer_support": "Customer support",
    "home": "Home",
    "categories": "Categories",
    "see_all": "See all",
    "logout": "Logout",
    "language": "Language",
    "select_language": "Select your language",
    "specialites": "Specialites",
    "reviews": "Reviews",
    "see_more": "See more",
    "about_me": "About me",
    "about_my_services": "About my services",
    "chat": "Chat",
    "voice": "Voice",
    "voice_call": "Voice call",
    "video": "Video",
    "video_call": "Video call",
    "live": "Live",
    "all": "All",
    "recommended": "Recommended for you",
    "recommended_text": "Find your recommended psychics to learn the secret of your life. Many psychics are here to help you, they are experts in their fields. Browse them, read the their fields, and pick one of them!",
    "trending": "Trending",
    "trending_text": "Learn the secret of your life ! Get a psychic reading from one of amazing psychics. Ask your question now! From your love life to family life, or birth and natal chart and more…",
    "love_readings": "Love readings",
    "palm_readings": "Palm readings",
    "tarot_readings": "Tarot readings",
    "psychics_readings": "Psychics readings",
    "astrology_horoscopes": "Astrology & horoscopes",
    "dream_analysis": "Dream analysis",
    "clairvoyance": "Clairvoyance",
    "career_readings": "Career readings",
    "love": "Love",
    "palm": "Palm",
    "tarot": "Tarot",
    "psychic": "Psychic",
    "astrology": "Astrology",
    "dream": "Dream",
    "career": "Career",
    "since": "since",
    "reading": "reading",
    "love_description": "We know that love is always complicated issue. Get a forecast of your love life with our experienced psychics. Approach your romantic life with the confidence. Find the best fortune teller for you, and bring clarity and balance to your love life.",
    "psychic_description": "Are you going through a turbulent phase of your life? Are you having trouble finding a solution? Zodiac Advisor is here to help you. Get reading now with our experienced psychics and overcome all your troubles.",
    "tarot_description": " Tarot Card readings for every topic - love, career, or any unanswered question! Many psychics use tarot readings to find meaning and new perspectives on work, relationship, love and life’s mysteries. Get reading now with one of our Tarot expert. Learn the secret!",
    "palm_description": "You actually hold the secrets of your life in your hands, all in the palm of your hand. Our amazing palm readers will analyze your palm and reach the most accurate conclusions about your past, present and the future. Get reading now!",
    "astrology_description": "Our destiny is written in the stars the moment we were born. Find out your Natal - Birth Chart. Talk with the gifted astrologer today to discover what star stuff you are made of. Learn deeply your horoscopes and signs.",
    "clairvoyance_description": "Enhance your magical life with our experienced clairvoyants. Feel the energy, learn the energy fields. Increase your mind power and connect your spirit guide. Get reading today!",
    "career_description": "Career readings will give you insight and advice to help smooth out and strengthen your professional path! Reveal your best possible outcome for any career dilemma. Get reading now with our experienced psychics.",
    "best_advisor": "Best advisor of the week",
    "description": "Description",
    "guarantee": "100% Satisfaction Guaranteed",
    "free": "3 FREE MIN + 60% OFF",
    "enjoy_reading": "ENJOY YOUR READING",
    "use_coupon": "Use coupon now!",
    "how_to_find_yourself": "How to find yourself",
    "new_advisors": "New advisors",
    "favourite_advisors": "Favourite advisors",
    "my_favourites": "My favourites",
    "not_favourites_yet": "Not favourites yet",
    "tap_on_the_heart": "Tap on the heart in advisor profiles to save them to your favourites.",
    "meeting_end": "Awesome, your meeting was ended!",
    "share_experience": "Could you take some time to share your experience with us?",
    "submit": "Submit",
    "maybe_later": "Maybe Later",
    "from": "from",
    "filters": "Filters",
    "not_found": "Ooops! Page not found.",
    "go_home": "Go home",
    "more_information": "More information",
    "apply": "Apply",
    "show_offline": "Show offline",
    "show_only": "Show only",
    "number_of_reviews": "Number of reviews",
    "price_per_minute": "Price per minute",
    "over": "Over",
    "live_chat": "Live chat",
    "any": "Any",
    "reading_since": "Reading since",
    "didnt_find": "Didn't find what you were looking for?",
    "clear_search": "Clear search filters to see more relevant advisors!",
    "clear_filters": "Clear filters",
    "cookies": "We use cookies to make interactions with our websites and services easy and meaningful. To learn more about the cookies we use or to find out how you can disable cookies, click here.",
    "accept": "Accept",
    "decline": "Decline",
    "select_language": "Select your language:",
    "success_purchase": "Your purchase was completed successfully.",
    "fail_purchase": "There was a problem purchasing.",
    "contact_us": "Contact us",
    "staff": "staff",
    "question": "Question",
    "question_placeholder": "Your questions must be longer than 20 characters...",
    "first_name": "First name",
    "surname": "Surname",
    "close": "Close",
    "send_message": "Send message",
    "message_success": "Your message was registered successfully.",
    "message_fail": "There was a problem, please try again.",
    "reset_success": "Your password successfuly changed.",
    "reset_fail_field": "Password fields not same!",
    "reset_fail_default": "An unknown problem accured when change password.", 
    "personal": "Personal",
    "didnt_find_advisors_with_discount": "Didn't find advisors with discount.",
    "advisors_with_discount": "Advisors with discount",
    "wrong_password": "Wrong password",
    "your_current_password": "Tu contraseña actual",
    "for_advisors": "For advisors",
    "already_have": "Do you have an account?",
    "dont_have": "Dont have an account?",
    "dont_have_coin": "You do not have enough coin",
    "cancel_call": "Cancel call",
    "advisor_busy": "ADVISOR IS BUSY",
    "message_from_advisor": "Message from advisor",
    "reject_meeting": "Reject meeting",
    "reject": "Reject",
    "do_you_know": "Do you know?",
    "average_meeting": "An interview takes an average of 17 minutes. You can load coins so that your call is not interrupted.",
    "continue": "Continue",
    "meeting_ended": "Awesome, your meeting was ended!",
    "could_you_share_experience": "Could you take some time to share your experience with us?",
    "maybe_later": "Maybe later",
    "total_earned": "Total earned",
    "get_more_coins_to_continue": "Get more coins to continue video chatting",
    "join_us": "Join us",
    "faq": "Frequently Asked Questions"
}