import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { doc, updateDoc } from 'firebase/firestore';
import { set, ref } from 'firebase/database';
import { MdOutlineBusinessCenter } from 'react-icons/md';
import { AiOutlineExperiment } from 'react-icons/ai';
import { RiProfileLine } from 'react-icons/ri';
import firebase from '../../config/firebase';
import './advisormanage.scss';
import Storage from '../../functions/storage';
import DateHooks from '../../hooks/dateHook';

const AdvisorManage = () => {

    const { user } = useSelector(state => state.site);
    const [image, setImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [year, setYear] = useState('');
    const [description, setDescription] = useState('');
    const [aboutme, setAboutme] = useState('');
    const [currentTab, setCurrentTab] = useState(0);
    const [isChatActive, setIsChatActive] = useState(false);
    const [isVoiceActive, setIsVoiceActive] = useState(false);
    const [isVideoActive, setIsVideoActive] = useState(false);
    const [chatPrice, setChatPrice] = useState(0);
    const [voicePrice, setVoicePrice] = useState(0);
    const [videoPrice, setVideoPrice] = useState(0);
    const [chatDiscountPrice, setChatDiscountPrice] = useState(0);
    const [voiceDiscountPrice, setVoiceDiscountPrice] = useState(0);
    const [videoDiscountPrice, setVideoDiscountPrice] = useState(0);
    const [welcomeMessage, setWelcomeMessage] = useState('');
    const [selectedSkillFromSkillList, setSelectedSkillFromSkillList] = useState('');
    const [selectedSkill, setSelectedSkill] = useState('');
    const [currentSkills, setCurrentSkills] = useState([]);

    const [isProfileChanged, setIsProfileChanged] = useState(false);
    const [isExperienceChanged, setIsExperienceChanged] = useState(false);
    const [isBusinessChanged, setIsBusinessChanged] = useState({
        welcome_message: false,
        chat: false,
        voice: false,
        video: false
    });

    //const [liveModes, setLiveModes] = useState(null);
    const navigate = useNavigate();

    const onFileChange = event => {
        const imageFile = event.target.files[0];
        const imageURL = URL.createObjectURL(imageFile);
        setImage({
            imageURL: imageURL,
            file: imageFile,
        });
    };
    const onInputClick = event => event.target.value = '';
    const handleFirstName = e => setFirstName(e.target.value);
    const handleLastName = e => setLastName(e.target.value);
    const handleEmail = e => setEmail(e.target.value);
    const handlePassword = e => setPassword(e.target.value);
    const handleYear = e => setYear(e.target.value);
    const handleDescription = e => setDescription(e.target.value);
    const handleAboutme = e => setAboutme(e.target.value);
    const handleWelcomeMessage = e => setWelcomeMessage(e.target.value);

    const handleChatPrice = e => {
        setChatPrice(parseInt(e.target.value));
        setChatDiscountPrice(parseInt(e.target.value));
    };
    const handleVoicePrice = e => {
        setVoicePrice(parseInt(e.target.value));
        setVoiceDiscountPrice(parseInt(e.target.value));
    };
    const handleVideoPrice = e => {
        setVideoPrice(parseInt(e.target.value));
        setVideoDiscountPrice(parseInt(e.target.value));
    };

    const handleChatDiscountPrice = e => {
        const inputValue = parseInt(e.target.value);
        if (inputValue >= chatPrice) {
            setChatDiscountPrice(chatPrice - 10);
        } else {
            setChatDiscountPrice(inputValue);
        }
    };
    const handleVoiceDiscountPrice = e => {
        const inputValue = parseInt(e.target.value);
        if (inputValue >= voicePrice) {
            setVoiceDiscountPrice(voicePrice - 10);
        } else {
            setVoiceDiscountPrice(inputValue);
        }
    };
    const handleVideoDiscountPrice = e => {
        const inputValue = parseInt(e.target.value);
        if (inputValue >= videoPrice) {
            setVideoDiscountPrice(videoPrice - 10);
        } else {
            setVideoDiscountPrice(inputValue);
        }
    };

    const minDiscountPrice = 20;

    const priceList = [
        10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000,
    ];

    const skillList = [
        "Love", "Palm", "Tarot", "Astrology", "Dream", "Psychic", "Clairvoyance", "Career"
    ];

    const yearList = [
        1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977, 1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022
    ];

    useEffect(() => {
        if (user) {
            setYear(DateHooks.getDateFromSec(user.created_at.seconds).getFullYear());
            const fullName = user.profile.name;
            setFirstName(fullName.split(' ')[0]);
            setLastName(fullName.split(' ').length > 1 ? fullName.split(' ')[1] : "");
        }
    }, [user && user.profile && user.profile.name]);

    useEffect(() => {
        if (user && user.profile && user.profile.email) {
            setEmail(user.profile.email);
        }
    }, [user && user.profile && user.profile.email]);

    useEffect(() => {
        if (user && user.profile && user.profile.password) {
            setPassword(user.profile.password);
        }
    }, [user && user.profile && user.profile.password]);

    useEffect(() => {
        if (user && user.profile && user.profile.description) {
            setDescription(user.profile.description);
        }
    }, [user && user.profile && user.profile.description]);

    useEffect(() => {
        if (user && user.profile && user.profile.about_me) {
            setAboutme(user.profile.about_me);
        }
    }, [user && user.profile && user.profile.about_me]);

    useEffect(() => {
        if (user && user.profile && user.profile.skills) {
            setCurrentSkills(user.profile.skills);
        }
    }, [user && user.profile && user.profile.skills]);

    useEffect(() => {
        if (user && user.profile && user.profile.pic_url) {
            setImage({
                imageURL: user.profile.pic_url,
                file: "",
            });
        }
    }, [user && user.profile && user.profile.pic_url]);

    useEffect(() => {
        if (user && user.live_modes) {
            setWelcomeMessage(user.live_modes.welcome_message);
        }
    }, [user && user.live_modes]);

    useEffect(() => {
        if (user && user.live_modes) {
            setIsChatActive(user.live_modes.chat.active);
        }
    }, [user && user.live_modes && user.live_modes.chat.active]);

    useEffect(() => {
        if (user && user.live_modes) {
            setIsVoiceActive(user.live_modes.voice.active);
        }
    }, [user && user.live_modes && user.live_modes.voice.active]);

    useEffect(() => {
        if (user && user.live_modes) {
            setIsVideoActive(user.live_modes.video.active);
        }
    }, [user && user.live_modes && user.live_modes.video.active]);

    useEffect(() => {
        console.log("--Chat price changed, new chat price value setted--");
        if (user && user.live_modes && user.live_modes.chat) {
            setChatPrice(user.live_modes.chat.price.normal_price);
        }
    }, [user && user.live_modes && user.live_modes.chat.price]);

    useEffect(() => {
        console.log("--Voice price changed, new voice price value setted--");
        if (user && user.live_modes && user.live_modes.voice) {
            setVoicePrice(user.live_modes.voice.price.normal_price);
        }
    }, [user && user.live_modes && user.live_modes.voice.price]);

    useEffect(() => {
        console.log("--Video price changed, new video price value setted--");
        if (user && user.live_modes && user.live_modes.video) {
            setVideoPrice(user.live_modes.video.price.normal_price);
        }
    }, [user && user.live_modes && user.live_modes.video.price]);

    useEffect(() => {
        if (user && user.live_modes && user.live_modes.chat) {
            setChatDiscountPrice(user.live_modes.chat.price.discount_price);
        }
    }, [user && user.live_modes && user.live_modes.chat.price]);

    useEffect(() => {
        if (user && user.live_modes && user.live_modes.voice) {
            setVoiceDiscountPrice(user.live_modes.voice.price.discount_price);
        }
    }, [user && user.live_modes && user.live_modes.voice.price]);

    useEffect(() => {
        if (user && user.live_modes && user.live_modes.video) {
            setVideoDiscountPrice(user.live_modes.video.price.discount_price);
        }
    }, [user && user.live_modes && user.live_modes.video.price]);

    useEffect(() => {
        if (user && ((image.file) || (`${firstName} ${lastName}` !== user.profile.name) || (email !== user.profile.email) || (password !== user.profile.password))) {
            console.log(image.file, firstName, lastName)
            setIsProfileChanged(true);
        } else {
            setIsProfileChanged(false);
        }
    }, [image, firstName, lastName, email, password, user && user.profile.pic_url, user && user.profile.name, user && user.profile.email, user && user.profile.password]);

    useEffect(() => {
        if (user) {
            if ((year !== DateHooks.getDateFromSec(user.created_at.seconds).getFullYear()) || (description !== user.profile.description) || (aboutme !== user.profile.about_me)) {
                //(currentSkills.sort().join('') !== user.profile.skills.sort().join(''))
                setIsExperienceChanged(true);
            } else {
                setIsExperienceChanged(false);
            }
        }
    }, [year, description, aboutme, currentSkills, user && user.created_at.seconds, user && user.profile.description, user && user.profile.about_me, user && user.profile.skills]);

    useEffect(() => {
        if (user && (user.live_modes.welcome_message !== welcomeMessage)) {
            setIsBusinessChanged(prev => ({ ...prev, welcome_message: true }));
        } else {
            setIsBusinessChanged(prev => ({ ...prev, welcome_message: false }));
        }
    }, [welcomeMessage, user && user.live_modes.welcome_message]);

    useEffect(() => {
        if (user && ((isChatActive !== user.live_modes.chat.active) || (chatPrice !== user.live_modes.chat.price.normal_price) || (chatDiscountPrice !== user.live_modes.chat.price.discount_price))) {
            setIsBusinessChanged(prev => ({ ...prev, chat: true }));
        } else {
            setIsBusinessChanged(prev => ({ ...prev, chat: false }));
        }
    }, [isChatActive, chatPrice, chatDiscountPrice, user && user.live_modes.chat.active, user && user.live_modes.chat.price.normal_price, user &&  user.live_modes.chat.price.discount_price]);

    useEffect(() => {
        if (user && ((isVoiceActive !== user.live_modes.voice.active) || (voicePrice !== user.live_modes.voice.price.normal_price) || (voiceDiscountPrice !== user.live_modes.voice.price.discount_price))) {
            setIsBusinessChanged(prev => ({ ...prev, voice: true }));
        } else {
            setIsBusinessChanged(prev => ({ ...prev, voice: false }));
        }
    }, [isVoiceActive, voicePrice, voiceDiscountPrice, user && user.live_modes.voice.active, user && user.live_modes.voice.price.normal_price, user && user.live_modes.voice.price.discount_price]);

    useEffect(() => {
        if (user && ((isVideoActive !== user.live_modes.video.active) || (videoPrice !== user.live_modes.video.price.normal_price) || (videoDiscountPrice !== user.live_modes.video.price.discount_price))) {
            setIsBusinessChanged(prev => ({ ...prev, video: true }));
        } else {
            setIsBusinessChanged(prev => ({ ...prev, video: false }));
        }
    }, [isVideoActive, videoPrice, videoDiscountPrice, user && user.live_modes.video.active, user && user.live_modes.video.price.normal_price, user && user.live_modes.video.price.discount_price]);

    // Remove footer
    useEffect(() => {
        const endSection = document.getElementById('end-section');
        endSection.style.display = "none";

        return () => {
            if (endSection) {
                endSection.style.display = "flex";
            }
        }
    }, []);

    useEffect(() => {
        // setTimeout(() => {
        //     if(!user || (user && user.user_type !== "advisor")) {
        //         console.log(user);
        //         navigate('/');
        //     }
        // }, 500);
    }, [user]);



    async function saveChangesForProfile() {
        try {
            const profileName = `${firstName} ${lastName}`;
            if (image.file) {
                console.log(image.file);
                await Storage.uploadImage(image.file).then(async function (imageURL) {
                    console.log(imageURL);
                    await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                        'profile.name': profileName,
                        'profile.pic_url': imageURL,
                    });
                });
            } else {
                await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                    'profile.name': profileName,
                });
            }
            console.log("--Profile successfully updated--");
        } catch (error) {
            console.log("--Some error accured while updating profile data--");
            console.log("Error: ", error);
        }
    }

    async function saveChangesForExperience() {
        try {
            await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                'profile.description': description,
                'profile.about_me': aboutme,
                'profile.skills': currentSkills,
            });
            console.log("--Experience successfully updated--");
        } catch (error) {
            console.log("--Some error accured while updating experience data--");
            console.log("Error: ", error);
        }
    }

    function toggleChatDiscountContainer() {
        const discountContainer = document.getElementById("chat-discount");
        if (discountContainer.style.display === "none") {
            setChatDiscountPrice(chatPrice / 2);
            discountContainer.style.display = "block";
        } else {
            setChatDiscountPrice(chatPrice);
            discountContainer.style.display = "none";
        }
    }

    function toggleVoiceDiscountContainer() {
        const discountContainer = document.getElementById("voice-discount");
        if (discountContainer.style.display === "none") {
            setVoiceDiscountPrice(voicePrice / 2);
            discountContainer.style.display = "block";
        } else {
            setVoiceDiscountPrice(voicePrice);
            discountContainer.style.display = "none";
        }
    }

    function toggleVideoDiscountContainer() {
        const discountContainer = document.getElementById("video-discount");
        if (discountContainer.style.display === "none") {
            setVideoDiscountPrice(videoPrice / 2);
            discountContainer.style.display = "block";
        } else {
            setVideoDiscountPrice(videoPrice);
            discountContainer.style.display = "none";
        }
    }

    async function saveChangesForVideoPrice() {
        // Make changes
        try {
            const discountPercent = (((videoPrice - videoDiscountPrice) / videoPrice) * 100).toFixed(0);

            await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                'live_modes.video': {
                    active: isVideoActive,
                    price: {
                        discount_percent: discountPercent,
                        discount_price: videoDiscountPrice,
                        discount_title: videoPrice !== videoDiscountPrice ? "normal" : "none",
                        normal_price: videoPrice,
                    }
                }
            });

            if(videoDiscountPrice < videoPrice) {
                const databaseFieldId = getUniqeStringFieldId()
                const databaseRef = ref(firebase.rDb, `promotions/video/${databaseFieldId}`);

                await set(databaseRef, {
                    id: user.uid,
                    name: user.profile.name,
                    price_before: videoPrice, 
                    price_after: videoDiscountPrice,
                    discount_percent: discountPercent,
                });
            }

            console.log("--Video price changed--");
            console.log("--Normal price--", videoPrice);
            console.log("--Discount price--", videoDiscountPrice);
        } catch (error) {
            console.log("--Some error accured while changing price--");
            console.log("Error: ", error);
        }
    }

    async function saveChangesForVoicePrice() {
        // Make changes
        try {
            const discountPercent = (((voicePrice - voiceDiscountPrice) / voicePrice) * 100).toFixed(0);

            await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                'live_modes.voice': {
                    active: isVoiceActive,
                    price: {
                        discount_percent: discountPercent,
                        discount_price: voiceDiscountPrice,
                        discount_title: voicePrice !== voiceDiscountPrice ? "normal" : "none",
                        normal_price: voicePrice,
                    }
                }
            });

            if(voiceDiscountPrice < voicePrice) {
                const databaseFieldId = getUniqeStringFieldId();
                const databaseRef = ref(firebase.rDb, `promotions/voice/${databaseFieldId}`);

                await set(databaseRef, {
                    id: user.uid,
                    name: user.profile.name,
                    price_before: voicePrice, 
                    price_after: voiceDiscountPrice,
                    discount_percent: discountPercent,
                });
            }

            console.log("--Video price changed--");
            console.log("--Normal price--", voicePrice);
            console.log("--Discount price--", voiceDiscountPrice);
        } catch (error) {
            console.log("--Some error accured while changing price--");
            console.log("Error: ", error);
        }
    }

    async function saveChangesForChatPrice() {
        // Make changes
        try {
            const discountPercent = (((chatPrice - chatDiscountPrice) / chatPrice) * 100).toFixed(0);

            await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                'live_modes.chat': {
                    active: isChatActive,
                    price: {
                        discount_percent: discountPercent,
                        discount_price: chatDiscountPrice,
                        discount_title: chatPrice !== chatDiscountPrice ? "normal" : "none",
                        normal_price: chatPrice,
                    }
                }
            });

            if(chatDiscountPrice < chatPrice) {
                const databaseFieldId = getUniqeStringFieldId();
                const databaseRef = ref(firebase.rDb, `promotions/chat/${databaseFieldId}`);
    
                await set(databaseRef, {
                    id: user.uid,
                    name: user.profile.name,
                    price_before: chatPrice, 
                    price_after: chatDiscountPrice,
                    discount_percent: discountPercent,
                });
            }

            console.log("--Video price changed--");
            console.log("--Normal price--", chatPrice);
            console.log("--Discount price--", chatDiscountPrice);
        } catch (error) {
            console.log("--Some error accured while changing price--");
            console.log("Error: ", error);
        }
    }

    async function saveChangesForWelcomeMessage() {
        await updateDoc(doc(firebase.db, `users/${user.uid}`), {
            'live_modes.welcome_message': welcomeMessage
        });
    }

    const getUniqeStringFieldId = () => {
        return new Date().getTime().toString();                             
    }

    return (
        user && user.user_type === "advisor" && (
            <div className="advisor-manage">
                <div className="container">

                    <div className="tabs noselect">
                        <div className="profile-tab tab" onClick={() => setCurrentTab(0)}>
                            <RiProfileLine className="tab-icon" style={{ color: currentTab === 0 ? "var(--theme-color)" : "var(--default-text-color)" }} />
                            <span className="tab-name" style={{ color: currentTab === 0 ? "var(--theme-color)" : "var(--default-text-color)" }}>Profile</span>
                        </div>
                        <div className="experience-tab tab" onClick={() => setCurrentTab(1)}>
                            <AiOutlineExperiment className="tab-icon" style={{ color: currentTab === 1 ? "var(--theme-color)" : "var(--default-text-color)" }} />
                            <span className="tab-name" style={{ color: currentTab === 1 ? "var(--theme-color)" : "var(--default-text-color)" }}>Experience</span>
                        </div>
                        <div className="business-tab tab" onClick={() => setCurrentTab(2)}>
                            <MdOutlineBusinessCenter className="tab-icon" style={{ color: currentTab === 2 ? "var(--theme-color)" : "var(--default-text-color)" }} />
                            <span className="tab-name" style={{ color: currentTab === 2 ? "var(--theme-color)" : "var(--default-text-color)" }}>Business</span>
                        </div>
                    </div>

                    <div className="edit-pages">
                        <div className="profile-page edit-page" id="profile-page" style={{ display: currentTab === 0 ? "block" : "none" }}>
                            <div className="header">
                                <h2 className="head">EDIT PROFILE</h2>
                                <div className="save-changes-button noselect" style={{
                                    opacity: isProfileChanged ? "1" : ".3",
                                    pointerEvents: isProfileChanged ? "all" : "none"
                                }} onClick={saveChangesForProfile}>Save changes
                                </div>
                            </div>
                            <div className="img edit-area">
                                <img src={image.imageURL} alt="" />
                                <div className="col">
                                    <label className="img-input-button noselect">
                                        <input type="file" onChange={onFileChange} onClick={onInputClick} accept="image/*" id="img-input" className="img-input" />
                                        <span>Choose a image</span>
                                    </label>
                                    <span className="info-text">Acceptable formats .jpg .png only</span>
                                    <span className="info-text">Max file size is 500kb min size is 10kb</span>
                                </div>
                            </div>
                            <div className="personal edit-area">
                                <h3>PERSONAL INFORMATION</h3>
                                <div className="input-container">
                                    <div className="col_">
                                        <span className="input-label">First Name</span>
                                        <input type="text" name="FirstName" className="firstName" onChange={handleFirstName} value={firstName} />
                                    </div>
                                    <div className="col_">
                                        <span className="input-label">Last Name</span>
                                        <input type="text" name="LastName" className="firstName" onChange={handleLastName} value={lastName} />
                                    </div>
                                </div>
                            </div>
                            <div className="account edit-area">
                                <h3>ACCOUNT INFORMATION</h3>
                                <div className="input-container">
                                    <div className="col_">
                                        <span className="input-label">E-mail</span>
                                        <input type="text" name="FirstName" className="firstName" onChange={handleEmail} value={email} />
                                    </div>
                                    <div className="col_">
                                        <span className="input-label">Password</span>
                                        <input type="text" name="LastName" className="firstName" onChange={handlePassword} value={password} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="experience-page edit-page" id="experience-page" style={{ display: currentTab === 1 ? "block" : "none" }}>
                            {/* <div className="permission-page">
                                <div className="permission-container noselect">
                                    <span>You need a permission to edit this area!</span>
                                    <div className="get-permission-button">Get permission</div>
                                </div>
                            </div> */}
                            <div className="header">
                                <h2 className="head">EDIT EXPERIENCE</h2>
                                <div className="save-changes-button noselect" style={{ opacity: isExperienceChanged ? "1" : ".3", pointerEvents: isExperienceChanged ? "all" : "none" }} onClick={saveChangesForExperience}>Save changes</div>
                            </div>
                            <p className="edit-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta totam accusamus beatae error dignissimos obcaecati. Pariatur mollitia corporis voluptas voluptates?</p>
                            <div className="advisor-information edit-area">
                                <h3>ADVISOR INFORMATION</h3>
                                <div className="row_">
                                    <div className="col_">
                                        <span className="input-label">Since for</span>
                                        <select onChange={handleYear} value={year}>
                                            {
                                                yearList.map(function (year) {
                                                    return (
                                                        <option value={year} selected={DateHooks.getDateFromSec(user.created_at.seconds).getFullYear() === year}>{year}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <span className="info">Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum, mollitia!</span>
                                </div>
                                <div className="col_">
                                    <div className="col_ description-edit">
                                        <span className="input-label">Description</span>
                                        <textarea className="description-textarea" name="description-textarea" id="description-textarea" cols="30" rows="10" spellcheck="false" onChange={handleDescription} value={description}></textarea>
                                    </div>
                                    <div className="col_ aboutme-edit">
                                        <span className="input-label">About me</span>
                                        <textarea className="aboutme-textarea" name="aboutme-textarea" id="aboutme-textarea" cols="30" rows="10" spellcheck="false" onChange={handleAboutme} value={aboutme}></textarea>
                                    </div>
                                </div>
                                <div className="skills-information edit-area"></div>
                                <h3>SKILLS</h3>
                                <div className="skills-edit">
                                    <div className="skills noselect noscrollbar" style={{ marginBottom: "10px" }}>
                                        {
                                            skillList.map(function (skill, index) {

                                                const isSelected = selectedSkillFromSkillList === skill;

                                                return (
                                                    <div key={index} className="skill" style={{ background: `var(--${skill.toLowerCase()}-card-color)`, color: `var(--${skill.toLowerCase()}-text-color)`, border: isSelected ? `1px solid var(--${skill.toLowerCase()}-text-color)` : "1px solid #fff" }} onClick={() => setSelectedSkillFromSkillList(skill)}>{skill}</div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="skills-control row_">
                                        <p>Your skills</p>
                                        <div className="row_">
                                            <BiPlus className="icon" style={{ opacity: selectedSkillFromSkillList ? currentSkills.includes(selectedSkillFromSkillList) ? ".3" : "1" : ".3", pointerEvents: selectedSkillFromSkillList ? currentSkills.includes(selectedSkillFromSkillList) ? "none" : "all" : "none" }} onClick={() => {
                                                setCurrentSkills(prev => [...prev, selectedSkillFromSkillList]);
                                                setSelectedSkillFromSkillList('');
                                            }} />
                                            <BiMinus className="icon" style={{ opacity: selectedSkill ? currentSkills.length > 1 ? "1" : ".3" : ".3", pointerEvents: selectedSkill ? currentSkills.length > 1 ? "all" : "none" : "none" }} onClick={() => {
                                                if (currentSkills.length > 1) {
                                                    setCurrentSkills(prev => {
                                                        const index = prev.indexOf(selectedSkill);
                                                        prev.splice(index, 1);
                                                        return prev;
                                                    });
                                                    setSelectedSkill('');
                                                }
                                            }} />
                                        </div>
                                    </div>
                                    <div className="skills noselect noscrollbar">
                                        {
                                            currentSkills.map(function (skill, index) {

                                                const skillName = skill.split(' ')[0];
                                                const isSelected = selectedSkill === skillName;

                                                return (
                                                    <div key={index} className="skill" style={{ background: `var(--${skillName.toLowerCase()}-card-color)`, color: `var(--${skillName.toLowerCase()}-text-color)`, border: isSelected ? `1px solid var(--${skillName.toLowerCase()}-text-color)` : "1px solid #fff" }} onClick={() => setSelectedSkill(skillName)}>{skill}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="business-page edit-page" id="business-page" style={{ display: currentTab === 2 ? "block" : "none" }}>
                            {/* <div className="permission-page">
                                <div className="permission-container noselect">
                                    <span>You need a permission to edit this area!</span>
                                    <div className="get-permission-button">Get permission</div>
                                </div>
                            </div> */}
                            <div className="header">
                                <h2 className="head">EDIT BUSINESS</h2>
                                {/* <div className="save-changes-button noselect">Save changes</div> */}
                            </div>
                            <p className="edit-description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta totam accusamus beatae error dignissimos obcaecati. Pariatur mollitia corporis voluptas voluptates?</p>
                            <div className="stream-information edit-area">
                                <h3>STREAM INFORMATION</h3>
                                <div className="stream">

                                    <div className="welcome-message-edit">
                                        <div className="row_">
                                            <label>Welcome message</label>
                                            <div className="save-button noselect" style={{ opacity: isBusinessChanged.welcome_message ? "1" : ".3", pointerEvents: isBusinessChanged.welcome_message ? "all" : "none" }} onClick={saveChangesForWelcomeMessage}>Save changes</div>
                                        </div>
                                        <textarea name="welcome-message" id="welcome-message" cols="30" rows="10" value={welcomeMessage} onChange={handleWelcomeMessage}></textarea>
                                    </div>

                                    <div className="chat-edit stream-edit">
                                        <div className="row_">
                                            <div className="row_">
                                                <div className="stream-img-container" style={{ background: isChatActive ? "var(--chat-active-color)" : "var(--manage-disabled-color)" }}>
                                                    <div className="chat-img stream-img" />
                                                </div>
                                                <span style={{ color: isChatActive ? "var(--chat-active-color)" : "var(--manage-disabled-color)" }} className="stream-name">Chat</span>
                                            </div>
                                            <div className="toggle-button" onClick={() => setIsChatActive(prev => !prev)} style={{ background: isChatActive ? "rgba(var(--chat-active-color-rgb), .5)" : "white" }}>
                                                <div className="circle noselect" style={{ background: isChatActive ? "var(--chat-active-color)" : "white", left: isChatActive ? "calc(100% - 20px)" : "0" }} />
                                            </div>
                                        </div>

                                        <div className="edit-container">
                                            <div className="save-button noselect" style={{ opacity: isBusinessChanged.chat ? "1" : ".3", pointerEvents: isBusinessChanged.chat ? "all" : "none" }} onClick={saveChangesForChatPrice}>Save changes</div>

                                            <div className="price-container noselect">
                                                <p>Price:</p>
                                                <div className="price">
                                                    <select onChange={handleChatPrice} value={chatPrice}>
                                                        {
                                                            priceList.map(function (price) {

                                                                return (
                                                                    <option selected={price === voicePrice} value={price}>{`${price} coin`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="price-amount">{chatPrice}</span>
                                                    <img className="coin-img" src={require('../../assets/ic_coin.png')} alt="" />
                                                </div>
                                            </div>

                                            <div className="add-discount noselect" onClick={toggleChatDiscountContainer}>{chatPrice !== chatDiscountPrice ? "Remove discount" : "Add discount"}</div>

                                            <div className="discount-price" id="chat-discount" style={{ display: chatPrice !== chatDiscountPrice ? "block" : "none" }}>
                                                <h4>Discount</h4>
                                                <div className="row_">
                                                    <span className="discount-price">Discount price:</span>
                                                    <input type="number" name="discount-price" value={chatDiscountPrice} onChange={handleChatDiscountPrice} />
                                                </div>

                                                <div className="row_">
                                                    <span className="discount-amount">Discount amount:</span>
                                                    <input type="number" name="discount-amount" value={chatPrice - chatDiscountPrice} onChange={null} />
                                                </div>

                                                <div className="row_">
                                                    <span className="discount-percent">Discount percent(%):</span>
                                                    <input type="number" name="discount-percent" value={(((chatPrice - chatDiscountPrice) / chatPrice) * 100).toFixed(0)} onChange={null} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="voice-edit stream-edit">
                                        <div className="row_">
                                            <div className="row_">
                                                <div className="stream-img-container" style={{ background: isVoiceActive ? "var(--voice-active-color)" : "var(--manage-disabled-color)" }}>
                                                    <div className="voice-img stream-img" />
                                                </div>

                                                <span style={{ color: isVoiceActive ? "var(--voice-active-color)" : "var(--manage-disabled-color)" }} className="stream-name">Voice</span>
                                            </div>
                                            <div className="toggle-button" onClick={() => setIsVoiceActive(prev => !prev)} style={{ background: isVoiceActive ? "rgba(var(--voice-active-color-rgb), .5)" : "white" }}>
                                                <div className="circle noselect" style={{ background: isVoiceActive ? "var(--voice-active-color)" : "white", left: isVoiceActive ? "calc(100% - 20px)" : "0" }} />
                                            </div>
                                        </div>

                                        <div className="edit-container">
                                            <div className="save-button noselect" style={{ opacity: isBusinessChanged.voice ? "1" : ".3", pointerEvents: isBusinessChanged.voice ? "all" : "none" }} onClick={saveChangesForVoicePrice}>Save changes</div>

                                            <div className="price-container noselect">
                                                <p>Price:</p>
                                                <div className="price">
                                                    <select onChange={handleVoicePrice} value={voicePrice}>
                                                        {
                                                            priceList.map(function (price) {

                                                                return (
                                                                    <option selected={price === voicePrice} value={price}>{`${price} coin`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="price-amount">{voicePrice}</span>
                                                    <img className="coin-img" src={require('../../assets/ic_coin.png')} alt="" />
                                                </div>
                                            </div>

                                            <div className="add-discount noselect" onClick={toggleVoiceDiscountContainer}>{voicePrice !== voiceDiscountPrice ? "Remove discount" : "Add discount"}</div>

                                            <div className="discount-price" id="voice-discount" style={{ display: voicePrice !== voiceDiscountPrice ? "block" : "none" }}>
                                                <h4>Discount</h4>
                                                <div className="row_">
                                                    <span className="discount-price">Discount price:</span>
                                                    <input type="number" name="discount-price" value={voiceDiscountPrice} onChange={handleVoiceDiscountPrice} />
                                                </div>

                                                <div className="row_">
                                                    <span className="discount-amount">Discount amount:</span>
                                                    <input type="number" name="discount-amount" value={voicePrice - voiceDiscountPrice} />
                                                </div>

                                                <div className="row_">
                                                    <span className="discount-percent">Discount percent(%):</span>
                                                    <input type="number" name="discount-percent" value={(((voicePrice - voiceDiscountPrice) / voicePrice) * 100).toFixed(0)} />
                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                    <div className="video-edit stream-edit">
                                        <div className="row_">
                                            <div className="row_">
                                                <div className="stream-img-container" style={{ background: isVideoActive ? "var(--video-active-color)" : "var(--manage-disabled-color)" }}>
                                                    <div className="video-img stream-img" />
                                                </div>
                                                <span style={{ color: isVideoActive ? "var(--video-active-color)" : "var(--manage-disabled-color)" }} className="stream-name">Video</span>
                                            </div>
                                            <div className="toggle-button" onClick={() => setIsVideoActive(prev => !prev)} style={{ background: isVideoActive ? "rgba(var(--video-active-color-rgb), .5)" : "white" }}>
                                                <div className="circle noselect" style={{ background: isVideoActive ? "var(--video-active-color)" : "white", left: isVideoActive ? "calc(100% - 20px)" : "0" }} />
                                            </div>
                                        </div>

                                        <div className="edit-container">
                                            <div className="save-button noselect" style={{ opacity: isBusinessChanged.video ? "1" : ".3", pointerEvents: isBusinessChanged.video ? "all" : "none" }} onClick={saveChangesForVideoPrice}>Save changes</div>

                                            <div className="price-container noselect">
                                                <p>Price:</p>
                                                <div className="price">
                                                    <select onChange={handleVideoPrice} value={videoPrice}>
                                                        {
                                                            priceList.map(function (price) {

                                                                return (
                                                                    <option selected={price === videoPrice} value={price}>{`${price} coin`}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="price-amount">{videoPrice}</span>
                                                    <img className="coin-img" src={require('../../assets/ic_coin.png')} alt="" />
                                                </div>
                                            </div>

                                            <div className="add-discount noselect" onClick={toggleVideoDiscountContainer}>{videoPrice !== videoDiscountPrice ? "Remove discount" : "Add discount"}</div>

                                            <div className="discount-price" id="video-discount" style={{ display: videoPrice !== videoDiscountPrice ? "block" : "none" }}>
                                                <h4>Discount</h4>
                                                <div className="row_">
                                                    <span className="discount-price">Discount price:</span>
                                                    <input type="number" name="discount-pric" value={videoDiscountPrice} onChange={handleVideoDiscountPrice} />
                                                </div>

                                                <div className="row_">
                                                    <span className="discount-amount">Discount amount:</span>
                                                    <input type="number" name="discount-amount" value={videoPrice - videoDiscountPrice} />
                                                </div>

                                                <div className="row_">
                                                    <span className="discount-percent">Discount percent(%):</span>
                                                    <input type="number" name="discount-percent" value={(((videoPrice - videoDiscountPrice) / videoPrice) * 100).toFixed(0)} className="discount-percent-input" />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default AdvisorManage