import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../localization/translations';
import './advisorswithdiscount.scss';
import { FaStar, FaPlay } from 'react-icons/fa';
import firebase from '../../config/firebase';
import { collection, where, getDocs } from 'firebase/firestore';
import DateHooks from '../../hooks/dateHook';
import { setVideoUrl } from '../../provider/site/site';
import coinImg from '../../assets/ic_coin.png';
import ShaderEffect from '../../components/shaderEffect/shaderEffect';
import notFoundAdvisorImg from '../../assets/ic_not-found-advisor.svg';
import { query } from 'firebase/database';

const AdvisorsWithDiscount = () => {

    const { user } = useSelector(state => state.site);
    const navigate = useNavigate();
    const translation = useTranslation();
    const [advisors, setAdvisors] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [runs, setRuns] = useState(false);
    const dispatch = useDispatch();

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }

    useEffect(() => {
        setRuns(true);
        async function fetchAdvisors() {
            setAdvisors([]);
            console.log("Fetch advisors method runs!");
            const q = query(collection(firebase.db, 'users'), where('user_type', '==', 'advisor'));
            const advisorSnapshot = await getDocs(q);
            advisorSnapshot.docs.forEach(function (advisor) {
                if (advisor.get('status.is_online') === "true" && (advisor.get('live_modes.chat.price.discount_percent') >=50 || advisor.get('live_modes.voice.price.discount_percent') >=50 || advisor.get('live_modes.video.price.discount_percent') >= 50)) {
                    setAdvisors(prev => [...prev, {
                        id: advisor.id,
                        data: advisor.data()
                    }])
                }
            });
        }
        fetchAdvisors().then(async () => await delay(500).then(() => setRuns(false)));
    }, [toggle]);

    useEffect(() => {

        function removeEndSection() {
            setTimeout(() => {
                const endSection = document.getElementById('end-section');
                if (endSection) {
                    endSection.style.display = "none";
                }
            }, 200)
        }
        function addEndSection() {
            setTimeout(() => {
                const endSection = document.getElementById('end-section');
                if (endSection) {
                    endSection.style.display = "flex";
                }
            }, 200)
        }

        removeEndSection();

        return () => addEndSection();
    }, []);


    const Skeletion = () => {
        return (
            <div className="skeletion">
                <ShaderEffect width="200px" effectWidth="40" color="#fff2" />
                <div className="skeletionImg"></div>
                <div className="skeletionBottom">
                    <div className="skeletion1"></div>
                    <div className="skeletion2"></div>
                    <div className="skeletion3">
                        <div className="left">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        <div className="right"></div>
                    </div>
                </div>
            </div>
        )
    }

    const ProfileCard = (map) => {

        var advisorId = map.id;
        var advisorData = map.data;

        const PROFILE = advisorData.profile;
        const STATUS = advisorData.status;
        const REVIEWS = advisorData.reviews;
        const LIVEMODES = advisorData.live_modes;

        const NAME = PROFILE.name;
        const AGE = PROFILE.age;
        const POINT = REVIEWS.point_avg;
        const DESCRIPTION = PROFILE.description;
        const PIC_URL = PROFILE.pic_url;
        const DISCOUNT_TITLE = LIVEMODES.chat.price.discount_title;
        const DISCOUNT_PERCENT = LIVEMODES.chat.price.discount_percent;
        const DISCOUNT_PRICE = LIVEMODES.chat.price.discount_price;
        const NORMAL_PRICE = LIVEMODES.chat.price.normal_price;
        const READING_SINCE = DateHooks.getYearByStringFromSec(advisorData.created_at.seconds);
        const READING_AMOUNT = REVIEWS.count.total;
        const SKILLS = PROFILE.skills;
        const IS_ONLINE = STATUS.is_online;
        const BUSY = STATUS.busy;
        const VIDEO_URL = PROFILE.video_url;

        const Reading = (props) => {
            const id = props.id;

            return (
                <span className="readingSince" id={id}><b>{READING_AMOUNT}</b> {`${translation.reading} (${translation.since + ' ' + READING_SINCE})`}</span>
            )
        }

        const NameSkill = (props) => {
            const id = props.id;

            return (
                <div className="name-skill" id={id}>
                    <span className="name noscrollbar">{NAME}</span>
                    <div className="row noscrollbar">
                        {
                            SKILLS.map(function (skill, index) {
                                const skill_ = translation[skill.toLowerCase()];
                                const isLast = index === (SKILLS.length - 1);
                                return (
                                    <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }

        return (
            <div className="advisor-profile-card" onClick={() => navigate(`/advisor/${NAME}`, { state: { id: advisorId } })}>
                <div className="profileImg">
                    <img src={PIC_URL} alt="" />
                    <div className="point-play">
                        <div className="point">
                            <FaStar className="starIcon" />
                            <span>{POINT}</span>
                        </div>
                        <FaPlay className="playIcon" onClick={(event) => {
                            event.stopPropagation();
                            dispatch(setVideoUrl(VIDEO_URL));
                        }} />
                    </div>
                    <NameSkill key="name-skill-position1" id="name-skill-position1" />
                    <Reading key="reading-position1" id="reading-position1" />
                    <div className="status" style={{ background: BUSY ? "var(--red-color)" : IS_ONLINE ? "var(--green-color)" : "var(--red-color)" }}>{BUSY ? translation.busy : IS_ONLINE ? translation.online : translation.offline}</div>
                </div>

                <div className="info-call">
                    <div className="infoSec">
                        <NameSkill key="name-skill-position2" id="name-skill-position2" />
                        <Reading key="reading-position2" id="reading-position2" />
                        <span className="description">{DESCRIPTION}</span>
                    </div>

                    <div className="callSec">
                        <div className="stream">
                            <div className="chatIcon"></div>
                            <div className="voiceIcon"></div>
                            <div className="videoIcon"></div>
                        </div>
                        <div className="row" style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ marginRight: "5px" }}>from</span>
                            {
                                DISCOUNT_PERCENT > 0 ? (
                                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                                        <span style={{ marginRight: "2px", fontSize: "14px", textDecoration: "line-through", textDecorationColor: "red" }}><b style={{ color: "red" }}>{NORMAL_PRICE}</b></span>
                                        <span style={{ marginRight: "2px", fontSize: "16px" }}><b>{DISCOUNT_PRICE}</b></span>
                                    </div>
                                ) : (
                                    <span style={{ marginRight: "2px" }}><b>{NORMAL_PRICE}</b></span>
                                )
                            }
                            <img src={coinImg} alt="" style={{ width: "20px", height: "20px" }} />
                            <span><b>/min</b></span>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="page-content">
            {advisors.length > 0 && <h1 className="header">{translation.advisors_with_discount}</h1>}
            {
                !runs && advisors.length === 0 && (
                    <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center", padding: "0 20px" }}>
                        <img src={notFoundAdvisorImg} style={{ width: "100%", maxWidth: "500px"}} />
                        <span style={{ fontSize: "16px", textAlign: "center"}}>{translation.didnt_find_advisors_with_discount}</span>
                    </div>
                )
            }
            <div className="profiles-container">
                <div className="profiles-grid-container" style={{ marginBottom: runs ? "0" : null, gridGap: runs ? 20 : advisors.length <= 1 ? 0 : 20 }}>

                    {
                        advisors.length > 0 && advisors.sort((a,b) => b.status.is_online - a.status.is_online).map(advisor => {
                            return (
                                ProfileCard(advisor)
                            )
                        })
                    }

                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                    {runs && <Skeletion />}
                </div>
            </div>
        </div>
    )
}

export default AdvisorsWithDiscount