import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguageModal, setLanguage } from '../../../provider/site/site';
import { CgClose } from 'react-icons/cg';
import './languagemodal.scss';
import useTranslation from '../../../localization/translations';
import CookieCRUD from '../../../storage/cookie';
import { doc, updateDoc } from 'firebase/firestore';
import firebase from '../../../config/firebase';

const LanguageModal = () => {

    const translation = useTranslation();
    const {languageModal, language, user } = useSelector(state => state.site);

    const dispatch = useDispatch();

    function closeLanguageModal(e) {
        // e.stopPropagation();
        dispatch(setLanguageModal(false));
    }

    async function changeLanguage(language) {
        if(user) {
            try {
                await updateDoc(doc(firebase.db, `users/${user.uid}`), {
                    "preffered_language": language,
                });
            } catch (error) {
                console.log("-Some error accured while updating user preffered language-");
                console.log(error);
            }
        }
        CookieCRUD.setCookie('language', language);
        dispatch(setLanguage(language));
        dispatch(setLanguageModal(false));
    }

    function closeModal () {
        dispatch(setLanguageModal(false));
    }


  return (
    languageModal && (
        <div className="modal-container" onClick={closeModal}>
            <div className="language-modal modal-anim" onClick={(e) => e.stopPropagation()}>
            <CgClose className="close-icon" onClick={closeLanguageModal}/>
            <span className="header">{translation.select_language}</span>
            <div className="options">
                <div className="option" onClick={() => changeLanguage('en')} style={{background: language === 'en' ? "rgb(160,160,160)" : "none", color: language === 'en' ? "#fff" : "var(--default-text-color)"}}>English</div>
                <div className="option" onClick={() => changeLanguage('es')} style={{background: language === 'es' ? "rgb(160,160,160)" : "none", color: language === 'es' ? "#fff" : "var(--default-text-color)"}}>Espanol</div>
            </div>
            </div>
        </div>
    )
  )
}

export default LanguageModal