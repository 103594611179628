import {onDisconnect, onValue, ref as dbRef, set} from "firebase/database";
import {serverTimestamp as dbServerTimestamp} from "@firebase/database";

export default class UserService {

    static offlineStatus = {
        state: 'offline',
        last_changed: dbServerTimestamp(),
    };

    static onlineStatus = {
        state: 'online',
        last_changed: dbServerTimestamp(),
    };

    async setStatus(firebase, uid) {

        const ref = dbRef(firebase.rDb, '/status/' + uid);

        onValue(dbRef(firebase.rDb, '.info/connected'), function(snapshot) {
            if(snapshot.val() === false) {
                return;
            }

            console.log(uid);
            onDisconnect(ref).set(UserService.offlineStatus).then(function() {
                set(ref, UserService.onlineStatus);
            })
        })
    }
}