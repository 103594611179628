import React, { useEffect, useState } from 'react';
import './foradvisors.scss';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { RiInstagramFill } from 'react-icons/ri';
import { BsFacebook } from 'react-icons/bs';
import ElementControl from '../../functions/elementcontrol';
import { useNavigate } from 'react-router-dom';
import useTranslation from '../../localization/translations';

const ForAdvisors = () => {

    const [selectedQuestion, setSelectedQuestion] = useState(-1);
    const translation = useTranslation();
    const navigate = useNavigate();

    const questions = [
        {
            id: 0,
            question: "What is Zodiac Advisor?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
        {
            id: 1,
            question: "Who can become an advisor on Zodiac Advisor?",
            answer: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Debitis magnam exercitationem tenetur rerum distinctio perspiciatis temporibus placeat veritatis at maxime, labore ipsam animi nulla, rem eligendi dolore repellendus sapiente obcaecati vitae voluptatum cumque reprehenderit error! Eligendi officia culpa quam officiis?"
        },
        {
            id: 2,
            question: "How can I apply to be an advisor on Zodiac Advisor?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
        {
            id: 3,
            question: "Will I be notified when a client calls me?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
        {
            id: 4,
            question: "I don’t have time at the moment to accept new calls. What should I do?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
        {
            id: 5,
            question: "I got a negative review. What can I do to have it removed?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
        {
            id: 6,
            question: "How and when do I get paid?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
        {
            id: 7,
            question: "How can I withdraw my earnings?",
            answer: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fuga maxime quis atque exercitationem, consequatur error quas odio minima nihil modi."
        },
    ];

    useEffect(() => {
        ElementControl.removeFixedElements();
        return () => ElementControl.addFixedElements();
    }, []);

    return (
        <div className="mw-100 mh-100 bg-white">
            <div className="banner">
                <h1>For Advisors</h1>
            </div>

            <div className="content">
                <article>
                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum dicta officiis, saepe laudantium omnis in. Optio eos harum quam illo sint, ullam non, quia, explicabo culpa maiores ipsam! Quia eligendi sequi perspiciatis inventore? Maxime ea, dolorum soluta placeat corporis numquam!</span>
                    <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima suscipit corrupti non dignissimos soluta sapiente numquam voluptate pariatur omnis sequi!</span>
                    <div className="container">
                        <div className="app-store" />
                        <div className="play-store" />
                    </div>
                </article>
            </div>

            <div className="join-us">
                <div className="join-button">{translation.join_us}</div>
            </div>

            <div className="faq">
                <article>
                    <h2>{translation.faq}</h2>
                    <div className="faq-container">
                        {
                            questions.map(function (question, index) {

                                const isSelected = selectedQuestion === question.id;

                                return (
                                    <div id={index} className="question-container">
                                        <div className="question" onClick={() => setSelectedQuestion(isSelected ? "-1" : question.id)} style={{borderBottom: isSelected ? "1px solid var(--border-color)" : index !== (questions.length - 1) ? "1px solid var(--border-color)" : "none"}}>
                                            <h3>{question.question}</h3>
                                            {isSelected ? <BiMinus className="icon"/> : <BiPlus className="icon" />}
                                        </div>
                                        <div className="answer" style={{ maxHeight: isSelected ? "500px" : "0"}}>
                                            <div className="answer-padding" style={{ borderBottom: isSelected ? index !== (questions.length - 1) ? "1px solid var(--border-color)" : "none" : "none"}}>
                                                <span>{question.answer}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="question-container"></div>
                        <div className="question-container"></div>
                        <div className="question-container"></div>
                    </div>
                </article>
            </div>

            <footer>
                <div className="container">
                    <span className="company">© 2023 Glm Technologies, Inc.</span>
                    <nav>
                        <ul>
                            <a href='/terms_of_use'><li>{translation.term_of_use}</li></a>
                            <a href='/advisor_terms'><li>{translation.advisor_terms}</li></a>
                            <a href='/privacy_policy'><li>{translation.privacy_policy}</li></a>
                            {/* <li>Psychic Chat</li> */}
                        </ul>
                    </nav>
                    <div className="social">
                        <a href='https://www.instagram.com/zodiacadvisor/' target="_blank"><RiInstagramFill className='social-icon' /></a>
                        <a href='https://www.facebook.com/profile.php?id=100088474826377' target="_blank"><BsFacebook className='social-icon' /></a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default ForAdvisors