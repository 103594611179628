import { addDoc, collection } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { BsExclamationCircleFill, BsFillCheckCircleFill } from 'react-icons/bs';
import { CgClose } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import firebase from '../../../config/firebase';
import useTranslation from '../../../localization/translations';
import { setCustomerSupportModal } from '../../../provider/site/site';
import './customersupportmodal.scss';
const db = firebase.db;

const CustomerSupportModal = () => {

    const { customerSupportModal, user } = useSelector(state => state.site);
    const translation = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');

    const handleName = e => setName(e.target.value);
    const handleSurname = e => setSurname(e.target.value);
    const handleEmail = e => setEmail(e.target.value);
    const handleMessage = e => setMessage(e.target.value.slice(0, 200));

    useEffect(() => {
        if(user && user.profile && user.profile.email) {
            setEmail(user.profile.email);
        }
    }, [user && user.profile && user.profile.email]);

    const closeModal = () => dispatch(setCustomerSupportModal(false));

    async function sendMessageToCustomerSupport() {
        setLoading(true);
        try {
            await addDoc(collection(db, 'customer_support'), {
                email: email,
                first_name: name,
                surname: surname,
                question: message,
            });
            setLoading(false);
            setStatus('ok');
            await delay(3000).then(resetAll);
        } catch (e) {
            console.log(e);
            setLoading(false);
            setStatus('fail');
            await delay(3000).then(resetAll);
        }
    }

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }

    function resetAll() {
        setStatus('');
        dispatch(setCustomerSupportModal(false));
        setName('');
        setSurname('');
        setEmail('');
        setMessage('');
    }

    return (
        customerSupportModal && (
            <div className="modal-container" onClick={closeModal}>
                {
                    status ? (
                        status === "ok" ? (
                            <div className="success-modal modal modal-anim">
                                <CgClose className="close-icon" onClick={resetAll}/>
                                <BsFillCheckCircleFill className="icon success-icon"/>
                                <span>{translation.message_success}</span>
                            </div>
                        ) : (
                            <div className="failed-modal modal modal-anim">
                                <CgClose className="close-icon" onClick={resetAll}/>
                                <BsExclamationCircleFill className="icon failed-icon"/>
                                <span>{translation.message_fail}</span>
                            </div>
                        )
                    ) : (
                        <div className="contact-us-modal modal modal-anim" onClick={e => e.stopPropagation()}>
                            <h2 className="header">{translation.contact_us}</h2>
                            <form action="">
                                <div className="col">
                                    <h3>{translation.personal}</h3>
                                    <input type="text" placeholder={translation.first_name} onChange={handleName} value={name} />
                                    <input type="text" placeholder={translation.surname} onChange={handleSurname} value={surname} />
                                    <input type="text" placeholder={translation.email} onChange={handleEmail} value={email} />
                                </div>
                                <div className="col">
                                    <h3>{translation.question}</h3>
                                    <textarea name="" id="" cols="30" rows="10" onChange={handleMessage} placeholder={translation.question_placeholder} value={message}></textarea>
                                </div>
                                <div className="send-button" onClick={sendMessageToCustomerSupport} style={{opacity: name && surname && email && message.length > 20 ? "1" : ".5", pointerEvents: loading ? "none" : "all"}}>{loading ? <PulseLoader color="white" size="10"/> : translation.send_message}</div>
                                <div className="close-button" onClick={closeModal}>{translation.close}</div>
                            </form>
                        </div>
                    )
                }
            </div>
        )
    )
}

export default CustomerSupportModal