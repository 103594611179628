import { useEffect, useState } from 'react';

export default function useScrollPosition (element) {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(element.scrollTop);
        // clean up code
        element.removeEventListener('scroll', onScroll);
        element.addEventListener('scroll', onScroll, { passive: true });
        return () => element.removeEventListener('scroll', onScroll);
    }, []);

    return offset;
};