import React, { useEffect, useState } from 'react';
import './forgotpassword.scss';
import {HiMail} from 'react-icons/hi';
import Auth from '../../functions/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import {AiFillCheckCircle} from 'react-icons/ai';
import useTranslation from '../../localization/translations';
import ElementControl from '../../functions/elementcontrol';


const ForgotPassword = () => {

  const translation = useTranslation();
  const [success, setSuccess] = useState('');
  
  const location = useLocation();
  const navigate = useNavigate();
  const auth = new Auth();

  function goHome() {
    navigate('/');
  }

  function checkReset() {
    const hasParams = location.search;
    if(hasParams) {
      const isPasswordResetSuccess = location.search.split('=')[1] === 'false' ? false : true;
      if(isPasswordResetSuccess) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    } else {
      setSuccess(false);
    }
  }


  useEffect(() => {
    checkReset();
    return () => ElementControl.addFixedElements();
  }, []);

  const SuccesResetPage = () => {
    return (
      <div className="_aD2Psq1">
          <div className="elips" style={{"--i": 0}}></div>
          <div className="elips" style={{"--i": 1}}></div>
          <div className="elips" style={{"--i": 2}}></div>
          <div className="elips" style={{"--i": 3}}></div>

          <div className="_af2Psq2">
            <img className="_aD2Psr1" src={require('../../assets/ic_forgotpassword.png')} alt="" />
            <div className="_af2Psq3">
              <AiFillCheckCircle className="_af2PsE3"/>
              <span className="_af2PsE2">{translation.password_changed}</span>
            </div>
            <div className="_af2PsG2" onClick={goHome}>{translation.go_home}</div>
          </div>
      </div>
    )
  }


  const ResetPasswordPage = () => {

    const [email, setEmail] = useState('');

    function handleEmail (e) {
      setEmail(e.target.value);
    }

    function isValidEmail(email) {
      // E-posta adresinin biçimini doğrulamak için regex kullanılır (ChatGPT)
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    async function resetPassword () {
      try {
        await auth.resetPassword(email); 
      } catch (e) {
        alert(e);
      }
    }

    return (
      <div className="_aD2Psq1">
          <div className="elips" style={{"--i": 0}}></div>
          <div className="elips" style={{"--i": 1}}></div>
          <div className="elips" style={{"--i": 2}}></div>
          <div className="elips" style={{"--i": 3}}></div>

          <div className="_aD2Psq2">
            <img className="_aD2Psr1" src={require('../../assets/ic_forgotpassword.png')} alt="" />
            <h3 className="_aD2Psq3">{translation.forgot_password}</h3>
            <span className="_aD2Psq4">{translation.enter_your_email}</span>
            <div className="_aD2Psf5">
              <input type="text" onChange={handleEmail} className="_aD2Psq5" placeholder="E-mail"/>
              <HiMail className="_aD2Psf5" style={{color: email ? isValidEmail(email) ? "var(--green-color)" : "var(--red-color)" : "rgb(220,220,220)"}}/>
            </div>
            <div className="_aD2Psq6 noselect" onClick={resetPassword} style={{background: isValidEmail(email) ? "var(--theme-color)" : "rgba(var(--theme-color-rgb), .5)", pointerEvents: isValidEmail(email) ? "all" : "none"}}>{translation.reset_password}</div>
          </div>
      </div>
    )
  }


  return (
    success === ''
    ? (
      <div className="_aD2Psq1">
          <PulseLoader color="var(--theme-color)"/>
      </div>
    )
    : success ? <SuccesResetPage/> : <ResetPasswordPage/>
  )
}

export default ForgotPassword