import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';
import useTranslation from '../../localization/translations';
import CookieCRUD from '../../storage/cookie';
import './favouriteadvisors.scss';
import { FaStar, FaPlay } from 'react-icons/fa';
import firebase from '../../config/firebase';
import { getDoc, doc } from 'firebase/firestore';
import DateHooks from '../../hooks/dateHook';
import { setVideoUrl } from '../../provider/site/site';
import coinImg from '../../assets/ic_coin.png';
import SeeMore from '../../components/button/seemore/seemore';
import ShaderEffect from '../../components/shaderEffect/shaderEffect';
import heartImg from '../../assets/ic_heart.png';

const FavouriteAdvisors = () => {

  const { user } = useSelector(state => state.site);
  const navigate = useNavigate();
  const translation = useTranslation();
  const [favouriteAdvisors, setFavouriteAdvisors] = useState([]);
  const [fetchedFavouritesLength, setFetchedFavouritesLength] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [runs, setRuns] = useState(false);
  const dispatch = useDispatch();

  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  }

  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user]);

  useEffect(() => {
    setRuns(true);
    async function fetchFavourites() {
      console.log("Fetch favourites method runs!");

      if (!user || !user.favourites) {
        return;
      }

      const favourites = user.favourites;
      let favouritesLength = favourites.length;
      const fetchPerCount = 8;

      if (favouritesLength > fetchedFavouritesLength) {
        if (favouritesLength >= fetchedFavouritesLength + fetchPerCount) {
          const listOfFavourites = favourites.slice(fetchedFavouritesLength, fetchedFavouritesLength + fetchPerCount);
          listOfFavourites.forEach(async function (favouriteId) {
            const favouriteAdvisor = (await getDoc(doc(firebase.db, `users/${favouriteId}`))).data();
            setFavouriteAdvisors(prev => [...prev, { id: favouriteId, data: favouriteAdvisor }]);
            console.log("Favourite advisor fetched: ", favouriteAdvisor);
          });
          setFetchedFavouritesLength(prev => prev + fetchPerCount);
        } else {
          const listOfFavourites = favourites.slice(fetchedFavouritesLength, favourites.length);
          console.log(listOfFavourites);
          listOfFavourites.forEach(async function (favouriteId) {
            const favouriteAdvisor = (await getDoc(doc(firebase.db, `users/${favouriteId}`))).data();
            setFavouriteAdvisors(prev => [...prev, { id: favouriteId, data: favouriteAdvisor }]);
            console.log("Favourite advisor fetched: ", favouriteAdvisor);
          });
          setFetchedFavouritesLength(prev => prev + favourites.length - fetchedFavouritesLength);
        }
      }
    }
    fetchFavourites().then(async () => await delay(500).then(() => setRuns(false)));
  }, [toggle]);

  useEffect(() => {

    function removeEndSection() {
      setTimeout(() => {
        const endSection = document.getElementById('end-section');
        if(endSection) {
          endSection.style.display = "none";
        }
      }, 200)
    }
    function addEndSection() {
      setTimeout(() => {
        const endSection = document.getElementById('end-section');
        if(endSection) {
          endSection.style.display = "flex";
        }
      }, 200)
    }

    removeEndSection();

    return () => addEndSection();
  }, []);


  const Skeletion = () => {
    return (
      <div className="skeletion">
        <ShaderEffect width="200px" effectWidth="40" color="#fff2" />
        <div className="skeletionImg"></div>
        <div className="skeletionBottom">
          <div className="skeletion1"></div>
          <div className="skeletion2"></div>
          <div className="skeletion3">
            <div className="left">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="right"></div>
          </div>
        </div>
      </div>
    )
  }

  const ProfileCard = (map) => {

    var advisorId = map.id;
    var advisorData = map.data;

    const PROFILE = advisorData.profile;
    const STATUS = advisorData.status;
    const REVIEWS = advisorData.reviews;
    const LIVEMODES = advisorData.live_modes;

    const NAME = PROFILE.name;
    const AGE = PROFILE.age;
    const POINT = REVIEWS.point_avg;
    const DESCRIPTION = PROFILE.description;
    const PIC_URL = PROFILE.pic_url;
    const DISCOUNT_TITLE = LIVEMODES.chat.price.discount_title;
    const DISCOUNT_PERCENT = LIVEMODES.chat.price.discount_percent;
    const DISCOUNT_PRICE = LIVEMODES.chat.price.discount_price;
    const NORMAL_PRICE = LIVEMODES.chat.price.normal_price;
    const READING_SINCE = DateHooks.getYearByStringFromSec(advisorData.created_at.seconds);
    const READING_AMOUNT = REVIEWS.count.total;
    const SKILLS = PROFILE.skills;
    const IS_ONLINE = STATUS.is_online;
    const BUSY = STATUS.busy;
    const VIDEO_URL = PROFILE.video_url;

    const Reading = (props) => {
      const id = props.id;

      return (
        <span className="readingSince" id={id}><b>{READING_AMOUNT}</b> {`${translation.reading} (${translation.since + ' ' + READING_SINCE})`}</span>
      )
    }

    const NameSkill = (props) => {
      const id = props.id;

      return (
        <div className="name-skill" id={id}>
          <span className="name noscrollbar">{NAME}</span>
          <div className="row noscrollbar">
            {
              SKILLS.map(function (skill, index) {
                const skill_ = translation[skill.toLowerCase()];
                const isLast = index === (SKILLS.length - 1);
                return (
                  <span className="skill" key={index}>{isLast ? (skill_) : (skill_ + ', ')}</span>
                )
              })
            }
          </div>
        </div>
      )
    }

    return (
      <div className="advisor-profile-card" onClick={() => navigate(`/advisor/${NAME}`, { state: { id: advisorId } })}>
        <div className="profileImg">
          <img src={PIC_URL} alt="" />
          <div className="point-play">
            <div className="point">
              <FaStar className="starIcon" />
              <span>{POINT}</span>
            </div>
            <FaPlay className="playIcon" onClick={(event) => {
              event.stopPropagation();
              dispatch(setVideoUrl(VIDEO_URL));
            }} />
          </div>
          <NameSkill key="name-skill-position1" id="name-skill-position1" />
          <Reading key="reading-position1" id="reading-position1" />
          <div className="status" style={{ background: BUSY ? "var(--red-color)" : IS_ONLINE ? "var(--green-color)" : "var(--red-color)" }}>{BUSY ? translation.busy : IS_ONLINE ? translation.online : translation.offline}</div>
        </div>

        <div className="info-call">
          <div className="infoSec">
            <NameSkill key="name-skill-position2" id="name-skill-position2" />
            <Reading key="reading-position2" id="reading-position2" />
            <span className="description">{DESCRIPTION}</span>
          </div>

          <div className="callSec">
            <div className="stream">
              <div className="chatIcon"></div>
              <div className="voiceIcon"></div>
              <div className="videoIcon"></div>
            </div>
            <div className="row" style={{ display: "flex", alignItems: "center" }}>
              <span style={{ marginRight: "5px" }}>{translation.from}</span>
              {
                DISCOUNT_PERCENT > 0 ? (
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <span style={{ marginRight: "2px", fontSize: "14px", textDecoration: "line-through", textDecorationColor: "red" }}><b style={{ color: "red" }}>{NORMAL_PRICE}</b></span>
                    <span style={{ marginRight: "2px", fontSize: "16px" }}><b>{DISCOUNT_PRICE}</b></span>
                  </div>
                ) : (
                  <span style={{ marginRight: "2px" }}><b>{NORMAL_PRICE}</b></span>
                )
              }
              <img src={coinImg} alt="" style={{ width: "20px", height: "20px" }} />
              <span><b>/min</b></span>
            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    user ? (
      <div className="page-content">
        {favouriteAdvisors.length > 0 && <h1 className="header">{translation.my_favourites}</h1>}
        {
          !runs && favouriteAdvisors.length === 0 && (
            <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", justifyContent: "center", padding: "0 20px" }}>
              <img src={heartImg} style={{ maxWidth: "calc(100% - 40px)" }} />
              <span style={{ fontWeight: "bold", fontSize: "18px", marginTop: "10px" }}>{translation.not_favourites_yet}</span>
              <span style={{ fontSize: "16px" }}>{translation.tap_on_the_heart}</span>
            </div>
          )
        }
        <div className="profiles-container">
          <div className="profiles-grid-container" style={{ marginBottom: runs ? "0" : null, gridGap: runs ? 20 : favouriteAdvisors.length <= 1 ? 0 : 20 }}>

            {
              favouriteAdvisors.length > 0 && favouriteAdvisors.map(advisor => {
                return (
                  ProfileCard(advisor)
                )
              })
            }

            {runs && <Skeletion />}
            {runs && <Skeletion />}
            {runs && <Skeletion />}
            {runs && <Skeletion />}
            {runs && <Skeletion />}
            {runs && <Skeletion />}
            {runs && <Skeletion />}
            {runs && <Skeletion />}
          </div>

          {!runs && (user && user.favourites && user.favourites.length !== fetchedFavouritesLength) && <SeeMore onClick={() => setToggle(prev => !prev)} />}
        </div>
      </div>
    ) : (
      <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}><PulseLoader color="var(--theme-color)" /></div>
    )
  )
}

export default FavouriteAdvisors