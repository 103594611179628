import React, { useEffect, useState } from 'react'
import './navbar.scss'
import logo from '../../assets/ic_fallogo.png'
import { MdLanguage } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { showSignInModal, showSignUpModal, setMenu, toggleMenu, setLanguageModal, setFunds, setCustomerSupportModal } from '../../provider/site/site';
import { CgClose } from 'react-icons/cg';
import { FaUser } from 'react-icons/fa';
import { HiOutlineLogout, HiChevronRight, HiOutlineMenuAlt4, HiOutlineHeart } from 'react-icons/hi';
import { FiSettings } from 'react-icons/fi';
import { BiCoinStack, BiSupport, BiCategoryAlt, BiPencil } from 'react-icons/bi';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsChat, BsSearch } from 'react-icons/bs';
import coinImg from '../../assets/ic_coin.png';
import homeImg from '../../assets/ic_homelink.png';
import loveImg from '../../assets/ic_lovelink.svg';
import pyshicImg from '../../assets/ic_psychiclink.svg';
import tarotImg from '../../assets/ic_tarotlink.svg';
import palmImg from '../../assets/ic_palmlink.svg';
import astrologyImg from '../../assets/ic_astrologylink.svg';
import dreamImg from '../../assets/ic_dreamlink.svg';
import languageImg from '../../assets/ic_languagelink.png';
import { Link, useNavigate } from 'react-router-dom'
import Auth from '../../functions/auth';
import useTranslation from '../../localization/translations';
import { collection, getDocs, where } from 'firebase/firestore';
import { query } from 'firebase/database';
import firebase from '../../config/firebase';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import VerifyFlag from '../verifyflag/verifyflag';


const Bar = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const translation = useTranslation();
  const [settings, setSettings] = useState(false);
  const { menu, user, language } = useSelector(state => state.site);
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const [searchInputText, setSearchInputText] = useState('');
  const [filteredAdvisors, setFilteredAdvisors] = useState([]);
  const { width } = useWindowDimensions();
  let subscription = null;

  // console.log(user.profile.pic_url)


  const NavbarLogo = () => {
    return (
      <div className="navbar-logo noselect" onClick={() => navigate('/')}>
        <img src={logo} alt="" />

        <Link to='/' style={{ letterSpacing: "-1.5px" }}>Zodiac Advisor</Link>
      </div>
    )
  }

  function goRoute(path, state) {
    setSettings(false);
    dispatch(setMenu(false));
    navigate(path, { state: state });
  }

  function openLanguageModal(e) {
    e.stopPropagation();
    dispatch(setLanguageModal(true));
  }

  const closeMenu = () => dispatch(setMenu(false));

  const handleSearchInputChange = e => setSearchInputText(e.target.value);

  useEffect(() => {

    function listenBarOutsideClicks() {

      if (!settings && subscription) {
        return;
      }

      const bar = document.getElementById('navbar');

      if (settings) {
        subscription = document.addEventListener('click', (event) => {
          const withinBoundaries = event.composedPath().includes(bar);

          if (!withinBoundaries) {
            setSettings(false);
          }
        })
      }
    }

    listenBarOutsideClicks();

    return () => {
      if (subscription) {
        subscription();
      }
    }
  }, [settings]);

  useEffect(() => {
    async function fetchAdvisors() {
      setAdvisors([]);
      const q = query(collection(firebase.db, 'users'), where('user_type', '==', 'advisor'));
      const advisorSnapshot = await getDocs(q);
      advisorSnapshot.docs.forEach(function (advisor) {
        setAdvisors(prev => [...prev, { id: advisor.id, data: advisor.data() }])
      });
    }

    fetchAdvisors();
  }, []);

  useEffect(() => {
    if (advisors && searchInputText) {
      setFilteredAdvisors([]);
      const filteredAdvisors_ = advisors.filter(advisor => advisor.data.profile.name.toLowerCase().includes(searchInputText.toLowerCase()));
      setFilteredAdvisors(filteredAdvisors_);
    }
  }, [searchInputText])

  const Menu = () => {

    const [categoryState, setCategoryState] = useState(false);

    function toggleCategory() {
      setCategoryState(prev => !prev);
    }

    return (
      <div className="menu noselect" id="menu" style={{ right: menu ? "0px" : "-100vw", opacity: menu ? "1" : "0"}} onClick={closeMenu}>

        <div className="menu-container" style={{ right: menu ? "0px" : "-260px", opacity: menu ? "1" : "0"}} onClick={e => e.stopPropagation()}>
          <div className="links-container">

            <div className="home-link link" onClick={() => goRoute('/')}>
              <span>{translation.home}</span>
              <img src={homeImg} alt="" className="icon" />
            </div>


            <div className="categories-container" style={{ height: categoryState ? "400px" : "50px" }}>
              <div className="categories-link link" onClick={toggleCategory}>
                {categoryState ? <FiChevronUp className="icon" /> : <FiChevronDown className="icon" />}
                <div style={{ display: "flex" }}>
                  <span>{translation.categories}</span>
                  <BiCategoryAlt className="icon" />
                </div>
              </div>

              <div className="categories">
                <div className="love-link link child-link" onClick={() => goRoute('/skills/love_readings')}>
                  <span>{translation.love_readings}</span>
                  <img src={loveImg} alt="" className="icon" />
                </div>

                <div className="pyschic-link link child-link" style={{ "--i": '2' }} onClick={() => goRoute('/skills/psychic_readings')}>
                  <span>{translation.psychics_readings}</span>
                  <img src={pyshicImg} alt="" className="icon" />
                </div>

                <div className="tarot-link link child-link" style={{ "--i": '3' }} onClick={() => goRoute('/skills/tarot_readings')}>
                  <span>{translation.tarot_readings}</span>
                  <img src={tarotImg} alt="" className="icon" />
                </div>

                <div className="palm-link link child-link" style={{ "--i": '4' }} onClick={() => goRoute('/skills/palm_readings')}>
                  <span>{translation.palm_readings}</span>
                  <img src={palmImg} alt="" className="icon" />
                </div>

                <div className="astrology-link link child-link" style={{ "--i": '5' }} onClick={() => goRoute('/skills/astrology_horoscopes')}>
                  <span>{translation.astrology_horoscopes}</span>
                  <img src={astrologyImg} alt="" className="icon" />
                </div>

                <div className="clairvoyance-link link child-link" style={{ "--i": '6' }} onClick={() => goRoute('/skills/clairvoyance_readings')}>
                  <span>{translation.clairvoyance}</span>
                  <img src={dreamImg} alt="" className="icon" />
                </div>

                <div className="career-link link child-link" style={{ "--i": '6' }} onClick={() => goRoute('/skills/career_readings')}>
                  <span>{translation.career}</span>
                  <img src={dreamImg} alt="" className="icon" />
                </div>

                <div className="dream-link link child-link" style={{ "--i": '7' }} onClick={() => goRoute('/skills/dream_readings')}>
                  <span>{translation.dream_readings}</span>
                  <img src={dreamImg} alt="" className="icon" />
                </div>

              </div>
            </div>


            <div className="favourite_advisors-link link" onClick={() => {
              if (user) {
                goRoute('/favourite_advisors');
              } else {
                dispatch(showSignUpModal(true));
              }
            }}>
              <span>{translation.favourite_advisors}</span>
              <HiOutlineHeart className="icon" />
            </div>

            <div className="add_funds-link link" onClick={function () {
              if (user) {
                dispatch(setFunds(true));
              } else {
                dispatch(showSignUpModal(true));
              }
            }}>
              <span>{translation.add_funds}</span>
              <BiCoinStack className="icon" />
            </div>

            <div className="chat_box-link link" onClick={() => {
              if(user){
                goRoute('/chat_box');
              } else {
                dispatch(showSignUpModal(true));
              }
            }}>
              <span>{translation.chat_box}</span>
              <BsChat className="icon" />
            </div>


            <div className="customer_support-link link" onClick={() => dispatch(setCustomerSupportModal(true))}>
              <span>{translation.customer_support}</span>
              <BiSupport className="icon" />
            </div>

            <div className="language-link link" onClick={openLanguageModal}>
              <div className="choose-lang">
                <p className="current-lang">{language}</p>
              </div>
              <div style={{ display: "flex" }}>
                <span>{translation.language}</span>
                <img src={languageImg} alt="" className="icon" />
              </div>
            </div>

            <div className="settings-link link" onClick={() => {
              if (user) {
                goRoute('/settings')
              } else {
                dispatch(showSignUpModal(true));
              }
            }}>
              <span>{translation.settings}</span>
              <FiSettings className="icon" />
            </div>

            <div className="space" style={{ height: "50px", width: "100%", background: "rgba(255, 255, 255, 0)" }}></div>
          </div>

          <div className="expanded"></div>

          {
            user
              ? (
                <div className="profile-container2">

                  <div className="profile">
                    <div className="profile-img-container">
                      {user.profile.pic_url ? <img alt="" src={user.profile.pic_url} className="profile-img" onClick={() => setSettings(prev => !prev)} /> : <FaUser className="profile-icon" onClick={() => setSettings(prev => !prev)} />}
                      {
                        user.user_type === "advisor" && (
                          <BiPencil className="edit-icon" onClick={() => goRoute('/advisor/manage', null)}/>
                        )
                      }
                    </div>
                    <div className="name-email">
                      <span className="name">{user.profile.name}</span>
                      <span className="email">{user.profile.email}</span>
                    </div>
                    <div className="balance" onClick={() => dispatch(setFunds(true))}>
                      <img className="balance-img" src={require('../../assets/ic_coin.png')} alt="" />
                      <span className="balance-amount">{user.balance}</span>
                    </div>
                  </div>
                </div>
              )
              : (
                <div className="signin-signup">
                  <div className="menu-signin">
                    <span onClick={() => { dispatch(setMenu(false)); dispatch(showSignInModal(true)); }}>{translation.sign_in}</span>
                  </div>

                  <div className="menu-signup">
                    <span onClick={() => { dispatch(setMenu(false)); dispatch(showSignUpModal(true)); }}>{translation.sign_up}</span>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    )
  }

  return (
    <div className='navbar' id='navbar'>
      
      {menu && <Menu />}
      <div className="navbar-container">
        <div className="row">
          {!(isSearchInputFocused && (width < 550)) && <NavbarLogo />}
          <div className="input-container" style={{ maxWidth: (isSearchInputFocused && (width < 550)) ? "none" : "400px", margin: (isSearchInputFocused && (width < 550)) ? "0" : "0 10px" }}>
            {
              isSearchInputFocused && advisors && (
                <div className="result-container">
                  <div className="head">
                    <span>Advisors</span>
                  </div>
                  <div className="results">
                    {
                      (searchInputText ? filteredAdvisors : advisors).map(function (advisor) {
                        const id = advisor.id;
                        const data = advisor.data;
                        return (
                          <div id={id} className="result" onClick={(e) => navigate(`/advisor/${data.profile.name}`, { state: { id: id } })}>
                            <img src={data.profile.pic_url} className="img" />
                            <div className="row" style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between" }}>
                              <div className="col">
                                <span className="name">{data.profile.name}</span>
                                <span className="skill">{data.profile.skills.join(', ')}</span>
                              </div>
                              {
                                width > 750 && (
                                  <div className="col_">
                                    <div className="row">
                                      <div className="chat_" />
                                      <div className="voice_" />
                                      <div className="video_" />
                                    </div>
                                    <div className="row">
                                      <span>from</span>
                                      {
                                        data.live_modes.chat.price.discount_percent > 0 ? (
                                          <>
                                            <span style={{ marginRight: "1px", fontSize: "12px", textDecoration: "line-through", color: "red" }}>{data.live_modes.chat.price.normal_price}</span>
                                            <span>{data.live_modes.chat.price.discount_price}</span>
                                          </>
                                        ) : (
                                          <span>{data.live_modes.chat.price.normal_price}</span>
                                        )
                                      }
                                      <img src={coinImg} alt="" />
                                      <span>/</span>
                                      <span>min</span>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                    {searchInputText && filteredAdvisors.length === 0 && (
                      <div className="not-found">
                        <span>{translation.didnt_find}</span>
                      </div>
                    )}
                  </div>
                </div>
              )
            }
            <BsSearch className="search-icon" style={{ color: searchInputText ? "rgb(40,40,40)" : "rgb(140,140,140)", pointerEvents: "none", left: (width < 550) ? isSearchInputFocused ? "15px" : "12px" : "15px" }} />
            <input id="search"
              onFocus={() => {
                document.getElementById('search').removeAttribute('readonly');
                setIsSearchInputFocused(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsSearchInputFocused(false);
                  if (width < 550) {
                    setSearchInputText('');
                  }
                }, 200);
              }} readOnly value={searchInputText} autoComplete="off" type="search" onChange={handleSearchInputChange} placeholder={width < 550 ? null : "Search psychics.."} style={{
                border: isSearchInputFocused ? "1px solid var(--theme-color)" : "1px solid var(--border-color)",
                width: (width < 550) ? isSearchInputFocused ? "100%" : "40px" : "100%",
                padding: width < 550 ? isSearchInputFocused ? "0 10px 0 40px" : "0" : "0 10px 0 40px",
              }} />
          </div>
        </div>
        {
          (width > 750) ? (
            user
              ? (
                <div className="profile-container noselect">
                  <div className="settings" id="settings" style={{ display: settings ? 'block' : 'none' }}>
                    <div className="settings-container">
                      <div className="profile">
                        <div className="profile-img-container">
                          {user.profile.pic_url ? <img alt="" src={user.profile.pic_url} className="profile-img" onClick={() => setSettings(prev => !prev)} /> : <FaUser className="profile-icon" onClick={() => setSettings(prev => !prev)} />}
                          {
                            user.user_type === "advisor" && (
                              <BiPencil className="edit-icon" onClick={() => goRoute('/advisor/manage', null)}/>
                            )
                          }
                        </div>
                        <div className="name-email">
                          <span className="name">{user.profile.name}</span>
                          <span className="email">{user.profile.email}</span>
                        </div>
                      </div>

                      <div className="settings-items">
                        <div className="settings-item" onClick={() => {
                          if (user) {
                            goRoute('/favourite_advisors')
                          } else {
                            dispatch(showSignUpModal(true));
                          }
                        }}>
                          <div className="row">
                            <HiOutlineHeart className="icon" />
                            <span>{translation.favourite_advisors}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>

                        <div className="settings-item" onClick={function () {
                          setSettings(false);
                          dispatch(setFunds(true));
                        }}>
                          <div className="row">
                            <BiCoinStack className="icon" />
                            <span>{translation.add_funds}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>


                        <div className="settings-item" onClick={() => {
                          if (user) {
                            goRoute('/chat_box');
                          } else {
                            dispatch(showSignUpModal(true));
                          }
                        }}>
                          <div className="row">
                            <BsChat className="icon" />
                            <span>{translation.chat_box}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>

                        <div className="settings-item" onClick={() => {
                          if (user) {
                            dispatch(setCustomerSupportModal(true));
                          } else {
                            dispatch(showSignUpModal(true));
                          }
                        }}>
                          <div className="row">
                            <BiSupport className="icon" />
                            <span>{translation.customer_support}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>

                        <div className="settings-item" onClick={openLanguageModal}>
                          <div className="row">
                            <img src={languageImg} alt="" className="icon" />
                            <span>{translation.language}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>

                        <div className="settings-item" onClick={() => {
                          if (user) {
                            goRoute('/settings');
                          } else {
                            dispatch(showSignUpModal(true));
                          }
                        }}>
                          <div className="row">
                            <FiSettings className="icon" />
                            <span>{translation.settings}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>

                        <div className="settings-item" onClick={() => {
                          if (user) {
                            Auth.logout(user.uid).then(setSettings(false));
                          }
                        }}>
                          <div className="row">
                            <HiOutlineLogout className="icon" />
                            <span>{translation.logout}</span>
                          </div>
                          <HiChevronRight className="settings-icon" />
                        </div>
                      </div>

                      <div className="settings-balance">


                        <div className="get-coin" onClick={() => {
                          setSettings(false);
                          dispatch(setFunds(true));
                        }}>{translation.get_coins}</div>
                      </div>
                    </div>
                  </div>

                  <div className="deneme" onClick={() => dispatch(setFunds(true))} style={{ padding: "5px 10px", boxShadow: "inset 0 1px 0 1px #0002", background: "rgb(245,245,245)", borderRadius: "50px", display: "flex", alignItems: "center" }}>
                    <img src={coinImg} alt="" style={{ marginRight: "5px", width: "20px", height: "20px" }} />
                    <span style={{ color: "var(--default-text-color)", fontSize: "16px", fontWeight: "bold" }}>{user.balance.toFixed(0)}</span>
                  </div>
                  {user.profile.pic_url ? <img src={user.profile.pic_url} alt="" className="profile-img" onClick={() => setSettings(prev => !prev)} /> : <FaUser className="profile-icon" onClick={() => setSettings(prev => !prev)} />}
                </div>
              )
              : (
                <div className="language-sign">
                  <div className="navbar-language" onClick={() => dispatch(setLanguageModal(true))}>
                    <MdLanguage style={{ width: "20px", height: "20px", color: "rgb(160,160,160)" }} />
                    <span>{language}</span>
                  </div>

                  <div className="navbar-sign">
                    <div onClick={() => dispatch(showSignInModal(true))}>{translation.sign_in} </div>
                    <div onClick={() => dispatch(showSignUpModal(true))}> {translation.sign_up}</div>
                  </div>
                </div>
              )
          ) : (
            !(isSearchInputFocused && (width < 550)) &&
            (
              <>
                {
                  user && (
                    <div className="balance-container noselect" onClick={() => dispatch(setFunds(true))}>
                      <img src={require('../../assets/ic_coin.png')} className="coin-img" />
                      <span>{user.balance}</span>
                    </div>
                  )
                }
                {
                  menu ?
                    <CgClose className="menubtn" onClick={() => {
                      dispatch(toggleMenu());
                    }} />
                    : < HiOutlineMenuAlt4 className="menubtn" onClick={() => {
                      dispatch(toggleMenu());
                    }} />
                }
              </>
            )
          )
        }
      </div>
    </div>
  )
}

export default Bar